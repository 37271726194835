import Dashboard from "../../theme/images/whiteBackgroundIcons/Dashboard.svg"
import UserManagement from "../../theme/images/whiteBackgroundIcons/User Management.svg"
import VendorManagement from "../../theme/images/whiteBackgroundIcons/Vendor Management.svg"
import Clients from "../../theme/images/whiteBackgroundIcons/Clients.svg"
import Product from "../../theme/images/whiteBackgroundIcons/Products - Services.svg"
import Portfolio from "../../theme/images/whiteBackgroundIcons/Clients.svg"
import Subscriptions from "../../theme/images/whiteBackgroundIcons/Dashboard.svg"
import services from "../../theme/images/whiteBackgroundIcons/Products - Services.svg"
import Enquiry from "../../theme/images/whiteBackgroundIcons/Enquiry.svg"
import Campaign from "../../theme/images/whiteBackgroundIcons/Campaign Status.svg"
import CustomerFeedback from "../../theme/images/whiteBackgroundIcons/Customer Feedback.svg"
import Reports from "../../theme/images/whiteBackgroundIcons/Reports.svg"
import Payment from "../../theme/images/whiteBackgroundIcons/My Performance.svg"

import EntityCreation from "../../theme/images/NewSidebarIcons/EntityCreationIcon.svg"
import EntityCreationSelected from "../../theme/images/NewSidebarIcons/EntityCreationIconSelected.svg"
import UserRights from "../../theme/images/NewSidebarIcons/UserRightsIcon.svg"
import UserRightsSelected from "../../theme/images/NewSidebarIcons/UserRightsIconSelected.svg"
import DashboardNew from "../../theme/images/NewSidebarIcons/DashboardNew.svg"
import ProductNew from "../../theme/images/NewSidebarIcons/ProductNew.svg"
import ProductNewSelected from "../../theme/images/NewSidebarIcons/ProductNewSelected.svg"
import ServiceNew from "../../theme/images/NewSidebarIcons/ServiceNew.svg"
import ServiceNewSelected from "../../theme/images/NewSidebarIcons/ServiceNewSelected.svg"
import PortFolioNew from "../../theme/images/NewSidebarIcons/PortfolioNew.svg"
import PortFolioNewSelected from "../../theme/images/NewSidebarIcons/PortfolioNewSelected.svg"
import SubscriptionsNew from "../../theme/images/NewSidebarIcons/SubscriptionNew.svg"
import SubscriptionsNewSelected from "../../theme/images/NewSidebarIcons/SubscriptionNewSelected.svg"
import CampaignNNew from "../../theme/images/NewSidebarIcons/CampaignNew.svg"
import CampaignNNewSelected from "../../theme/images/NewSidebarIcons/CampaignNewSelected.svg"
import ReportsNew from "../../theme/images/NewSidebarIcons/ReportsNew.svg"
import ReportsNewSelected from "../../theme/images/NewSidebarIcons/ReportsNewSelected.svg"
import OrderManagementNew from "../../theme/images/NewSidebarIcons/OrderManagemenntNew.svg"
import OrderManagementNewSelected from "../../theme/images/NewSidebarIcons/OrderManagementnewSelected.svg"
import PaymentNew from "../../theme/images/NewSidebarIcons/PaymentsNew.svg"
import PaymentNewSelected from "../../theme/images/NewSidebarIcons/PaymentsNewSelected.svg"
import FAQNew from "../../theme/images/NewSidebarIcons/FAQNew.svg"
import FAQNewSelected from "../../theme/images/NewSidebarIcons/FAQNewSelected.svg"
import ChatWithNew from "../../theme/images/NewSidebarIcons/ChatWithRMNew.svg"
import ChatWithNewSelected from "../../theme/images/NewSidebarIcons/ChatWithRMNewSelected.svg"
import MyProspectsNew from "../../theme/images/NewSidebarIcons/MyProspectsNew.svg"
import MyProspectsNewSelected from "../../theme/images/NewSidebarIcons/MyProspectNewSelected.svg"
import MyClientNew from "../../theme/images/NewSidebarIcons/MyClientsNew.svg"
import MyClientNewSelected from "../../theme/images/NewSidebarIcons/MyClientsNewSeleccted.svg"
import UserManagementNew from "../../theme/images/NewSidebarIcons/UserManagemenntNew.svg"
import UserManagementNewSelected from "../../theme/images/NewSidebarIcons/UserManagementNewSelected.svg"
import VendormanagementNew from "../../theme/images/NewSidebarIcons/VendorManagementNew.svg"
import VendormanagementNewSelected from "../../theme/images/NewSidebarIcons/VendormanagemenntnewSelected.svg"
import FinacialProductNew from "../../theme/images/NewSidebarIcons/FinanccialProoductNew.svg"
import FinacialProductNewSelected from "../../theme/images/NewSidebarIcons/FinancialProductNewSelected.svg"
import FinancialServiceNew from "../../theme/images/NewSidebarIcons/FinancialServiceNew.svg"
import FinacialServiceNewSelected from "../../theme/images/NewSidebarIcons/FinancialServiceNewSelected.svg"
import LifeStyleProductNew from "../../theme/images/NewSidebarIcons/LifeStyleProductNew.svg"
import LifeStyleProducctNewSelected from "../../theme/images/NewSidebarIcons/LifeStyleProductNewSelected.svg"
import LifeStyleServiceNew from "../../theme/images/NewSidebarIcons/LifeStyleServiceNew.svg"
import LifeStyleServiceNewSelected from "../../theme/images/NewSidebarIcons/LifeStyleServiceNewSelected.svg"
import RMPerfomanceNew from "../../theme/images/NewSidebarIcons/RMPerformanceNew.svg"
import RMPerfomanceNewSelected from "../../theme/images/NewSidebarIcons/RMPerfomanceNewSelected.svg"
import CustomerFeedbackNew from "../../theme/images/NewSidebarIcons/CustomerFeedbackNew.svg"
import CustomerFeedbackNewSelected from "../../theme/images/NewSidebarIcons/CustomerFeedbbackNewSelected.svg"
import ReferenceDataMNew from "../../theme/images/NewSidebarIcons/DataManagementNew.svg"
import ReferenceDataMNewSelected from "../../theme/images/NewSidebarIcons/DataManagementNewSelected.svg"
import DashboardSelected from "../../theme/images/NewSidebarIcons/DashboardNewSelected.svg"
import EnquiryB from "../../theme/images/blackBackgroundIcons/Enquiry.svg"
import PaymentB from "../../theme/images/blackBackgroundIcons/Dashboard.svg"
import kairosBot from "../../theme/images/blackBackgroundIcons/kairosBot.png"
import ASKGaiaNew from "../../theme/images/NewSidebarIcons/ASKGaiaNew.svg"
import ASKGaiaNewSelected from "../../theme/images/NewSidebarIcons/ASKGaiaNewSelected.svg"
import liveIcon from "../../theme/images/blackBackgroundIcons/Live_Black BG.png"
import liveIconB from "../../theme/images/blackBackgroundIcons/Live_white BG.png"
import RMPerfomIcon from '../../theme/images/blackBackgroundIcons/RM perf_wihite BG.png'
import FAQ from '../../theme/images/faq.png'
import Chat from '../../theme/images/chat.png'

const userChecks = JSON.parse(window.localStorage.getItem("user"))
const isRmChecks = userChecks?.userTypeId?.description === "Entity RM" ? true : false


const NavButton = [{
    index: 0,
    text: "Dashboard",
    icon: Dashboard,
    icon2: DashboardNew,
    icon3: DashboardSelected,
    path: "/dashboard"
},
{
    index: 26,
    text: "Ask Gaia",
    icon: kairosBot,
    icon2: ASKGaiaNew,
    icon3: ASKGaiaNewSelected,
    path: "/kairosbot"
},
{
    index: 1,
    text: "User Management",
    icon: UserManagement,
    icon2: UserManagementNew,
    icon3: UserManagementNewSelected,
    path: "/userManagement"
}, {
    index: 2,
    text: "Vendor Management",
    icon: VendorManagement,
    icon2: VendormanagementNew,
    icon3: VendormanagementNewSelected,

    path: "/vendorManagement"

},
{
    index: 3,
    text: "Product",
    icon: Product,
    icon2: ProductNew,
    icon3: ProductNewSelected,

    path: "/productsCatalog"

}, {
    index: 4,
    text: "Service",
    icon: services,
    icon2: ServiceNew,
    icon3: ServiceNewSelected,
    path: "/serviceCatalog"

},
{
    index: 5,
    text: "Financial Products",
    icon: Product,
    icon2: FinacialProductNew,
    icon3: FinacialProductNewSelected,
    path: "/financialproducts"
},
{
    index: 6,
    text: "Lifestyle Products",
    icon: Product,
    icon2: LifeStyleProductNew,
    icon3: LifeStyleProducctNewSelected,
    path: "/lifestyleproducts"
},
{
    index: 27,
    text: "Live Trade - Bordeaux",
    icon: liveIconB,
    icon2: liveIcon,

    path: "/bordeaux"
},
{
    index: 7,
    text: "Portfolio",
    icon: Portfolio,
    icon2: PortFolioNew,
    icon3: PortFolioNewSelected,

    path: "/myPortfolio"
},
{
    index: 8,
    text: "Subscriptions",
    icon: Subscriptions,
    icon2: SubscriptionsNew,
    icon3: SubscriptionsNewSelected,
    path: "/mySubscriptions"
},
{
    index: 10,
    text: "Financial Services",
    icon: services,
    icon2: FinancialServiceNew,
    icon3: FinacialServiceNewSelected,
    path: "/financialservices"
}, {
    index: 11,
    text: "Lifestyle Services",
    icon: services,
    icon2: LifeStyleServiceNew,
    icon3: LifeStyleServiceNewSelected,

    path: "/lifestyleservices"
}, {
    index: 12,
    text: "Campaign",
    icon: Campaign,
    icon2: CampaignNNew,
    icon3: CampaignNNewSelected,
    path: "/campaignstatus"
},
{
    index: 13,
    text: "My Prospects",
    icon: Campaign,
    icon2: MyProspectsNew,
    icon3: MyProspectsNewSelected,
    path: "/prospects"

}
    ,
{
    index: 14,
    text: "Divider",
},
{
    index: 15,
    text: !isRmChecks ? "Clients" : "My Clients",
    icon: Clients,
    icon2: MyClientNew,
    icon3: MyClientNewSelected,
    path: !isRmChecks ? "/clients" : "/myClients"

},
{
    index: 16,
    text: "Reports",
    icon: Reports,
    icon2: ReportsNew,
    icon3: ReportsNewSelected,
    path: "/reports"

}, {
    index: 37,
    text: "RM Performance",
    icon: RMPerfomIcon,
    icon2: RMPerfomanceNew,
    icon3: RMPerfomanceNewSelected,

    // path: ""
}, {
    index: 17,
    text: "Customer Feedback",
    icon: CustomerFeedback,
    icon2: CustomerFeedbackNew,
    icon3: CustomerFeedbackNewSelected,

    path: "/customerFeedback"

}, {
    index: 18,
    text: "General Enquiries",
    icon: Enquiry,
    icon2: EnquiryB,

    path: "/enquiry"

}, {
    index: 19,
    text: "User Rights",
    icon: UserRights,
    icon2: UserRights,
    icon3: UserRightsSelected,

    path: "/userRights"
},
{
    index: 38,
    text: "FO/Entity Creation",
    icon: EntityCreation,
    icon2: EntityCreation,
    icon3: EntityCreationSelected,

    path: "/familyandentityCreations"
},

{
    index: 20,
    text: "Chatbot Queries",
    icon: Enquiry,
    icon2: EnquiryB,

    path: "/chatqueries"
},

{
    index: 22,
    text: "Order Management",
    icon: Payment,
    icon2: OrderManagementNew,
    icon3: OrderManagementNewSelected,

    path: "/orders"
},
{
    index: 23,
    text: "Ref. Data Management",
    icon: Campaign,
    icon2: ReferenceDataMNew,
    icon3: ReferenceDataMNewSelected,

    path: "/referencedata"
},
{
    index: 24,
    text: "Payments",
    icon: Payment,
    icon2: PaymentNew,
    icon3: PaymentNewSelected,

    path: "/payments"
},
{
    index: 25,
    text: "Chart Gpt",
    icon: Payment,
    icon2: PaymentB,

    path: "/chatgpt"
},
{
    index: 46,
    text: "FAQs",
    path: "/enquiry",
    icon2: FAQNew,
    icon3: FAQNewSelected,
    icon: FAQ,
},
{
    index: 47,
    text: isRmChecks ? "Chat" : "Chat with RM",
    path: "/chatqueries",
    icon2: ChatWithNew,
    icon3: ChatWithNewSelected,
    icon: Chat,
},

]

export default NavButton