import React from 'react'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';

function GridTable({ columns, rows, data, ...props }) {
    // Check if rows is not null, not undefined, and has more than zero elements
    const filteredRows = rows && rows.length > 0 ? rows.filter(row => row !== null && row !== undefined) : [];

    return (
        <Box
            padding="0px 10px"
            className
            sx={{
                height: data === 'height' ? '80%' : '75%',
                '& .MuiDataGrid-root': {
                    border: 'none',
                    borderBottom: 'none'
                },
                '& .MuiDataGrid-cellContent': {
                    fontFamily: "Mona Sans !important"
                },
                "& .MuiDataGrid-cell": {
                    border: "none",
                },
                "&.MuiDataGrid-cell:focus": {
                    outline: "none !important",
                },
                "&.MuiDataGrid-actionsCell": {
                    boxSizing: 'none !important',
                    gridGap: '1px !important'
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                    fontFamily: "Mona Sans",
                    fontWeight: "bold",
                },
                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: '#000000',
                    color: 'white',

                    '& .MuiCheckbox-root': {
                        color: 'white',
                        // background: '#FFFFF'
                    }
                },
                '& .MuiDataGrid-sortIcon': {
                    display: 'none',
                }
            }}

        >
            <DataGrid
                disableColumnMenu
                sx={{
                    fontFamily: 'Mona Sans',
                    fontSize: "14px",
                    '& .MuiDataGrid-iconSeparator': {
                        display: 'none !important',
                    },
                    '& .MuiDataGrid-cellContent': {
                        fontFamily: "Mona Sans !important"
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontFamily: "Mona Sans",
                        fontWeight: "bold",
                    },
                    "&.MuiDataGrid-cell--actions": {
                        boxSizing: 'none !important',
                        display: 'flex',
                        gridGap: '1 !important'
                    },
                    "&.MuiDataGrid-cell:focus": {
                        outline: "none !important",
                    },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                        width: '2px',
                        height: '4px'
                    },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar:vertical': {
                        width: '4px',
                        height: '5px'
                    },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                    },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:vertical ': {
                        backgroundColor: '#6A3BE2',
                    },
                }}
                {...props}
                rows={filteredRows}
                columns={columns}
                rowHeight={36}
                headerHeight={25}
                hideFooter={true}
                disableColumnSelector
            />
        </Box>
    )
}
export default GridTable;
