/* eslint-disable */
import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Header from "../../common/Header/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { newPasswordApi, checkEmaliLinkApi } from "../../../services/index";
import { Typography, FormControl, Button, Box, useTheme } from "@mui/material";
import toastr from "toastr";
import { toast } from "react-toastify";
// import adminimage from '../../../theme/images/Adminpageimage.jpg'
import CustomPasswordTextField from "../../common/Form/customPasswordTextField";
import { useSearchParams, useNavigate } from "react-router-dom";
import "./newPassword.css";
import { tokens } from "../../../theme";
import "../../../theme/styles/globalStyles.css";
import {
  AES256_GCM_ENCRYPT,
  AES256_GCM_decrypt,
} from "../../../constant/EncryptionDecryptionUtils";
const CreatePassword = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      const requestBody = {
        encryptedTxt: searchParams?.get("txt").replaceAll(" ", "+"),
      };
      // window.location = `app://kairoswealth/SetPassword/${requestBody.encryptedTxt}`
      const response = await checkEmaliLinkApi(requestBody);
      response?.errorMessage && toastr.error(response?.errorMessage);
      response?.errorMessage && navigate("/login");
    }
    fetchData();
  }, []);
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .trim()
      .required("Password is Required")
      .matches(
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&*.\[\]{}()?\"!@#%&/\\,><':;|_~`=+\-])[a-zA-Z\d!@#\$%\^&*.\[\]{}()?\"!@#%&/\\,><':;|_~`=+\-]{8,}$/,
        "Password Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character (^ $ * . [ ] { } ( ) ? \"! @ # % & / \\ , > < ' : ; | _ ~ ` = + -)"
      ),

    // .min(8, "Must be 8 characters or more")
    // .max(16, "Must be 16 characters or less")
    // .matches(/[a-z]+/, "One lowercase character")
    // .matches(/[A-Z]+/, "One uppercase character")
    // .matches(/[@$!%*#?&]+/, "One special character")
    // .matches(/\d+/, "One number"),
    confirmPassword: Yup.string()
      .trim()
      .required("Confirm Password is Required")
      .matches(
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&*.\[\]{}()?\"!@#%&/\\,><':;|_~`=+\-])[a-zA-Z\d!@#\$%\^&*.\[\]{}()?\"!@#%&/\\,><':;|_~`=+\-]{8,}$/,
        "Confirm Password Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character (^ $ * . [ ] { } ( ) ? \"! @ # % & / \\ , > < ' : ; | _ ~ ` = + -)"
      )
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  const formValues = {
    password: "",
    confirmPassword: "",
  };
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const handleSubmit = async (values) => {
    if (searchParams.get("txt") === null || searchParams?.get("txt") === "") {
      navigate("/login");
    } else if (values.password === values.confirmPassword) {
      const requestBody = {
        encryptedTxt: searchParams?.get("txt").replaceAll(" ", "+"),
        password: await AES256_GCM_ENCRYPT(values.password),
      };
      await newPasswordApi(requestBody).then(async (res) => {
        if (res?.data?.status) {
          toast.success(res.data.statusMessage);
          navigate("/verify", {
            state: {
              ...res.data.body,
              email: await AES256_GCM_decrypt({
                data: { encryptedValue: res?.data?.body?.email },
              }),
              mobile: await AES256_GCM_decrypt({
                data: { encryptedValue: res?.data?.body?.mobile },
              }),
              password: values.password,
            },
          });
        } else {
          toast.error(res.data.errorMessage);
        }
      });
    }
  };

  return (
    <Box
      sx={{
        marginBottom: "25px",
        marginTop: "25px",
        // padding: '24px',

        // marginLeft: "auto",
        // marginRight: "auto",
      }}>
      {/* <LocationDisplay /> */}
      <Header
        title="Set New Password"
        subtitle="Please create a new password that you don’t use on any other site."
        dark={true}
        isNonMobile={isNonMobile}
      />
      <Formik
        initialValues={{ ...formValues }}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount>
        {(formik) => {
          return (
            <Box
            // display="grid"
            // mt={"5px"}
            // gap="20px"
            // gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            // sx={{
            //     "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            // }}
            >
              <Form>
                <FormControl>
                  <CustomPasswordTextField
                    name="password"
                    label="Set new password"></CustomPasswordTextField>
                  <Typography
                    sx={{ margin: "10px 0px" }}
                    color={
                      !isNonMobile
                        ? "white"
                        : true
                        ? colors.grey[100]
                        : colors.grey[700]
                    }>
                    Confirm password
                  </Typography>
                  <CustomPasswordTextField
                    name="confirmPassword"
                    label="Confirm Password"></CustomPasswordTextField>
                </FormControl>
                <Box mt="20px">
                  <Button
                    type="submit"
                    className="changePassword"
                    sx={{ maxWidth: "300px", fontFamily: "Mona Sans" }}
                    variant="contained"
                    disabled={!formik.isValid}>
                    Set New Password
                  </Button>
                </Box>
              </Form>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

// export const LocationDisplay = () => {
//     const location = useLocation()

//     return <div data-testid="location-display">{location.pathname}</div>
// }

export default CreatePassword;
