import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { openModal, setTableColumnData } from '../../../../redux/slices/dashboardModalSlice';
import BARWITHLINE from '../../../common/ChartsComponent/BarWithLine';
import { getClientswiseAUMPaginationsData, getClientswiseInvestedPaginationsData } from '../../../../services/DashboardAPI';
import { createColumnDynamicBar } from '../../../../constant/DynamicTableColumsUtils';
import NoDataImage from "../../../../theme/images/ProductCardsIcons/emptytransactions.svg";

const BarChartController = (props) => {
    const dispatch = useDispatch();
    const [loadContent, setLoadContent] = React.useState(true);
    const [pageNumber, setPageNumber] = React.useState(1);
    const user = JSON.parse(localStorage.getItem('user'));
    const isHni = ["Client Head", "Client Family Member"].includes(user?.userTypeId?.description);

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLoadContent(false);
        }, 500);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    const apiCalls = async () => {
        const passingObject = {
            loginUserId: user?.userId,
            pageNo: pageNumber - 1,
            pageSize: 10,
        };
        return props?.category === 'AUM'
            ? await getClientswiseAUMPaginationsData(passingObject)
            : await getClientswiseInvestedPaginationsData(passingObject);
    };

    const dataModalReducer = async () => {
        const dataObjects = await apiCalls();
        if (dataObjects) {
            const dataKey = props?.category === 'AUM'
                ? 'clientsCurrentInvestedWealthAum'
                : 'clientsInvestedWealth';
            const data = dataObjects?.data?.body?.[dataKey];
            const columsDynamics = await createColumnDynamicBar(data[0], props?.category);
            dispatch(openModal(true));
            dispatch(setTableColumnData({
                column: columsDynamics,
                titleofModal: props?.title,
                data,
                loading: false,
            }));
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                background: '#FFFFFF',
                border: '1px solid #EBEBEB',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Grid container sx={{ justifyContent: 'space-between', flexDirection: 'row', p: '20px 15px' }}>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Mona Sans',
                            fontSize: '18px',
                            fontWeight: 500,
                            color: '#1E1E1E',
                            lineHeight: '24px',
                        }}
                    >
                        {props?.title}
                    </Typography>
                </Grid>
                {!isHni && (
                    <Grid item>
                        <Typography
                            sx={{
                                fontFamily: 'Mona Sans',
                                fontSize: '10px',
                                textDecoration: 'underline',
                                color: '#000000',
                                cursor: 'pointer',
                            }}
                            onClick={dataModalReducer}
                        >
                            See client wise details
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid item sx={{ flexGrow: 1, p: '15px' }}>
                {loadContent ? (
                    <CenterErrorEmpty isloading={loadContent} />
                ) : (
                    (props?.data?.data?.length > 0) ? (
                        <BARWITHLINE
                            data={props?.data}
                            category={props?.category}
                            setPageNumber={setPageNumber}
                            pageNumber={pageNumber}
                            type={props?.type}
                        />
                    ) : (
                        <CenterErrorEmpty isloading={props.isloading} />
                    )
                )}
            </Grid>
        </Box>
    );
};

const CenterErrorEmpty = (props) => {
    return (
        <Grid container alignItems="center" justifyContent="center" minHeight={"120px"}>
            <Grid item>
                <Typography align="center" variant="h5">
                    {props.isloading ? 'Loading...' :
                        <Grid sx={{ display: "flex", flexDirection: "column" }}>
                            <img src={NoDataImage} alt="No data available" sx={{ paddingX: "10px", marginBottom: 1 }} />
                            <>{"No data available"}</>
                        </Grid>}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default BarChartController;
