import { createSlice } from '@reduxjs/toolkit';

const initialState = {api1:{},api2:{},api3:{},api4:{},api5:{},api6:{}};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateAPI1: (state, action) => {
      const { user, data } = action.payload;
      if (!state[user]) state[user] = {};
      state[user].api1 = {
        ...state[user].api1, // preserve existing properties
        ...data // add/overwrite with new properties
      };
    
    },
    updateAPI2: (state, action) => {
      const { user, data } = action.payload;
      if (!state[user]) state[user] = {};
      state[user].api2 = {
        ...state[user].api2, // preserve existing properties
        ...data // add/overwrite with new properties
      };
    },
    updateAPI3: (state, action) => {
      const { user, data } = action.payload;
      
      if (!state[user]) state[user] = {};
      state[user].api3 = {
        ...state[user].api3, // preserve existing properties
        ...data // add/overwrite with new properties
      };
      
    },
    updateAPI4: (state, action) => {
      const { user, data } = action.payload;
      if (!state[user]) state[user] = {};
      state[user].api4 = {
        ...state[user].api4, // preserve existing properties
        ...data // add/overwrite with new properties
      };
      
    },
    updateAPI5: (state, action) => {
      const { user, data } = action.payload;
      if (!state[user]) state[user] = {};
      state[user].api5 = {
        ...state[user].api5, // preserve existing properties
        ...data // add/overwrite with new properties
      };
      
    },
    updateAPI6: (state, action) => {
      const { user, data } = action.payload;
      if (!state[user]) state[user] = {};
      state[user].api6 = {
        ...state[user].api6, // preserve existing properties
        ...data // add/overwrite with new properties
      };
      
    }
  }
});

export const { updateAPI1, updateAPI2, updateAPI3, updateAPI4 ,updateAPI5, updateAPI6 } = dashboardSlice.actions;
export default dashboardSlice.reducer;
