import React from 'react'
import { Grid, Typography } from "@mui/material"
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu'
import { useDispatch } from 'react-redux';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import colorKairos from "../../../theme/images/GuestKairos Logo.svg";
import { setGuestOpen } from "../../../redux/slices/navSlice";

const guestUser = JSON.parse(localStorage.getItem("guestUser"));

function GuestHeader() {
    const dispatch = useDispatch()
    const handleSideOpen = (e) => {
        e.stopPropagation();
        dispatch(setGuestOpen(true))
    }
    return (
        <>
            <Grid className="header" sx={{ background: { xs: "#171B24", sm: "white" } }}>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }} >
                    <Grid item xl={2} lg={5} md={2} sm={4} xs={4}>
                        <Typography sx={{ fontSize: "17px", fontWeight: "100", display: { xs: "none", sm: "block" } }}>{'Client-Dashboard'}</Typography>
                        <Typography ml={3} sx={{ fontSize: "22px", fontWeight: "550", marginLeft: { xs: "-9%" }, display: { xs: "flex", sm: "none" }, alignItems: { xs: "center", sm: "none" } }} ><MenuIcon onClick={handleSideOpen} sx={{ color: "white", marginRight: "19%", fontSize: "30px" }} /><img src={colorKairos} alt="logo" /></Typography>

                    </Grid>
                    <Grid item lg={4} md={7} xs={2} sm={4} display={{ lg: "block", md: "block", xs: "block", sm: "block" }}></Grid>
                    <Grid item xl={3} lg={3} md={3} sm={4} xs={6} >
                        <div >
                            <div style={{ display: "flex", flexGrow: 0.5, alignItems: "center", float: "right", marginLeft: "5%", marginRight: "7%" }}>
                                <Avatar alt="Remy Sharp" sx={{ width: "25px", height: "25px" }} />

                                <Typography sx={{ fontSize: "12px" }} display={{ xs: "none", lg: "block", md: "block", sm: "block" }} pl={1}>{guestUser?.firstName}</Typography>
                            </div>
                            <Badge showZero color="primary" style={{ flexGrow: 1, float: "right", marginTop: "1.5%" }}>

                                <NotificationsNoneOutlinedIcon sx={{ color: { xs: "white", sm: "black" }, width: "18px", height: "18px", }} />
                            </Badge>
                        </div>
                    </Grid>
                </Grid>
            </Grid >
            <Grid sx={{ display: { xs: "block", sm: "none" }, padding: "10px 14px ", background: "#F7F7F7", border: "1.1px solid #E0E0E0" }}>
                <div style={{ fontSize: "17px", fontWeight: "600" }}>{'Client-Dashboard'}</div>
            </Grid>
        </>
    )
}

export default GuestHeader
