/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
    TextField,
    Button,
    Select,
    MenuItem,
    Grid,
    Typography,
} from "@mui/material";
import "../../../theme/styles/Catalog.css"
import BordeauxProductsCard from "../../common/BordeauxCardComponents/BordeauxProductCard"
import LiveTradeIcon from '../../../theme/images/livetradeicon.png'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fieldValuesBordeauxFilters } from "./FormUtilisBordeaux/InitialValues"
import { debounce } from 'lodash';
import Loader from "../../common/Loader/Loader";
import InputAdornment from '@mui/material/InputAdornment';
import SelectInputMultiple from "../../common/Select/SelectMultiple"
import { FormModalBordeauxFilter } from "./FormModalBordeaux/FormModalBordeaux"
import { Formik } from "formik";
import { LoadingButton } from '@mui/lab';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box';
import { getAllBordeauxLiveData } from "../../../services/catalogProductandService"

let sortItems = [{ id: 1, description: "Price: Low to High" }, { id: 2, description: "Price: High to Low" },]

const BordeauxLiveData = () => {
    const [wines, setWines] = useState([]);
    const [isLoading, setisLoading] = React.useState(true);
    const [filteredWines, setFilteredWines] = useState([]);
    const [windowSize, setWindowSize] = React.useState([window.innerWidth, window.innerHeight,]);

    const [searchTerm, setSearchTerm] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [formikValuesSet, setFormikValues] = useState({ vintageFilter: [], regionFilter: [], growerFilter: [], colorFilter: [] });
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const [regionList, setRegionList] = React.useState([]);
    const [onloadEmpty, setonloadEmpty] = React.useState(false);
    const [growerList, setGrowerList] = React.useState([]);
    const [colorList, setColorList] = React.useState([]);
    const [vintageList, setVintageList] = React.useState([]);
    let { formFieldbordeauxFilters: { regionFilter, vintageFilter, growerFilter, colorFilter } } = FormModalBordeauxFilter

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearchTermChange = debounce((value) => {
        setSearchWithValues(value, formikValuesSet);
    }, 30);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => { window.removeEventListener('resize', handleWindowResize); };
    });

    const setSearchWithValues = async (term, filtersValues) => {
        console.log(filtersValues, "filtersValues")
        setSearchTerm(term)
        setisLoading(true)
        await applyFilters(filtersValues, term);
    }

    // eslint-disable-next-line
    const fetchData = async () => {
        try {
            const response = await getAllBordeauxLiveData();
            setWines(response?.data);

            let InitialLoadData = await response?.data?.filter(wine => {
                return wine.offer_price && parseFloat(wine.offer_price) !== 0.00;
            });
            setFilteredWines(InitialLoadData);
            setonloadEmpty(true)

            const uniqueRegions = response?.data?.reduce((result, item) => {
                const region = item.region;
                if (!result.find((obj) => obj.id === region)) {
                    result.push({ id: region, description: region });
                }
                return result;
            }, []);
            setRegionList(uniqueRegions);

            const uniqueGrower = response?.data?.reduce((result, item) => {
                const grower = item.grower;
                if (!result.find((obj) => obj.id === grower) && grower) {
                    result.push({ id: grower, description: grower });
                }
                return result;
            }, []);
            setGrowerList(uniqueGrower);

            const uniqueVintage = response?.data?.reduce((result, item) => {
                const vintage = item.vintage;
                if (!result.find((obj) => obj.id === vintage)) {
                    result.push({ id: vintage, description: vintage });
                }
                return result;
            }, []);
            setVintageList(uniqueVintage)

            const uniqueColor = response?.data?.reduce((result, item) => {
                const colour = item.colour;
                if (!result.find((obj) => obj.id === colour)) {
                    result.push({ id: colour, description: colour });
                }
                return result;
            }, []);
            setColorList(uniqueColor)

            setisLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const applyFilters = async (values, searchTermsPassing) => {
        let filteredData = wines;
        if (values?.vintageFilter.length > 0) {
            filteredData = filteredData.filter(wine => values?.vintageFilter.includes(wine.vintage));
        }
        if (values?.regionFilter.length > 0) {
            filteredData = filteredData.filter(wine => values?.regionFilter.includes(wine.region));
        }
        if (values?.growerFilter.length > 0) {
            filteredData = filteredData.filter(wine => values?.growerFilter.includes(wine.grower));
        }
        if (values?.colorFilter.length > 0) {
            filteredData = filteredData.filter(wine => values?.colorFilter.includes(wine.colour));
        }

        if (searchTermsPassing) {
            const searchTermLower = searchTermsPassing?.toLowerCase();
            filteredData = filteredData.filter((wine) =>
                wine.name.toLowerCase().includes(searchTermLower)
            );
        }
        if (sortOrder) {
            // eslint-disable-next-line 
            if (sortOrder == 1) {
                filteredData.sort((a, b) => parseFloat(a.offer_price) - parseFloat(b.offer_price));
            }
            else {
                filteredData.sort((a, b) => parseFloat(b.offer_price) - parseFloat(a.offer_price));
            }
        }
        filteredData = filteredData.filter(wine => {
            return wine.offer_price && parseFloat(wine.offer_price) !== 0.00;
        });
        setFilteredWines(filteredData);
        setisLoading(false)
    };

    const handleSortChange = (sortType) => {
        let sortedData = [...filteredWines];

        switch (sortType) {
            case 1:
                sortedData.sort((a, b) => parseFloat(a.offer_price) - parseFloat(b.offer_price));
                break;
            case 2:
                sortedData.sort((a, b) => parseFloat(b.offer_price) - parseFloat(a.offer_price));
                break;
            default:
                // Default to ascending order by price
                sortedData.sort((a, b) => parseFloat(a.offer_price) - parseFloat(b.offer_price));
        }
        setSortOrder(sortType);
        setFilteredWines(sortedData);
    };
    const handleFormSubmit = async (values) => {
        setFormikValues(values);
        setButtonLoading(true)
        setisLoading(true)
        await applyFilters(values, searchTerm);
        setTimeout(() => {
            setButtonLoading(false)
        }, 900)
    };

    return (
        <>
            {(isLoading || buttonLoading) && <Loader isLoading={isLoading || buttonLoading} />}
            <div style={{}}>
                <Box style={{ width: "100%" }}>
                    <Typography sx={{ fontFamily: "Mona Sans", fontSize: "12px", textAlign: "center" }}>For urgent shipping, opt for Express Delivery</Typography>
                </Box>
                <Grid container spacing={2} lg={12} md={12} sm={12} xs={12} sx={{ paddingX: "10px", marginTop: 1, display: windowSize[0] >= 1270 ? "block" : "none" }}>
                    <Typography sx={{ fontFamily: "Mona Sans", fontSize: "12px", marginLeft: "10px", }}>Filters:</Typography>
                    <Box style={{
                        border: "1px solid #ebebeb ",
                        marginLeft: "10px",
                        width: "100%",
                        marginBottom: "10px", padding: "10px",
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                    }}>
                        <Formik onSubmit={handleFormSubmit}
                            initialValues={fieldValuesBordeauxFilters}  >
                            {({// eslint-disable-next-line
                                values, errors, touched, handleBlur, handleChange, handleSubmit,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container flexDirection={"row"} spacing={1} lg={12} md={12} sm={12} xs={12} >
                                        <Grid item lg={2} md={6} sm={12} xs={12}>
                                            <SelectInputMultiple fixedwidth={"100%"} fixedHeight={32} name={vintageFilter.name} header={vintageFilter.label} data={vintageList} multiple={"multiple"} fontCustom={"Mona Sans"} style={{ fontFamily: "Mona Sans", }} />
                                        </Grid>
                                        <Grid item lg={2} md={6} sm={12} xs={12}>
                                            <SelectInputMultiple fixedwidth={"100%"} fixedHeight={32} name={growerFilter.name} header={growerFilter.label} data={growerList} multiple={"multiple"} fontCustom={"Mona Sans"} style={{ fontFamily: "Mona Sans", }} />
                                        </Grid>
                                        <Grid item lg={2} md={6} sm={12} xs={12}>
                                            <SelectInputMultiple fixedwidth={"100%"} fixedHeight={32} name={regionFilter.name} header={regionFilter.label} data={regionList} multiple={"multiple"} fontCustom={"Mona Sans"} style={{ fontFamily: "Mona Sans", }} />
                                        </Grid>
                                        <Grid item lg={2} md={6} sm={12} xs={12}>
                                            <SelectInputMultiple fixedwidth={"100%"} fixedHeight={32} name={colorFilter.name} header={colorFilter.label} data={colorList} multiple={"multiple"} fontCustom={"Mona Sans"} style={{ fontFamily: "Mona Sans", }} />
                                        </Grid>
                                        <Grid item lg={1.9} md={6} sm={12} xs={12}>
                                            <Grid container flexDirection={"column"}>
                                                <Grid item>
                                                    <div className='whiteSpaceAllign'></div>
                                                </Grid>
                                                <Grid item>
                                                    {buttonLoading && <LoadingButton loading loadingIndicator="Filtering" className='applyingButtonStyle' sx={{ width: "110px !important" }} variant="outlined">
                                                        Filtering...
                                                    </LoadingButton>}
                                                    {!buttonLoading && <Button className='applyButtonFilterStyle' variant="contained" type="submit">
                                                        Filter
                                                    </Button>}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem />

                                        <Grid item lg={2} md={6} sm={12} xs={12} flexDirection="column" >
                                            <Typography variant="h6" className='searchTextCSS'>
                                                Search
                                            </Typography>
                                            <TextField
                                                variant="outlined"
                                                type="text"
                                                value={searchTerm}
                                                onChange={(e) => handleSearchTermChange(e.target.value)}
                                                placeholder={"Search"}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                                }}
                                                className='textInput'
                                                sx={{
                                                    width: "100%",
                                                    '& input::placeholder': {
                                                        fontFamily: "Mona Sans",
                                                        textOverflow: 'ellipsis !important',
                                                        color: '#BBC5D5 !important',
                                                        opacity: 1,
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        fontFamily: "Mona Sans",
                                                        height: '32px !important',
                                                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                        borderRadius: '8px',
                                                    },
                                                }} />
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Grid>
                {/** Filters display on small screens */}
                <Grid sx={{ display: windowSize[0] < 1270 ? "block" : "none" }}>
                    <div style={{ marginTop: "3px" }}>
                        <Accordion style={{ backgroundColor: "#fff", boxShadow: "none" }}>
                            <AccordionSummary style={{ backgroundColor: "#fafafb", marginTop: "-1px" }}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => handleSearchTermChange(e.target.value)}
                                    placeholder={"Search"}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                    }}
                                    className='textInput'
                                    sx={{
                                        width: "100%",
                                        '& input::placeholder': {
                                            fontFamily: "Mona Sans",
                                            textOverflow: 'ellipsis !important',
                                            color: '#fff !important',
                                            opacity: 1,
                                        },
                                        '& .MuiInputBase-root': {
                                            fontFamily: "Mona Sans",
                                            height: '32px !important',
                                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                                            borderRadius: '8px',
                                        },
                                    }} />
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: "#fff" }}>
                                <Formik onSubmit={handleFormSubmit}
                                    initialValues={fieldValuesBordeauxFilters}  >
                                    {({// eslint-disable-next-line
                                        values, errors, touched, handleBlur, handleChange, handleSubmit,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Grid container flexDirection={"row"} spacing={1} lg={12} md={12} sm={12} xs={12} >
                                                <Grid item lg={2} md={6} sm={12} xs={12}>
                                                    <SelectInputMultiple fixedwidth={"100%"} fixedHeight={32} name={vintageFilter.name} header={vintageFilter.label} data={vintageList} multiple={"multiple"} fontCustom={"Mona Sans"} style={{ fontFamily: "Mona Sans", }} />
                                                </Grid>
                                                <Grid item lg={2} md={6} sm={12} xs={12}>
                                                    <SelectInputMultiple fixedwidth={"100%"} fixedHeight={32} name={growerFilter.name} header={growerFilter.label} data={growerList} multiple={"multiple"} fontCustom={"Mona Sans"} style={{ fontFamily: "Mona Sans", }} />
                                                </Grid>
                                                <Grid item lg={2} md={6} sm={12} xs={12}>
                                                    <SelectInputMultiple fixedwidth={"100%"} fixedHeight={32} name={regionFilter.name} header={regionFilter.label} data={regionList} multiple={"multiple"} fontCustom={"Mona Sans"} style={{ fontFamily: "Mona Sans", }} />
                                                </Grid>
                                                <Grid item lg={2} md={6} sm={12} xs={12}>
                                                    <SelectInputMultiple fixedwidth={"100%"} fixedHeight={32} name={colorFilter.name} header={colorFilter.label} data={colorList} multiple={"multiple"} fontCustom={"Mona Sans"} style={{ fontFamily: "Mona Sans", }} />
                                                </Grid>
                                                <Grid item lg={1.9} md={6} sm={12} xs={12}>
                                                    <Grid container flexDirection={"column"}>
                                                        <Grid item>
                                                            <div className='whiteSpaceAllign'></div>
                                                        </Grid>
                                                        <Grid item>
                                                            {buttonLoading && <LoadingButton loading loadingIndicator="Filtering" className='applyingButtonStyle' sx={{ width: "110px !important" }} variant="outlined">
                                                                Filtering...
                                                            </LoadingButton>}
                                                            {!buttonLoading && <Button className='applyButtonFilterStyle' variant="contained" type="submit">
                                                                Filter
                                                            </Button>}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Formik>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Grid>

                {/** Title and Sort by option */}
                <Grid container flexDirection={"row"} spacing={0} p={"10px"}>
                    <Grid item xs={8} lg={4} container flexDirection={"row"} >
                        <Grid> <img alt="icon" src={LiveTradeIcon} loading="lazy" /></Grid>
                        <Grid p="5px" >  <Typography noWrap className="headerTypo">{"Live trade - Bordeaux "}</Typography></Grid>

                    </Grid>
                    <Grid item xs={1} lg={4} p="10px" sx={{ display: { xs: "none", lg: "block" } }}>
                        <Divider orientation="horizontal" variant="fullWidth" flexItem />
                    </Grid>
                    <Grid xs={4} lg={4} item flexDirection={"row"} >
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="Bordeaux-simple-select-autowidth"
                            value={sortOrder}
                            sx={{
                                fontFamily: "Mona Sans",
                                width: "100%",
                                height: "24px",
                                fontSize: "12px",
                                backgroundColor: "#fff",
                                '& input::placeholder': {
                                    textOverflow: 'ellipsis !important',
                                    color: '#363636 !important',
                                    fontFamily: "Mona Sans",
                                    opacity: 1,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: '8px',
                                },
                                '& .MuiSelect-select .notranslate::after': {
                                    fontFamily: "Mona Sans !important",
                                    textOverflow: 'ellipsis !important',
                                    opacity: 1,
                                    content: '"Sort By"',
                                    color: '#BBC5D5 !important',
                                },

                                '& .MuiInputBase-root': {
                                    fontFamily: "Mona Sans",
                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                    borderRadius: '8px',
                                },
                            }}
                            onChange={(e) => {
                                handleSortChange(e.target.value)
                            }}
                            inputProps={{ 'aria-label': 'Without label' }}  >
                            {sortItems?.map((datas, index) =>
                                <MenuItem key={datas.id} value={datas.id}  >{datas.description}</MenuItem>
                            )}
                        </Select>
                    </Grid>
                </Grid>

                {/** Main wine cards display */}
                <div style={{ marginTop: "5px" }}>
                    {!isLoading && <Box flexGrow={1} marginBottom={"70px"}>
                        <Grid container spacing={{ xs: 0, md: 3, lg: 2 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12 }} pl={"20px"} pt={"20px"} pb={"50px"}>
                            {filteredWines?.map((items, index) => (
                                <Grid xs={12} sm={12} md={6} lg={4} key={index} justifyContent={"center"} display={"flex"}  >
                                    <BordeauxProductsCard data={items} url={"product"} />
                                </Grid>
                            ))}
                            {onloadEmpty && filteredWines?.length === 0 && <Typography sx={{ marginLeft: "30px" }}>No Records</Typography>}
                        </Grid>
                    </Box>}
                </div>
            </div>
        </>

    );
};

export default BordeauxLiveData;
