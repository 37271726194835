import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from "@mui/material";
import { jwtDecode } from 'jwt-decode';
import Loader from "../../common/Loader/Loader";
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box';
import CardsDisplay from "../../common/CatalogCard/CatalogCard"
import CardsDisplayList from "../../common/CatalogCard/CatalogCardList"
import "../../../theme/styles/Catalog.css"
import ToggleButton from '@mui/material/ToggleButton';
import { getAllDetailsListCatalog } from "../../../services/catalogProductandService"
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CardsDisplayIcon from "../../../theme/images/ProductPageIcons/cards_display.svg"
import ListDisplayIcon from "../../../theme/images/ProductPageIcons/list_display.svg"
import { useSelector, useDispatch } from "react-redux";
import { CONSTANT_ID } from "../../../constant/ConstantsId"
import { setIsGridProductDisplay } from "../../../redux/slices/navSlice"
import BordeauxLiveData from "../ThirdPartySections/BordeauxLiveData"
import { updateFinancialProducts, updateLifestyleProducts, updateFinancialServices, updateLifestyleServices } from "../../../redux/slices/ProductServiceSlices"
// Main component containing the products
function TabPanel(props) {
    const { children, value, index, listitems, description, isbordeauxenabled, ...other } = props;
    const user = JSON.parse(localStorage.getItem("user"));
    const decodedToken = jwtDecode(user?.idToken);
    const [datalist, setDatalist] = React.useState(null);
    const [loadingInt, setLoadingInt] = React.useState(true);
    const userDataViewTabPanel = useSelector(state => state.productService.users[btoa(decodedToken?.email)]);
    const [readValueForDataFulls, setReadValueForDataFulls] = React.useState(() => {
        // Depending on Value selected, data binding will assign 
        let dataFulls;
        if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_PRODUCT) {
            dataFulls = userDataViewTabPanel?.financialProducts?.dataFull;
        } else if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_SERVICE) {
            dataFulls = userDataViewTabPanel?.financialServices?.dataFull;
        } else if (props?.valueofProductID === CONSTANT_ID?.LIFESTYLE_PRODUCT) {
            dataFulls = userDataViewTabPanel?.lifestyleProducts?.dataFull;
        } else {
            dataFulls = userDataViewTabPanel?.lifestyleServices?.dataFull;
        }
        return dataFulls ? Object.values(dataFulls) : [];
    });
    // eslint-disable-next-line
    const [readValueForDataFullsListItem, setReadValueForDataFullsListItem] = React.useState(() => {
        let assetClassesListT;
        // Depending on Value selected, data binding will assign 
        if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_PRODUCT) {
            assetClassesListT = userDataViewTabPanel?.financialProducts?.assetClassesList;
        } else if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_SERVICE) {
            assetClassesListT = userDataViewTabPanel?.financialServices?.assetClassesList;
        } else if (props?.valueofProductID === CONSTANT_ID?.LIFESTYLE_PRODUCT) {
            assetClassesListT = userDataViewTabPanel?.lifestyleProducts?.assetClassesList;
        } else {
            assetClassesListT = userDataViewTabPanel?.lifestyleServices?.assetClassesList;
        }
        return assetClassesListT ? Object.values(assetClassesListT) : [];//Object.values(assetClassesList || {});
    });

    React.useEffect(() => {
        const fetchData = async () => {
            // Get the Particular category
            const data = await getParticularPrimaryData(value);
            setDatalist(data);
        };
        if (value !== 0) {
            setLoadingInt(true)
            setTimeout(() => {
                fetchData();
            }, 1000)

        }
        // eslint-disable-next-line 
    }, [value, props?.alignment]);

    //Methods to set the state value, depends on its types
    const setReadValueForDataFullsListItemCustom = async () => {
        let assetClassesListTemp;
        if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_PRODUCT) {
            assetClassesListTemp = userDataViewTabPanel?.financialProducts?.assetClassesList;
        } else if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_SERVICE) {
            assetClassesListTemp = userDataViewTabPanel?.financialServices?.assetClassesList;
        } else if (props?.valueofProductID === CONSTANT_ID?.LIFESTYLE_PRODUCT) {
            assetClassesListTemp = userDataViewTabPanel?.lifestyleProducts?.assetClassesList;
        } else {
            assetClassesListTemp = userDataViewTabPanel?.lifestyleServices?.assetClassesList;
        }
        setReadValueForDataFullsListItem(assetClassesListTemp ? Object.values(assetClassesListTemp) : []);

    };
    //Methods to set the state value, depends on its types
    const setReadValueForDataFullsListItemCustomDataList = () => {
        let dataFulls;
        if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_PRODUCT) {
            dataFulls = userDataViewTabPanel?.financialProducts?.dataFull;
        } else if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_SERVICE) {
            dataFulls = userDataViewTabPanel?.financialServices?.dataFull;
        } else if (props?.valueofProductID === CONSTANT_ID?.LIFESTYLE_PRODUCT) {
            dataFulls = userDataViewTabPanel?.lifestyleProducts?.dataFull;
        } else {
            dataFulls = userDataViewTabPanel?.lifestyleServices?.dataFull;
        }
        setReadValueForDataFulls(dataFulls ? Object.values(dataFulls) : []);
    };
    // the useeffect triggers when value of type change
    React.useEffect(() => {
        setReadValueForDataFullsListItemCustomDataList();
        setReadValueForDataFullsListItemCustom();
        // eslint-disable-next-line 
    }, [props?.valueofProductID, userDataViewTabPanel])

    function generateRandomInteger(max) {
        return Math.floor(Math.random() * max) + 1;
    }

    //Methods to find the ndivdual category 
    async function getParticularPrimaryData(position) {
        let dataFullsTemp;
        if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_PRODUCT) {
            dataFullsTemp = Object.values(userDataViewTabPanel?.financialProducts?.dataFull);
        } else if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_SERVICE) {
            dataFullsTemp = Object.values(userDataViewTabPanel?.financialServices?.dataFull);
        } else if (props?.valueofProductID === CONSTANT_ID?.LIFESTYLE_PRODUCT) {
            dataFullsTemp = Object.values(userDataViewTabPanel?.lifestyleProducts?.dataFull);
        } else {
            dataFullsTemp = Object.values(userDataViewTabPanel?.lifestyleServices?.dataFull);
        }
        //     console.log("getParticularPrimaryData --------> 1",dataFullsTemp)
        //     let assetClassesListT;
        // // Depending on Value selected, data binding will assign 
        // if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_PRODUCT) {
        //     assetClassesListT = userDataViewTabPanel?.financialProducts?.assetClassesList;
        // } else if (props?.valueofProductID === CONSTANT_ID?.FINANCIAL_SERVICE) {
        //     assetClassesListT = userDataViewTabPanel?.financialServices?.assetClassesList;
        // } else if (props?.valueofProductID === CONSTANT_ID?.LIFESTYLE_PRODUCT) {
        //     assetClassesListT = userDataViewTabPanel?.lifestyleProducts?.assetClassesList;
        // } else {
        //     assetClassesListT = userDataViewTabPanel?.lifestyleServices?.assetClassesList;
        // }
        let filterTypes = readValueForDataFullsListItem[position];// Object.values(assetClassesListT)[position];
        let dataresult = dataFullsTemp?.filter(function (e) {
            return e.category === filterTypes?.description;
        });

        if (dataresult) {
            setLoadingInt(false)
            return dataresult[0];
        }
        else {
            setLoadingInt(false)
            return { category: filterTypes?.description, productServiceCatalogueResponseDto: [] }
        }
    }

    return (
        <div
            role="tabpanel"
            {...other}
            hidden={value !== index}
            id={`vertical-tabpaneld-${index}`}
            aria-labelledby={`vertical-tabd-${index}`}
        >
            {/** Generate the cards for the All asset classes */}
            {value === index && value < 1 && (
                <>
                    {readValueForDataFulls && readValueForDataFulls?.length > 0 && readValueForDataFulls?.map(function (item, i) {
                        return <Box key={"box" + i}>
                            {item?.productServiceCatalogueResponseDto?.length > 0 &&
                                <CardsDisplay
                                    url={props?.url}
                                    datalist={item?.productServiceCatalogueResponseDto}
                                    header={item?.category}
                                    icon={item?.categoryImage}
                                    colorimage={generateRandomInteger(9)}
                                    loading={false}
                                    portfoliochanges={props?.portfoliochanges}
                                />
                            }
                        </Box>
                    })}
                </>
            )}

            {/** Generate the cards for the selected asset class */}
            {value === index && value >= 1 && children !== "BORDEAUX" && (
                <Box>
                    <CardsDisplayList
                        url={props?.url}

                        datalist={datalist || {}}
                        loading={datalist && !loadingInt ? false : true}
                        portfoliochanges={props?.portfoliochanges}
                    />
                </Box>
            )}

            {/** Generate the cards for Bordeaux Index */}
            {value === index && children === "BORDEAUX" && isbordeauxenabled && (
                <>
                    <BordeauxLiveData />
                </>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const getAssetClassEnabledList = (valueofProductID, userDataViewList) => {
    if (valueofProductID === CONSTANT_ID.FINANCIAL_PRODUCT) {
        return userDataViewList?.financialProducts?.assetClassEnabledList !== undefined
            ? Object.values(userDataViewList?.financialProducts?.assetClassEnabledList)
            : ["ALL"];
    } else if (valueofProductID === CONSTANT_ID.FINANCIAL_SERVICE) {
        return userDataViewList?.financialServices?.assetClassEnabledList !== undefined
            ? userDataViewList?.financialServices?.assetClassEnabledList
            : ["ALL"];
    } else if (valueofProductID === CONSTANT_ID.LIFESTYLE_PRODUCT) {
        return userDataViewList?.lifestyleProducts?.assetClassEnabledList !== undefined
            ? userDataViewList?.lifestyleProducts?.assetClassEnabledList
            : ["ALL"];
    } else if (valueofProductID === CONSTANT_ID.LIFESTYLE_SERVICE) {
        return userDataViewList?.lifestyleServices?.assetClassEnabledList !== undefined
            ? userDataViewList?.lifestyleServices?.assetClassEnabledList
            : ["ALL"];
    } else {
        return ["ALL"];
    }
};



export default function CatalogIndex(props) {
    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem("user"));
    const decodedToken = jwtDecode(user?.idToken);
    const isGridDisplay = useSelector(state => state.nav.isGridProductDisplay);
    const userDataViewList = useSelector(state => state.productService.users[btoa(decodedToken?.email)]);
    const [isLoadingInternal, setisLoadingInternal] = React.useState(userDataViewList?.financialProducts?.dataFull ? false : userDataViewList?.lifestyleProducts?.dataFull ? false : userDataViewList?.financialServices?.dataFull ? false : userDataViewList?.lifestyleServices?.dataFull ? false : true);
    const [valueofProductID, setValueProductID] = React.useState(props.url === "product" ? props?.passingValueForrender?.producttypes[0]?.id : props?.passingValueForrender?.servicetypes[0]?.id);
    const [alignment, setAlignment] = React.useState(props.url === "product" ? props?.passingValueForrender?.producttypes[0]?.description : props.passingValueForrender?.servicetypes[0]?.description);
    const [assetClassesList, setAssetClassesList] = React.useState(props.url === "product" ? [{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.financialproducts] : [{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.financialservices]);
    const [dataFull, setFullData] = React.useState(props.url === "product" && props?.passingValueForrender?.producttypes[0]?.id === CONSTANT_ID?.FINANCIAL_PRODUCT ? userDataViewList?.financialProducts?.dataFull : userDataViewList?.financialServices?.dataFull); // contains the full data of products/services
    const [assetClassEnabledList, setAssetClassEnabledList] = React.useState(getAssetClassEnabledList(valueofProductID, userDataViewList));
    const [q, setQ] = React.useState("");// eslint-disable-next-line  
    const [location, setLocations] = React.useState([]);// eslint-disable-next-line
    const [tagslist, settagslist] = React.useState([]);// eslint-disable-next-line
    let { formFieldProducts: { locationId, tags } } = props;
    const [windowSize, setWindowSize] = React.useState([window.innerWidth, window.innerHeight,]);
    const [value, setValue] = React.useState(0);
    const [isBordeauxEnabled, setIsBordeauxEnabled] = React.useState(false);
    const [condtion, setcondtion] = React.useState(false); // eslint-disable-next-line
    const [regionList, setregionList] = React.useState(null); // eslint-disable-next-line
    const [tagsLists, settagsLists] = React.useState(null);
    const rmUser = JSON.parse(localStorage.getItem('user'))
    const rmvalue = rmUser?.userTypeId?.description;
    // detect if we have a touch device
    const isTouchDevice = 'ontouchstart' in document.documentElement;

    // redux Update Depends on Product Type 
    const handleUpdateProducts = (listOfItems) => {
        switch (valueofProductID) {
            case CONSTANT_ID?.FINANCIAL_PRODUCT:
                dispatch(updateFinancialProducts({ user: btoa(decodedToken?.email), listOfItems }));
                break;
            case CONSTANT_ID?.LIFESTYLE_PRODUCT:
                dispatch(updateLifestyleProducts({ user: btoa(decodedToken?.email), listOfItems }));
                break;
            case CONSTANT_ID?.FINANCIAL_SERVICE:
                dispatch(updateFinancialServices({ user: btoa(decodedToken?.email), listOfItems }));
                break;
            case CONSTANT_ID?.LIFESTYLE_SERVICE:
                dispatch(updateLifestyleServices({ user: btoa(decodedToken?.email), listOfItems }));
                break;
            default:
                break;
        }
    };

    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([990, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => { window.removeEventListener('resize', handleWindowResize); };
    });

    React.useEffect(() => {
        if (props.url === "product") {
            setAssetClassesList([{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.financialproducts])
            settagsLists(props?.passingValueForrender?.tags)
            changeObjectPropertiesRegion(props?.passingValueForrender?.location)
        }
        else {
            settagsLists(props?.passingValueForrender?.tags)
            setAssetClassesList([{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.financialservices]);
            changeObjectPropertiesRegion(props?.passingValueForrender?.location)
        }
        setisLoadingInternal(userDataViewList?.financialProducts?.dataFull ? false : userDataViewList?.lifestyleProducts?.dataFull ? false : userDataViewList?.financialServices?.dataFull ? false : userDataViewList?.lifestyleServices?.dataFull ? false : true);
        setTimeout(() => {
            const passingObject = {
                rmId: user?.userId,
                productServiceTypeId: valueofProductID,
                locationId: [],
                tags: [],
                search: ''
            }
            getDataDetails(passingObject)
            setcondtion(true)
        }, 900)
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        if (condtion) {
            setTimeout(() => {
                const passingObject = {
                    rmId: user?.userId,
                    productServiceTypeId: valueofProductID,
                    locationId: location,
                    tags: tagslist,
                    search: q
                }
                getDataDetails(passingObject)
                setIsBordeauxEnabled(false)

            }, 900)
        }
        // eslint-disable-next-line
    }, [valueofProductID])

    React.useEffect(() => {
        const timeOut = condtion ? setTimeout(() => getDataDetails({
            rmId: user?.userId,
            productServiceTypeId: valueofProductID,
            locationId: location,
            tags: tagslist,
            search: q
        }), 900) : null;
        return () => clearTimeout(timeOut);
        // eslint-disable-next-line
    }, [q])

    const getDataDetails = async (values) => {
        if (userDataViewList?.financialProducts?.assetClassesList?.length === 0 && userDataViewList?.lifestyleProducts?.assetClassesList?.length === 0 && userDataViewList?.financialServices?.assetClassesList?.length === 0 && userDataViewList?.lifestyleServices?.assetClassesList?.length === 0) {
            setisLoadingInternal(true)
        }

        if (props?.recommentation === "recommentation") {
            if (rmvalue === "Entity RM") {
                values.promote = true
                values.hniId = props?.id
            }
            else {
                values.promote = true
                values.hniId = user?.userId
            }
        }
        if (props?.portfolio) {
            values.hniPortfolioAndSubscriptions = true;
            values.hniId = user?.userId
        }
        // set the state value to change depends on the Type   
        if (valueofProductID === CONSTANT_ID?.FINANCIAL_PRODUCT) {
            setFullData(userDataViewList?.financialProducts?.dataFull)
        }

        if (valueofProductID === CONSTANT_ID?.FINANCIAL_SERVICE) {
            setFullData(userDataViewList?.financialServices?.dataFull)
        }

        if (valueofProductID === CONSTANT_ID?.LIFESTYLE_PRODUCT) {
            setFullData(userDataViewList?.lifestyleProducts?.dataFull)
        }

        if (valueofProductID === CONSTANT_ID?.LIFESTYLE_SERVICE) {
            setFullData(userDataViewList?.lifestyleServices?.dataFull)
        }

        let resultOfData = await getAllDetailsListCatalog(values)
        if (resultOfData?.data?.status) {
            setFullData(resultOfData?.data?.body)

            const newCategories = resultOfData?.data?.body
                .filter(item => item?.productServiceCatalogueResponseDto?.length > 0)
                .map(item => item?.category);
            let tempAssetClassEnabledList = Object.values(assetClassEnabledList);
            const combinedList = [...tempAssetClassEnabledList, ...newCategories];
            const uniqueList = [...new Set(combinedList)];

            setAssetClassEnabledList(uniqueList);
            let listOfItems = {
                dataFull: resultOfData?.data?.body,
                assetClassEnabledList: uniqueList,
                assetClassesList: valueofProductID === CONSTANT_ID?.FINANCIAL_PRODUCT ? [{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.financialproducts] : valueofProductID === CONSTANT_ID?.FINANCIAL_SERVICE ? [{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.financialservices] : valueofProductID === CONSTANT_ID?.LIFESTYLE_PRODUCT ? [{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.lifestyleproducts] : valueofProductID === CONSTANT_ID?.LIFESTYLE_SERVICE ? [{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.lifestyleservices] : []
            }
            // Update the redux state by passing the value
            await handleUpdateProducts(listOfItems)
            setisLoadingInternal(false)
        }
    }

    const changeObjectPropertiesRegion = async (listOfData) => {
        const newArrayOfObj = await listOfData?.map(({
            countryName: description,
            ...rest
        }) => ({
            description,
            ...rest
        }));
        setregionList(newArrayOfObj)
    }

    // Handle change between Financial and Lifestyle tab selector
    const handleChangeButton = async (event) => {
        setAlignment(event.target.value);
        if (event.target.value !== alignment) {
            // Tab change and value updates
            if (props.url === "product") {
                if (userDataViewList?.financialProducts?.assetClassesList?.length === 0 || userDataViewList?.lifestyleProducts?.assetClassesList?.length === 0 || userDataViewList?.financialServices?.assetClassesList?.length === 0 || userDataViewList?.lifestyleServices?.assetClassesList?.length === 0) {
                    setisLoadingInternal(true)
                }
                setValue(0)
                if (event.target.value === "FINANCIAL PRODUCTS") {
                    let dataForAssestEnableList = await getAssetClassEnabledList(CONSTANT_ID?.FINANCIAL_PRODUCT, userDataViewList)
                    setFullData(userDataViewList?.financialProducts?.dataFull)
                    let listOfItems = {
                        dataFull: userDataViewList?.financialProducts?.dataFull,
                        assetClassEnabledList: dataForAssestEnableList,//userDataViewList?.financialProducts?.assetClassEnabledList,
                        assetClassesList: [{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.financialproducts]
                    }
                    dispatch(updateFinancialProducts({ user: btoa(decodedToken?.email), listOfItems }));
                    setAssetClassesList([{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.financialproducts])
                    setValueProductID(props?.passingValueForrender?.financialproducts[0].parentId)
                }
                //lifestyleProducts,financialServices,lifestyleServices
                else {
                    setFullData(userDataViewList?.lifestyleProducts?.dataFull)
                    let dataForAssestEnableListL = await getAssetClassEnabledList(CONSTANT_ID?.LIFESTYLE_PRODUCT, userDataViewList)
                    let listOfItems = {
                        dataFull: userDataViewList?.lifestyleProducts?.dataFull,
                        assetClassEnabledList: dataForAssestEnableListL,//userDataViewList?.lifestyleProducts?.assetClassEnabledList,
                        assetClassesList: [{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.lifestyleproducts]
                    }
                    dispatch(updateLifestyleProducts({ user: btoa(decodedToken?.email), listOfItems }));
                    setAssetClassesList([{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.lifestyleproducts])
                    setValueProductID(props?.passingValueForrender?.lifestyleproducts[0].parentId)
                }
            }
            else {
                if (userDataViewList?.financialProducts?.assetClassesList?.length === 0 || userDataViewList?.lifestyleProducts?.assetClassesList?.length === 0 || userDataViewList?.financialServices?.assetClassesList?.length === 0 || userDataViewList?.lifestyleServices?.assetClassesList?.length === 0) {
                    setisLoadingInternal(true)
                }
                setValue(0)
                if (event.target.value === "FINANCIAL SERVICES") {
                    let dataForAssestEnableListFS = await getAssetClassEnabledList(CONSTANT_ID?.FINANCIAL_SERVICE, userDataViewList)
                    let listOfItems = {
                        dataFull: userDataViewList?.financialServices?.dataFull,
                        assetClassEnabledList: dataForAssestEnableListFS,//userDataViewList?.financialServices?.assetClassEnabledList,
                        assetClassesList: [{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.financialservices]
                    }
                    setFullData(userDataViewList?.financialServices?.dataFull)
                    dispatch(updateFinancialServices({ user: btoa(decodedToken?.email), listOfItems }));

                    setAssetClassesList([{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.financialservices])
                    setValueProductID(props?.passingValueForrender?.financialservices[0].parentId)
                }
                else {
                    setFullData(userDataViewList?.lifestyleServices?.dataFull)
                    let dataForAssestEnableListLS = await getAssetClassEnabledList(CONSTANT_ID?.FINANCIAL_SERVICE, userDataViewList)
                    let listOfItems = {
                        dataFull: userDataViewList?.lifestyleServices?.dataFull,
                        assetClassEnabledList: dataForAssestEnableListLS,//userDataViewList?.lifestyleServices?.assetClassEnabledList,
                        assetClassesList: [{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.lifestyleservices]
                    }
                    dispatch(updateLifestyleServices({ user: btoa(decodedToken?.email), listOfItems }));
                    setAssetClassesList([{ id: 0, description: "ALL", progressBar: 100, imagePath: "https://di51go9vwu42.cloudfront.net/CategoryIcons/All.svg" }, ...props?.passingValueForrender?.lifestyleservices])
                    setValueProductID(props?.passingValueForrender?.lifestyleservices[0].parentId)
                }
            }
        }
    };

    const handleChangeT = async (description, index) => {
        setValue(index);
        if (description === "BORDEAUX") {
            setIsBordeauxEnabled(true)
        }
        else {
            setIsBordeauxEnabled(false)
        }
    };


    return (
        <>
            <Grid style={{ display: "flex", flexDirection: "column", backgroundColor: "white" }}>

                <Typography sx={{ fontSize: "28px", fontWeight: 500, paddingX: "10px" }}>{props?.url === "service" ? "Services" : "Products"}</Typography>

                {/** Financial / Lifestyle selector */}
                <ToggleButtonGroup
                    sx={{
                        marginX: "10px",
                        mb: 3,
                        fontFamily: "Mona Sans",
                        fontSize: "14px !important",
                        '& .MuiToggleButton-root.Mui-selected ': {
                            borderBottom: "2px solid #643dd6 !important",
                            fontWeight: "600",
                            color: "#643dd6",
                            backgroundColor: "white",
                        }, '& .MuiToggleButton-root ': {
                            fontSize: "14px !important",
                            borderRadius: "0px",
                            border: "none"
                        },
                    }}
                    value={alignment}
                    exclusive
                    onChange={handleChangeButton}  >
                    {props.url === "product" ? props.passingValueForrender.producttypes?.map(function (item, i) {
                        return <ToggleButton key={item.description} value={item.description} style={{
                            fontFamily: "Mona Sans",
                            minWidth: "auto",
                        }}>{item.description[0].toUpperCase() + item.description.slice(1).toLowerCase()}</ToggleButton>
                    })
                        : props.passingValueForrender.servicetypes?.map(function (item, i) {
                            return <ToggleButton key={item.description} value={item.description} style={{ fontFamily: "Mona Sans", minWidth: "auto", }}>{item.description[0].toUpperCase() + item.description.slice(1).toLowerCase()}</ToggleButton>
                        })}
                </ToggleButtonGroup>

                {/** Search block with view selector*/}
                <Grid display="flex" flexDirection="row" gap={2} alignContent="center" justifyContent="center" flexGrow={1} sx={{ mb: 3, marginX: "10px" }}>
                    <TextField
                        variant="outlined"
                        type="text"
                        value={q}
                        placeholder={"Search all assets"}
                        onChange={(e) => setQ(e.target.value)}
                        InputProps={{ startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>, }}
                        sx={{
                            flexGrow: 1,
                            '& input::placeholder': {
                                fontFamily: "Mona Sans",
                                textOverflow: 'ellipsis !important',
                                color: '#BBC5D5 !important'
                            },
                            '& .MuiInputBase-root': {
                                height: 'auto !important',
                                fontFamily: "Mona Sans",
                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                                borderRadius: '40px',
                                padding: "0 15px",
                            },
                        }}
                    />
                    {/** Grid display type selector */}
                    <Grid display="flex" flexDirection="row" alignItems="center" gap={1} sx={{ border: "1px solid #bcbcbc", borderRadius: "40px", padding: "0px 5px" }}>
                        <Grid
                            sx={{ padding: "10px 20px", borderRadius: "40px", backgroundColor: isGridDisplay ? "#ebebeb" : "white", display: "flex", flexDirection: "row", alignItems: "center", ":hover": !isGridDisplay ? { backgroundColor: "#f0f0f0" } : null }}
                            onClick={() => dispatch(setIsGridProductDisplay(true))}
                        >
                            <img src={CardsDisplayIcon} alt="" width="24px" height="24px" />
                        </Grid>
                        <Grid
                            sx={{ padding: "10px 20px", borderRadius: "40px", display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: !isGridDisplay ? "#ebebeb" : "white", ":hover": isGridDisplay ? { backgroundColor: "#f0f0f0" } : null }}
                            onClick={() => dispatch(setIsGridProductDisplay(false))}
                        >
                            <img src={ListDisplayIcon} alt="" width="24px" height="24px" />
                        </Grid>
                    </Grid>
                </Grid>

                {/** Asset classes selector */}
                <Grid display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" gap={1} sx={{ mb: 2, marginX: "10px" }}>

                    {assetClassesList?.map(function (item, i) {
                        let isEnabled = assetClassEnabledList && Object.values(assetClassEnabledList)?.includes(item.description);
                        if (item.description === "BORDEAUX") {
                            isEnabled = true;
                        }
                        return <Typography
                            key={i}
                            sx={{
                                border: "1px solid #ebebeb",
                                borderRadius: "40px",
                                wrap: "nowrap",
                                fontSize: "14px",
                                color: isEnabled ? (value === i ? "white" : "#1e1e1e") : "#a9a9a9",
                                padding: "10px 20px",
                                backgroundColor: isEnabled ? (value === i ? "#643dd6" : "white") : "white",
                                ":hover": isTouchDevice || value === i ? {} : { backgroundColor: "#ebebeb", color: "#1e1e1e" },
                                cursor: isEnabled ? "pointer" : "not-allowed"
                            }}
                            onClick={isEnabled ? (event, newValue) => handleChangeT(item.description, i) : null}
                        >
                            {item.description[0].toUpperCase() + item.description.slice(1).toLowerCase()}
                        </Typography>
                    }
                    )}
                </Grid>

                {/** Tabpanel that displays the products list */}
                <Grid sx={{ backgroundColor: "#FAFAFB", borderTop: "1px solid #ebebeb" }}>
                    <div className="divTabpanelcss">
                        {!isLoadingInternal && <>{assetClassesList?.map(function (item, i) {
                            return <TabPanel
                                key={"index" + i}
                                url={props?.url}
                                value={value}
                                index={i}
                                alignment={alignment}
                                description={item?.description}
                                listitems={assetClassesList}
                                valueofProductID={valueofProductID}
                                datafull={dataFull}
                                portfoliochanges={props.portfoliaChanges}
                                windowsize={windowSize}
                                isbordeauxenabled={isBordeauxEnabled}
                            >
                                {item.description}
                            </TabPanel>
                        })}</>
                        }
                        {isLoadingInternal && <Loader isLoading={isLoadingInternal} />}
                    </div>
                </Grid>
            </Grid>
        </>
    );
}
