import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
    name: "product",
    initialState: {
        productsData: {}
    },
    reducers: {
        setProductsData: (state, action) => {
            state.productsData = action.payload
        }
    },

});
export const { 
    setProductsData
} = productSlice.actions

export default productSlice.reducer