import React from 'react'
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Grid, TextField, Typography } from "@mui/material"
import { useSelector } from "react-redux"

const formTypes = {
    rating: "counter",
    radio: "choose",
    fill: "fill in the blank"
};
const ratings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const options = [{ value: 1, color: '#FE7576' },
{ value: 2, color: '#FE7576' },
{ value: 3, color: '#FE7576' },
{ value: 4, color: '#FE7576' },
{ value: 5, color: '#FE7576' },
{ value: 6, color: '#FE7576' },
{ value: 7, color: '#FABD03' },
{ value: 8, color: '#FABD03' },
{ value: 9, color: '#79CE20' },
{ value: 10, color: '#79CE20' }]




function Form({ type, question, radioButtons, surveyList, onRadioChange, selectedRadio, onValueChange, value, global, onCounterChange, counterValue, onNpsCounterChange, counterNpsValue, array }) {

    const surveyId = useSelector((state) => state.nav.surveyId)
    const warning = useSelector((state) => state.nav.warning)

    return (
        <Grid container sx={{ marginBottom: "20px" }}>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ fontSize: "14px" }}>{surveyList?.question}</Typography>
            </Grid>
            <Grid item sm={9} xs={11.8} sx={{ display: "flex", justifyContent: "space-between", marginTop: "15px", flexDirection: surveyList?.answerType === "choose" ? "column" : "" }}>
                {(surveyList?.answerType === formTypes.rating && surveyId !== 5) &&
                    ratings.map((rating) => (
                        <Button
                            key={rating}
                            variant={counterValue === rating ? 'contained' : 'outlined'}
                            elevation={4}
                            onClick={(e) => onCounterChange(e, surveyList?.surveyQuestionId, surveyList, rating)}
                            sx={{
                                padding: "2px 0px", minWidth: "24px",
                                background: counterValue === rating ? "#683AE0" : "white",
                                color: counterValue === rating ? "white" : "#707070",
                                border: counterValue !== rating ? "1px solid #707070" : "",
                                boxShadow: "0px 3px 3px #00000029",
                                '&:hover': {
                                    backgroundColor: counterValue === rating ? '#683AE0' : "",
                                },
                            }}
                        >
                            {rating}
                        </Button>
                    ))}
                {(surveyList?.answerType === formTypes.rating && surveyId === 5) && <FormControl><RadioGroup row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="controlled-radio-buttons-group"
                    onChange={(e) => onNpsCounterChange(e, surveyList?.surveyQuestionId, surveyList)}
                    value={counterNpsValue}>
                    {options.map((data, index) => {
                        return (<FormControlLabel
                            control={<Radio />}
                            key={data.value}
                            label={data.value}
                            value={data?.value}
                            labelPlacement="bottom"
                            sx={{
                                marginLeft: "0px",
                                paddingLeft: "0px",
                                "& .MuiFormControlLabel-label": {
                                    fontSize: '14px',
                                    color: 'white',
                                    padding: '3px 9px',
                                    borderRadius: "3.5px",
                                    background: data.color,
                                },
                                '& .Mui-checked': {
                                    color: '#683AE0', 
                                }
                            }} />)
                    })}</RadioGroup></FormControl>}

                {(surveyList?.answerType === formTypes.radio) &&
                    (<>
                        <FormControl>
                            <RadioGroup
                                key={surveyList?.surveyQuestionId}
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="controlled-radio-buttons-group"
                                onChange={(e) => onRadioChange(e, surveyList?.surveyQuestionId, surveyList)}
                                value={counterValue}
                            >
                                {surveyList?.answers?.map((radio) => <FormControlLabel key={radio?.answerId} value={radio?.answer} control={<Radio />} label={<span
                                    style={{
                                        fontFamily: "Mona Sans"
                                    }}
                                >
                                    {radio.answer}
                                </span>} />)}
                            </RadioGroup>
                        </FormControl>
                        {surveyId === 3 && warning && !array.some((a) => a?.questionId === surveyList?.surveyQuestionId) && (
                            <div ><p style={{ color: 'red', fontSize: "13px", fontWeight: 400, marginTop: "0px" }}>This field is required.</p></div>
                        )}
                    </>)}
                {(surveyList?.answerType === formTypes.fill) &&
                    (<>  <TextField
                        key={surveyList?.surveyQuestionId}
                        id="outlined-multiline-static"
                        onChange={(e) => onValueChange(e, surveyList?.surveyQuestionId, surveyList)}
                        defaultValue=""
                        value={value}
                        multiline
                        sx={{ width: { xs: "100%", sm: "100%" }, border: "1px solid #DDDDDD", borderRadius: "5px" }}
                        rows={2}
                    /></>)
                }
            </Grid>
        </Grid>
    )
}

export default Form