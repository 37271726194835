/* eslint-disable no-unused-vars */
import React from 'react'
import { Typography, Grid, Paper, useMediaQuery } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import './hniDashboard.css'
import { useNavigate } from 'react-router-dom';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { ReactComponent as ContactIcon } from '../../../../theme/images/contactIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setText, setButton, setchatBot, setChatWindowShow, setTargetValue } from "../../../../redux/slices/navSlice"
import EnquiryModel from './enquiryModel'
import ProductCard from '../../../common/ProductCard/ProductCard'
import Carousel from "react-elastic-carousel";
import { toast } from 'react-toastify';
import '../../../../theme/styles/carosuelstyles.css'
//import BannerCard from "./BannerRM"
import { hniAddEnquiry } from '../../../../services'
import { deepPurple } from '@mui/material/colors';
import { jwtDecode } from 'jwt-decode';

const userChecks = JSON.parse(window.localStorage.getItem("user"))
const userRights = userChecks?.userRights;
//Checkng Condtion For Chat wiith RM , from User rights
const chatWithRM = userRights?.find(item => item.module === "Chat with RM" && item.create === true);
const isRmChecks = userChecks?.userTypeId?.description === "Entity RM" ? true : false
const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 1 },
    { width: 850, itemsToShow: 3, itemsToScroll: 1 },
    { width: 1120, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1250, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1750, itemsToShow: 4, itemsToScroll: 1 },
];
const breakPointsBanner = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 }
];
const assistanceButtons = [
    {
        index: 26,
        text: "FAQs",
        path: "/enquiry"
    },
    {
        index: 27,
        text: isRmChecks ? "Chat" : "Chat with RM",
        path: "/chatqueries"
    },
    {
        index: 28,
        text: "Ask Gaia",
        path: "/chatgpt"
    },

]
const marks = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 40, label: '40' },
];

function HniDashboardSectionTwo() {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#5021C6',
        textAlign: 'center',
        height: '100%',
        color: '#FFF',
        padding: '27px 0',
        borderRadius: '8px'
    }));

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [enquiryModelOpen, setEnquiryModelOpen] = React.useState(false)
    const [faqModelData, setFaqModelData] = React.useState()
    const [windowSize, setWindowSize] = React.useState([window.innerWidth, window.innerHeight,]);
    const [description, setDescription] = React.useState()
    const targetValue = useSelector((state) => state.nav.targetValue)

    const user = JSON.parse(localStorage.getItem('user'))
    const decodedToken = jwtDecode(user?.idToken);
    // depending on user mail id -> We use base64 format to encrypt the user mail id., the data fetch from redux presist. 
    const dashboardContent = useSelector((state) => state.dashboard[btoa(decodedToken?.email)]);

    const isHni = (user?.userTypeId?.description === "Client Head" || user?.userTypeId?.description === "Client Family Member") ? true : false;
    const members = dashboardContent?.api4?.dashboardListsequencefour?.manageYourFamilyMember;

    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.between('md', 'lg'));
    //const isMorethanLG = useMediaQuery('(min-width:1201px) and (max-width:9600px)');
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

    let cols // Default number of columns

    const carouselRefL = React.useRef();
    const carouselRef = React.useRef();

    const handleEnquiryClose = () => setEnquiryModelOpen(false);
    //const handleEnquiryOpen = () => setEnquiryModelOpen(true);

    const slidePrev = () => {
        carouselRef.current.slidePrev();
    };

    const slideNext = () => {
        carouselRef.current.slideNext();
    };

    const slidePrevL = () => {
        carouselRefL.current.slidePrev();
    };

    const slideNextL = () => {
        carouselRefL.current.slideNext();
    };

    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        dispatch(setTargetValue(''))
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });// eslint-disable-next-line 

    const myArrow = () => {
        return <></>;
    };

    const handleClick = (e, memberId) => {
        if (e.detail === 1) {
            navigate("/hniprofile", { state: { memberid: memberId } })
        }
    }
    if (isLargeScreen) {
        cols = 4; // Number of columns for large screens
    } else if (isMediumScreen) {
        cols = 4; // Number of columns for medium screens
    } else if (isSmallScreen) {
        cols = 2; // Number of columns for small screens
    }

    const handleFaqs = (button) => {
        if (button.path === "/chatgpt") {
            dispatch(setchatBot(true))
            dispatch(setTargetValue('ChatGpt'))
            navigate(button.path)
        }
        else if (button.text === "Chat with RM" || button.text === "Chat") {
            dispatch(setButton(button))

            if (!isHni) {
                dispatch(setText(button.text))
            }
            if (isHni) {
                dispatch(setChatWindowShow(true))
            }
            else {
                navigate(button.path)
            }
        }
        else if (targetValue === 'ChatGpt') {
            if (button.path === "/chatgpt/history") {
                dispatch(setchatBot(true))
                dispatch(setTargetValue('ChatGpt'))
                navigate(button.path)
            }
        }
        else {
            dispatch(setButton(button))
            dispatch(setText(button.text))
            dispatch(setchatBot(false))
            navigate(button.path)
        }
    }

    const handleSubmit = async () => {
        const req = {
            loginUserId: user.userId,
            enquiry: description,
            subject: faqModelData?.id
        }
        const res = await hniAddEnquiry(req)
        if (res?.data?.status) {
            handleEnquiryClose()
            toast.success(res?.data?.statusMessage)
        }
    }

    const isXL = useMediaQuery("(min-width: 2000px) and (max-width: 4000px)");

    return (
        <div style={{ backgroundColor: "#FAFAFB" }}>

            {/** First recommendation section: Financial */}
            {dashboardContent?.api3?.dashboardListsequencethree?.recommendedProducts?.recommendedProducts?.length > 0 && <>
                <Grid container sx={{
                    display: { xs: 'flex', lg: 'flex' },
                    justifyContent: { xs: 'center', lg: 'start' },
                    paddingX: "10px",
                    marginBottom: 2
                }}>
                    {/** Title */}
                    <Grid item >
                        {dashboardContent?.api3?.dashboardListsequencethree?.recommendedProducts?.recommendedProducts?.length > 0 &&
                            <Typography sx={{ fontSize: "24px", fontWeight: 500 }}>Recommended financial products and services</Typography>
                        }
                    </Grid>
                </Grid>
                {/** Cards carousel */}
                <>
                    <Carousel //outerSpacing={windowSize[0] <= 900 ? 40 : 0}
                        sx={{ paddingX: "10px", marginTop: 2 }}
                        showEmptySlots
                        ref={carouselRef}
                        breakPoints={breakPoints}
                        renderArrow={myArrow}//windowSize[0] <= 900 ? myArrow : dashboardListsequencethree?.recommendedProducts?.recommendedProducts?.length > 0 ? null : myArrow}
                        renderPagination={({ pages, activePage, onClick }) => {
                            return <></>;
                        }}
                    >
                        {dashboardContent?.api3?.dashboardListsequencethree?.recommendedProducts?.recommendedProducts?.map((item) => (
                            <ProductCard key={item?.productServiceId} product={item} />
                        ))}
                    </Carousel>
                    {/** Pagination arrows */}
                    {dashboardContent?.api3?.dashboardListsequencethree?.recommendedProducts?.recommendedProducts && <Grid container justifyContent={"center"} flexDirection={"row"} spacing={0} mt={"10px"}>
                        <Grid item>
                            <ArrowCircleLeftOutlinedIcon onClick={slidePrev} sx={{ color: deepPurple[500], fontSize: 24, cursor: "pointer" }}></ArrowCircleLeftOutlinedIcon>
                        </Grid>
                        <Grid item>
                            <ArrowCircleRightOutlinedIcon onClick={slideNext} sx={{ color: deepPurple[500], fontSize: 24, cursor: "pointer" }}></ArrowCircleRightOutlinedIcon>
                        </Grid>
                    </Grid>}

                    {!dashboardContent?.api3?.dashboardListsequencethree?.recommendedProducts?.recommendedProducts && <>
                        <Grid item xs={12} sx={{ paddingX: "10px" }}>
                            <Typography>Nothing to display yet</Typography>
                        </Grid></>}
                </>
            </>
            }


            <br />

            {/** Second recommendation section: Lifestyle */}
            {dashboardContent?.api3?.dashboardListsequencethree?.recommendedServices?.recommendedServices?.length > 0 && <>
                <Grid container sx={{
                    display: { xs: 'flex', lg: 'flex' },
                    justifyContent: { xs: 'center', lg: 'start' },
                    paddingX: "10px",
                    marginBottom: 2
                }}>
                    {/** Title */}
                    <Grid item >
                        {dashboardContent?.api3?.dashboardListsequencethree?.recommendedServices?.recommendedServices?.length > 0 && <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>Recommended lifestyle products and services</Typography>}
                    </Grid>
                </Grid>
                {/** Cards carousel */}
                <>
                    <Carousel
                        sx={{ paddingX: "10px" }}
                        showEmptySlots
                        ref={carouselRefL}
                        breakPoints={breakPoints}
                        renderArrow={myArrow}
                        renderPagination={({ pages, activePage, onClick }) => {
                            return <></>;
                        }}
                    >
                        {dashboardContent?.api3?.dashboardListsequencethree?.recommendedServices?.recommendedServices?.map((item) => (
                            <ProductCard key={item?.productServiceId} product={item} />
                        ))}
                    </Carousel>
                    {/** Pagination arrows */}
                    {dashboardContent?.api3?.dashboardListsequencethree?.recommendedServices?.recommendedServices && <Grid container justifyContent={"center"} flexDirection={"row"} spacing={0} mt={"10px"}>
                        <Grid item>
                            <ArrowCircleLeftOutlinedIcon onClick={slidePrevL} sx={{ color: deepPurple[500], fontSize: 24, cursor: "pointer" }}></ArrowCircleLeftOutlinedIcon>
                        </Grid>
                        <Grid item>
                            <ArrowCircleRightOutlinedIcon onClick={slideNextL} sx={{ color: deepPurple[500], fontSize: 24, cursor: "pointer" }}></ArrowCircleRightOutlinedIcon>
                        </Grid>
                    </Grid>}
                    {!dashboardContent?.api3?.dashboardListsequencethree?.recommendedServices?.recommendedServices && <>
                        <Grid item xs={12} sx={{ paddingX: "10px" }}>
                            <Typography>Nothing to display yet</Typography>
                        </Grid></>}
                </>
            </>
            }


            {/** Manage family members block */}
            {user?.userTypeId?.description === "Client Head" && <Grid container sx={{ border: '1px solid #ebebeb', borderRadius: '12px', paddingBottom: '10px', boxShadow: "none", marginTop: 4 }}>
                <Grid item sx={{ display: 'flex', justifyContent: { xs: 'center', lg: 'start' } }}>
                    <Typography sx={{ padding: '10px' }}>Manage your family member</Typography>
                </Grid>
                <Grid container columnSpacing={2} rowSpacing={2} sx={{ padding: '0px 10px 10px 10px', marginLeft: isXL ? "4%" : 0 }}>
                    {members?.map((val, index) => {
                        return (
                            <Grid key={val?.memberId} sx={{ cursor: "pointer", boxShadow: "none", }} onClick={(e) => handleClick(e, val?.memberId)} item lg={3} xs={6} >
                                <Item sx={{ background: "linear-gradient(88deg, #E5AAF3 -5.59%, #AAB7F3 115.83%);", backgroundSize: "cover", height: "80px", maxWidth: "280px" }}>
                                    <Typography><ContactIcon /><span style={{ paddingRight: '10px', paddingLeft: '10px' }}>{val.memberName}</span></Typography>
                                </Item>
                            </Grid>
                        )
                    })}
                    <br />
                    {(members?.length === 0 || members === null) && (
                        <Grid item xs={12}>
                            <Typography>Nothing to display yet</Typography>
                        </Grid>)}
                </Grid>
            </Grid>}

            <br />

            {/** Need help section */}
            <Grid container sx={{ border: "1px solid #ebebeb", padding: "13px 15px", alignItems: "center", marginTop: "15px", marginBottom: "10px", borderRadius: "12px", display: "flex", flexDirection: "row", gap: "10px 0px" }}>
                <Grid sx={{ fontSize: "14px", mr: 2 }}>Need Help?</Grid>
                <Grid sx={{ display: "flex", gap: 2, justifyContent: "space-around", flexWrap: "wrap" }}>
                    {assistanceButtons?.map(button => {
                        if (button.text === "Chat with RM" && !chatWithRM) {
                            return null;
                        }
                        return (
                            <Grid key={assistanceButtons?.index} item sx={{ width: "200px", borderRadius: "50px", background: "#ebebeb", color: "#1e1e1e", padding: "5px", cursor: "pointer", boxShadow: "none", }}>
                                <Typography sx={{ textAlign: "center" }} item pt={0} style={{ fontSize: "12px" }} onClick={() => handleFaqs(button)}>{button.text}</Typography>
                            </Grid>
                        )
                    })
                    }
                </Grid>
            </Grid>

            <EnquiryModel setDescription={setDescription} handleSubmit={handleSubmit} addEnquiry={false} faqModelData={faqModelData} enquiryModelOpen={enquiryModelOpen} handleClose={handleEnquiryClose} />
        </div >

    )
}
export default HniDashboardSectionTwo;
