import React, { useEffect, useState, useRef } from 'react'
import { hniGetDashboardList } from '../../../../services';
import { updateAPI1, updateAPI2, updateAPI3, updateAPI4 } from '../../../../redux/slices/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import Loader from '../../../common/Loader/Loader';
import { Box, } from '@mui/material';
import HniDashboardSectionOne from "./HniDashboardSectiononetwo";
import HniDashboardSectionTwo from "./HniDashboardSectionThreeFour";
const LazyLoadingHNI = () => {
    const [sections, setSections] = useState([1]);
    const loaderRef = useRef(null);
    const dispatch = useDispatch();
    const User = JSON.parse(localStorage.getItem('user'))
    const decodedToken = jwtDecode(User?.idToken);
    // eslint-disable-next-line
    const [isLoading, setLoading] = useState(false);
    const dashboardContent = useSelector((state) => state.dashboard[btoa(decodedToken?.email)]);

    const listapi = async () => {
        if (!dashboardContent?.api1 && !dashboardContent?.api2 && !dashboardContent?.api3 && !dashboardContent?.api4) {
            setLoading(true)

        }
        const obj = {
            "loginUserId": User.userId,
            sequence: 1
        }
        for (let [index, promise] of [{ ...obj, sequence: 1 }, { ...obj, sequence: 2 }, { ...obj, sequence: 3 }, { ...obj, sequence: 4 }].entries()) {
            try {
                const res = await hniGetDashboardList(promise);
                if (index === 0) {

                    if (res?.data?.status) {
                        const cardsData = res?.data?.body?.cardData.filter(card => ["Investable Wealth", "Cash & Equivalent", "Current Value"].includes(card.header))
                        dispatch(updateAPI1({
                            user: btoa(decodedToken?.email), data: {
                                dashboardList: res?.data?.body,
                                cardList: cardsData
                            }
                        }));
                    }
                }
                if (index === 1) {
                    if (res?.data?.status)
                        dispatch(updateAPI2({
                            user: btoa(decodedToken?.email), data: {
                                dashboardListsequencetwo: res?.data?.body
                            }
                        }));
                }
                if (index === 3) {
                    if (res?.data?.status) {
                        dispatch(updateAPI4({
                            user: btoa(decodedToken?.email), data: {
                                dashboardListsequencefour: res?.data?.body
                            }
                        }));
                    }
                }
                if (index === 2) {
                    if (res?.data?.status) {
                        dispatch(updateAPI3({
                            user: btoa(decodedToken?.email), data: {
                                dashboardListsequencethree: res?.data?.body
                            }
                        }));
                    }
                }
            } catch (error) {
                console.log(error.message);
            }
        }
        setLoading(false)
    }

    useEffect(() => {

        listapi();
        // eslint-disable-next-line
    }, [])

    const loadNextSection = () => {
        if (sections.length < 2) {
            setSections((prevSections) => [...prevSections, prevSections.length + 1]);
        }
    };

    const handleObserver = (entries) => {
        const target = entries[0];
        if (target.isIntersecting && target.target === loaderRef.current) {
            // When the loader is in view, load the next section
            loadNextSection();
        }
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5,
        };

        const observer = new IntersectionObserver(handleObserver, options);
        // Start observing the loader element
        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            // Stop observing when the component unmounts
            if (loaderRef.current) { // eslint-disable-next-line
                observer.unobserve(loaderRef.current);
            }
        };// eslint-disable-next-line
    }, [sections]);

    return (
        <>  {isLoading && <Loader isLoading={isLoading} />}
            <Box minHeight="100vh" style={{ padding: '20px', marginBottom: "100px" }}>
                {sections.map((sectionNumber, index) => (
                    <Box key={index} marginBottom={2}>
                        {index === 0 ? (
                            <HniDashboardSectionOne
                                User={User}
                            />
                        ) : (
                            <HniDashboardSectionTwo />
                        )
                        }
                    </Box>
                ))}
                <div ref={loaderRef} id="lazyloadingVisible"></div>
            </Box></>
    );
};

export default LazyLoadingHNI;
