/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, AvatarGroup, Avatar, useMediaQuery } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';

import moment from "moment";

import { UseCurrencyHook } from '../../common/GlobalCurrency/useCurrencyHook';
import { TitleConverstion } from '../../../constant/DollorFormats';
import { useSelector } from 'react-redux';
// import all icons from theme/images/ProductCardsIcons
// Financial icons first
import EquityIcon from '../../../theme/images/ProductCardsIcons/icon_justice_scale_outline.svg'
import FixedIncomeIcon from '../../../theme/images/ProductCardsIcons/icon_coin_share_outline.svg'
import CashIcon from '../../../theme/images/ProductCardsIcons/icon_dollar_coin_outline.svg'
import StructuredProductsIcon from '../../../theme/images/ProductCardsIcons/icon_stacked_boxes_outline.svg'
import FundsAndETFsIcon from '../../../theme/images/ProductCardsIcons/icon_graph_bar_increase_outline.svg'
import CommoditiesIcon from '../../../theme/images/ProductCardsIcons/icon_stacked_gold_outline.svg'
import RealEstateIcon from '../../../theme/images/ProductCardsIcons/icon_building_outline.svg'
import ESGIcon from '../../../theme/images/ProductCardsIcons/icon_leaf_outline.svg'
import InsuranceIcon from '../../../theme/images/ProductCardsIcons/icon_shield_outline.svg'
import CryptoIcon from '../../../theme/images/ProductCardsIcons/icon_bitcoin_outline.svg'
import PrivateEquityIcon from '../../../theme/images/ProductCardsIcons/icon_diamond_outline.svg'
import GrowthEquityIcon from '../../../theme/images/ProductCardsIcons/icon_stock_outline.svg'
import VentureCapitalInvestmentIcon from '../../../theme/images/ProductCardsIcons/icon_money_lightbulb_outline.svg'
import PrivateCreditIcon from '../../../theme/images/ProductCardsIcons/icon_padlock_outline.svg'
import PreIpoProductsIcon from '../../../theme/images/ProductCardsIcons/icon_flag_progress_outline.svg'
import DirectDealProductsIcon from '../../../theme/images/ProductCardsIcons/icon_money select_outline.svg'
import OtherAlternativesIcon from '../../../theme/images/ProductCardsIcons/icon_four_circle_outline.svg'
// Then Services icons
import PortfolioManagementIcon from '../../../theme/images/ProductCardsIcons/icon_hierarchy_outline.svg'
import RiskManagementIcon from '../../../theme/images/ProductCardsIcons/icon_rock_slide_outline.svg'
import WealthPlanningIcon from '../../../theme/images/ProductCardsIcons/icon_desktop_dollar_outline.svg'
import TaxPlanning from '../../../theme/images/ProductCardsIcons/icon_receipt_outline.svg'
import LegalIcon from '../../../theme/images/ProductCardsIcons/icon_justice_hammer_outline.svg'
import SuccessionIcon from '../../../theme/images/ProductCardsIcons/icon_crown_outline.svg'
import OtherServicesIcon from '../../../theme/images/ProductCardsIcons/icon_four_circle_outline.svg'
// Then Lifestyle icons
import ArtIcon from '../../../theme/images/ProductCardsIcons/icon_paint_palette_outline.svg'
import WineIcon from '../../../theme/images/ProductCardsIcons/icon_wine_outline.svg'
import BordeauxIcon from '../../../theme/images/ProductCardsIcons/icon_wine_outline.svg'
import WellnessIcon from '../../../theme/images/ProductCardsIcons/icon_hot_spring_outline.svg'
import FoodIcon from '../../../theme/images/ProductCardsIcons/icon_serving_dome_outline.svg'
import HealthIcon from '../../../theme/images/ProductCardsIcons/icon_insurance_hand_outline.svg'
import SportIcon from '../../../theme/images/ProductCardsIcons/icon_ball_outline.svg'
import TravelIcon from '../../../theme/images/ProductCardsIcons/icon_airplane_outline.svg'
import OtherLifestyleIcon from '../../../theme/images/ProductCardsIcons/icon_four_circle_outline.svg'


const ProductCard = ({ product, data }) => {
    const productObject = product;
    const currency = useSelector(state => state.globalCurrency.currency);
    const currencyTitle = useSelector(state => state.globalCurrency.titleCurrency);
    const user = JSON.parse(localStorage.getItem('user'));
    const rmvalue = user?.userTypeId?.description
    const [productType, setIsProductOrService] = React.useState("product");
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const navigate = useNavigate();


    const handleClickViewProduct = (productid) => {
        navigate("/viewProductDetails/" + btoa(productid), { state: { recommentation: "dashboard" } })
    }
    const handleClickViewService = (serviceid) => {
        navigate("/viewServiceDetails/" + btoa(serviceid), { state: { recommentation: "dashboard" } })
    }

    const getIcon = (label) => {
        let productIcon = ""
        switch (label) {
            case "EQUITIES": productIcon = EquityIcon; break;
            case "FIXED INCOME": productIcon = FixedIncomeIcon; break;
            case "CASH OR EQUIVALENT": productIcon = CashIcon; break;
            case "STRUCTURED PRODUCTS": productIcon = StructuredProductsIcon; break;
            case "FUNDS AND ETFS": productIcon = FundsAndETFsIcon; break;
            case "COMMODITIES": productIcon = CommoditiesIcon; break;
            case "REAL ESTATE": productIcon = RealEstateIcon; break;
            case "ESG/IMPACT INVESTING": productIcon = ESGIcon; break;
            case "INSURANCE": productIcon = InsuranceIcon; break;
            case "CRYPTO": productIcon = CryptoIcon; break;
            case "PRIVATE EQUITY": productIcon = PrivateEquityIcon; break;
            case "GROWTH EQUITY": productIcon = GrowthEquityIcon; break;
            case "VENTURE CAPITAL INVESTMENT": productIcon = VentureCapitalInvestmentIcon; break;
            case "PRIVATE CREDIT": productIcon = PrivateCreditIcon; break;
            case "PRE-IPO PRODUCTS": productIcon = PreIpoProductsIcon; break;
            case "DIRECT DEAL PRODUCTS": productIcon = DirectDealProductsIcon; break;
            case "OTHER ALTERNATIVES": productIcon = OtherAlternativesIcon; break;

            case "PORTFOLIO MANAGEMENT": productIcon = PortfolioManagementIcon; break;
            case "RISK MANAGEMENT": productIcon = RiskManagementIcon; break;
            case "WEALTH PLANNING": productIcon = WealthPlanningIcon; break;
            case "TAX PLANNING": productIcon = TaxPlanning; break;
            case "LEGAL AND COMPLIANCE": productIcon = LegalIcon; break;
            case "SUCCESSION PLANNING": productIcon = SuccessionIcon; break;
            case "OTHER SERVICES": productIcon = OtherServicesIcon; break;

            case "TRAVEL": productIcon = TravelIcon; break;
            case "ART": productIcon = ArtIcon; break;
            case "WINE": productIcon = WineIcon; break;
            case "BORDEAUX": productIcon = BordeauxIcon; break;
            case "WELLNESS": productIcon = WellnessIcon; break;
            case "FOOD AND DRINK": productIcon = FoodIcon; break;
            case "FOOD": productIcon = FoodIcon; break;
            case "HEALTH": productIcon = HealthIcon; break;
            case "SPORTS": productIcon = SportIcon; break;
            case "OTHERS": productIcon = OtherLifestyleIcon; break;
            default: break;
        }
        return productIcon;
    }
    let productIcon = getIcon(productObject?.primaryCategoryId?.description);

    useEffect(() => {
        if (productObject.productServiceTypeId?.description === "LIFESTYLE SERVICES" || productObject.productServiceTypeId?.description === "FINANCIAL SERVICES") {
            setIsProductOrService("service")
        } else {
            setIsProductOrService("product")
        }
    }, [productObject])

    return (
        <Grid
            id="linearProductCard-container"
            onClick={() => productType === 'product' ? handleClickViewProduct(productObject?.productServiceId) : handleClickViewService(productObject?.productServiceId)}
            sx={{
                width: "100%",
                fontFamily: "Mona Sans",
                boxShadow: "none",
                cursor: "pointer",
                backgroundSize: "cover",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px 10px",
                gap: 2,
                flexWrap: isSmallScreen ? "wrap" : "no-wrap",
                overflow: "auto",
                '&::-webkit-scrollbar': {
                    height: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.08)',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#ebebeb',
                }
            }}>

            <Grid display="flex" flexDirection="row" gap={2} flexWrap="no-wrap">
                {/** Icon with Gradient */}
                <Grid
                    sx={{
                        width: "56px", height: "56px", display: "flex", gap: 1, flexDirection: "row", alignItems: "center",
                        justifyContent: "center", padding: "20px 20px", borderRadius: "8px",
                        background: productType === "service" ? "linear-gradient(61deg, #D2C6FC -0.84%, #9FB3F0 83.4%)" : "linear-gradient(16deg, #DBEBF9 -12.44%, #77B3E8 106.29%)"
                    }}>
                    {/** Asset class icon */}
                    <img src={productIcon} width="24px" sx={{ marginX: "auto" }} alt="" />
                </Grid>
                {/** Product name and asset class */}
                <Grid sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    {/** Product title */}
                    <Typography title={productObject.productServiceName} sx={{ fontSize: "14px", color: "#1e1e1e" }}>
                        {productObject?.productServiceName}
                    </Typography>
                    {/** Asset Class or Product Category*/}
                    <Typography title={productObject?.primaryCategoryId?.description} sx={{ width: "auto", textAlign: "left", fontSize: "12px", color: "#777777" }}>
                        {productObject?.primaryCategoryId?.description}
                    </Typography>
                </Grid>
            </Grid>

            <Grid display="flex" flexDirection="row" gap={2} flexWrap="wrap">
                <Grid sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    {/** Minimum Value */}
                    <Grid display="flex" flexDirection={"column"} sx={{ marginTop: "8px", width: "100px" }}>
                        <Typography title={"Minimum Value"} sx={{ fontSize: "12px", color: "#777777" }}>
                            MIN. VALUE
                        </Typography>
                        <Typography title={TitleConverstion(currencyTitle?.currencyCode, productObject?.sellingPrice, user, currency)} sx={{ fontSize: "12px" }}>
                            <UseCurrencyHook val={productObject?.sellingPrice} />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    {/** Minimum Value */}
                    <Grid display="flex" flexDirection={"column"} sx={{ marginTop: "8px", width: "80px" }}>
                        <Typography title={"Minimum Value"} sx={{ fontSize: "12px", color: "#777777" }}>
                            RETURNS
                        </Typography>
                        <Typography title={productObject?.returnsLevel?.description} sx={{ fontSize: "12px" }}>
                            {productObject?.returnsLevel?.description}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    {/** Minimum Value */}
                    <Grid display="flex" flexDirection={"column"} sx={{ marginTop: "8px", width: "90px" }}>
                        <Typography title={"Minimum Value"} sx={{ fontSize: "12px", color: "#777777" }}>
                            RISK
                        </Typography>
                        <Typography title={productObject?.riskLevel?.description} sx={{ fontSize: "12px" }}>
                            {productObject?.riskLevel?.description}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    {/** Expiry */}
                    <Grid display="flex" flexDirection={"column"} sx={{ marginTop: "8px", width: "100px" }}>
                        <Typography title="Expiry Date" sx={{ fontSize: "12px", color: "#777777" }}>
                            EXPIRES IN
                        </Typography>
                        <Typography title={moment(productObject.endDate).format("D MMMM  YYYY")} sx={{ fontSize: "12px" }}>
                            {moment(productObject.endDate).format("D MMMM  YYYY")}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default ProductCard
