/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Grid, Button, Typography, Box, Card, CardContent, Modal, ListItem, List, ListItemAvatar, Avatar, useMediaQuery, ListItemText, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Contacts from '../DashboardList/Contacts';
import Pagination from "@mui/material/Pagination";
import MarketNews from "../../../common/MarketNews/MarketNews"
import ProductCard from '../../../common/ProductCard/ProductCard';
import { useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
// const columnsHniLeaderBoard = [
//     { field: 'customerName', headerName: 'Customer Name', width: 100, sortable: false, flex: 1 },
//     { field: 'asset', headerName: 'Asset', width: 67, sortable: false, },
// ];
// const columnsProductLeaderBoard = [
//     { field: 'productName', headerName: 'Product Name', width: 100, sortable: false, flex: 1 },
//     { field: 'revenue', headerName: 'Revenue', width: 67, sortable: false },
// ]
// const columnsServiceLeaderBoard = [
//     { field: 'serviceName', headerName: 'Service Name', width: 100, sortable: false, flex: 1 },
//     { field: 'revenue', headerName: 'Revenue', width: 67, sortable: false, }
// ]


const RMDashboardSectionThree = (props) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [modal, setModal] = useState(false);
    const [portfolioIndex, setPortfolioIndex] = useState(0);
    const [portfolioType, setPortfolioType] = useState("");
    const decodedToken = jwtDecode(user?.idToken);
    // depending on user mail id -> We use base64 format to encrypt the user mail id., the data fetch from redux presist. 
    const dashboardContent = useSelector((state) => state.dashboard[btoa(decodedToken?.email)]);
    const navigate = useNavigate();



    const handleClickAddProspects = () => {
        navigate("/dashboard/AddProspects")
    }

    const handleCloseLifStylePortfolio = () => {
        setModal(false);
    }

    const handleClickViewPortfolio = (index, clientId) => {
        if (portfolioType === "financial") {
            navigate("/myPortfolio", { state: { setTab: index, financialPortfolio: "financialPortfolio", hniId: clientId } })
        } else {
            navigate("/myPortfolio", { state: { setTab: index, lifestylePortfolio: "lifestylePortfolio", hniId: clientId } })
        }
    }
    const isXL = useMediaQuery("(min-width: 2000px) and (max-width: 4000px)");

    return (
        <>
            <Modal open={modal} onClose={handleCloseLifStylePortfolio}>
                <Box
                    sx={{
                        width: 350,
                        boxShadow: 24,
                        borderRadius: "5px",
                        position: "absolute",
                        padding: 2,
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "white",
                        border: "1px solid #ffffff",
                    }}
                >
                    <List
                        sx={{
                            maxHeight: 300,
                            width: "100%",
                            overflowY: "auto", // Make the list scrollable
                        }}
                    >
                        <CloseIcon
                            sx={{ float: "right", fontSize: "20px", cursor: "pointer" }}
                            onClick={handleCloseLifStylePortfolio}
                        ></CloseIcon>
                        <Typography sx={{ fontSize: "14px", marginLeft: "20px" }}>Client's List</Typography>
                        {dashboardContent?.api3?.hniList?.map((user, index) => {
                            return (
                                <ListItem key={index} alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt="Client" src={user?.profilePhotoUrl}></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={user?.firstName} sx={{ marginTop: "15px", fontSize: "10px" }}></ListItemText>
                                    <Button
                                        sx={{
                                            color: "white",
                                            float: "right",
                                            background: "#6442C4",
                                            border: "8px",
                                            marginTop: "10px",
                                            "&:hover": {
                                                color: "white",
                                                background: "#6442C4",
                                            },
                                        }}
                                        onClick={() => handleClickViewPortfolio(portfolioIndex, user?.clientId)}
                                    >
                                        View Portfolio
                                    </Button>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </Modal>
            {props?.loadingSectionThree && <><p>Loading...</p></>}
            {!props?.loadingSectionThree && <div >
                <Grid container spacing={2} sx={{ marginTop: "5px", marginBottom: '30px', paddingX: "10px" }}>
                    {/** Contacts section */}
                    <Grid item xs={12} lg={6}>
                        <div style={{ height: "100%", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "none", border: "1px solid #DFD2D280", borderRadius: "12px" }}>
                            <Contacts handleClickAddProspects={handleClickAddProspects} />
                        </div>
                        {/** Leaderboard from old design */}
                        {/* <Grid item xs={12} lg={12} sx={{ marginTop: "10px", }}>
                                <Box
                                    sx={{
                                        height: "auto",
                                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                                        // boxShadow: "0.47px 3px 10px #77777733",
                                        boxShadow: "none",
                                        // border: "1px solid #EAEAEA",
                                        borderRadius: "8px", border: "1px solid #EBEBEB",
                                        opacity: 1,
                                    }}
                                >
                                    <Typography variant='h6' style={{ padding: "15px 10px 5px 10px", color: "#000000", fontSize: "14px", fontWeight: 600 }}
                                    >Leader board
                                    </Typography>

                                    <Grid container sx={{ flexGrow: 1 }} >
                                        <Grid item xs={12} lg={4} md={12} >
                                            <Grid container sx={{ p: 0.5 }}>
                                                <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Typography variant="body1" sx={{ p: "6px", fontWeight: 600, fontSize: "10px", height: "25px", whiteSpace: 'pre' }}>Top client's with respect to assets</Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                </Grid>
                                            </Grid>
                                            <GridTable rows={dashboardContent?.api3?.hnileaderboard} getRowId={(row) => row.hniId} columns={columnsHniLeaderBoard} height={"180px"} ></GridTable>
                                        </Grid>
                                        <Grid item xs={12} lg={4} md={12}>
                                            <Grid container sx={{ p: 0.5 }}>
                                                <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Typography variant="body1" sx={{ p: "6px", fontWeight: 600, fontSize: "10px", height: "24px", whiteSpace: 'pre' }}>Top products in terms of revenue</Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                </Grid>
                                            </Grid>
                                            <GridTable rows={dashboardContent?.api3?.productleaderboard} getRowId={(row) => row.productId} columns={columnsProductLeaderBoard} height={"180px"}></GridTable>
                                        </Grid>
                                        <Grid item xs={12} lg={4} md={12}>
                                            <Grid container sx={{ p: 0.5 }}>
                                                <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Typography variant="body1" sx={{ p: "6px", fontWeight: 600, fontSize: "10px", height: "24px", whiteSpace: 'pre' }}>Top services in terms of revenue</Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                </Grid>
                                            </Grid>
                                            <GridTable rows={dashboardContent?.api3?.serviceleaderboard} getRowId={(row) => row.serviceId} columns={columnsServiceLeaderBoard} height={"180px"}></GridTable>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid> */}
                    </Grid>

                    {/** Market News section */}
                    <Grid item xs={12} lg={6}>
                        <div style={{ height: "100%", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "none", border: "1px solid #DFD2D280", borderRadius: "12px" }}>
                            <MarketNews marketDetails={dashboardContent?.api3?.marketNews} />
                        </div>
                    </Grid>

                </Grid>


                {/** Recommendations section */}
                {dashboardContent?.api4?.recProducts?.length > 0 && <>
                    <Grid container sx={{
                        display: { xs: 'flex', lg: 'flex', xl: 'flex' },
                        justifyContent: { xs: 'center', lg: 'start', xl: "start" },
                        paddingX: "10px",
                        marginBottom: 2
                    }}>
                        <Grid item >
                            <Typography sx={{ fontSize: "24px", fontWeight: 500 }}>Recommended financial products and services</Typography>
                        </Grid>
                    </Grid>

                    <div style={{ display: 'flex', justifyContent: 'end', padding: '5px' }}>

                    </div>
                    <Grid container spacing={2} sx={{ width: "100%", marginBottom: '10px', paddingTop: '10px', justifyContent: "start", alignItems: "flex-start", paddingX: "10px" }} >
                        {/** Cards display */}
                        {dashboardContent?.api4?.recProducts?.map((product, index) => {
                            if (props?.finProdServLoader) {
                                return (
                                    <Grid item lg={4} md={6} sm={12} spacing={1}>
                                        <Card variant="outlined" sx={{ width: "95%", borderRadius: "15px", backgroundSize: "cover", height: "95%", backgroundColor: `#000000` }}>
                                            <CardContent sx={{ color: "white", "& .MuiCardContent-root": { padding: "8px" } }}>
                                                <CircularProgress color="inherit" sx={{ marginLeft: "100px" }} />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            }
                            return (
                                <Grid item key={"recProducts" + index} lg={4} md={6} sm={12} >
                                    <ProductCard product={product} />
                                </Grid>
                            )
                        })}
                    </Grid>
                    <div style={{ float: "right" }}>
                        {dashboardContent?.api4?.productTotalPages > 1 && <Pagination sx={{
                            "& .MuiPaginationItem-root.Mui-selected ": {
                                backgroundColor: "black !important",
                                color: "white !important"
                            },
                        }} count={dashboardContent?.api4?.productTotalPages} page={dashboardContent?.api4?.prodpage + 1} onChange={props?.handlePageProdChange} />}
                    </div>
                </>
                }
                {dashboardContent?.api4?.recServices?.length > 0 && <>
                    <Grid container sx={{
                        display: { xs: 'flex', lg: 'flex' },
                        justifyContent: { xs: 'center', lg: 'start' },
                        paddingX: "10px",
                        marginTop: "20px"
                    }}>
                        <Grid item>
                            <Typography sx={{ fontSize: "24px", fontWeight: 500 }}>Recommended lifestyle products and services</Typography>
                        </Grid>
                    </Grid>
                    <div style={{ display: 'flex', justifyContent: 'end', padding: '5px' }}>

                    </div>
                    <Grid container spacing={2} sx={{ marginBottom: '10px', paddingTop: '10px', paddingX: "10px", justifyContent: "start", alignItems: "flex-start" }}>
                        {dashboardContent?.api4?.recServices?.map((product, index) => {
                            if (props?.lifProdServLoader) {
                                return (
                                    <Grid item lg={4} md={6} xs={12} spacing={1}>
                                        <Card variant="outlined" sx={{
                                            width: "95%",
                                            borderRadius: "15px",
                                            backgroundSize: "cover", height: "95%", backgroundColor: `#000000`,
                                        }}>
                                            <CardContent sx={{ color: "white", "& .MuiCardContent-root": { padding: "8px" } }}>
                                                <CircularProgress color="inherit" sx={{ marginLeft: "100px" }} />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            }
                            return (
                                <Grid item key={"recServices" + index} lg={4} md={6} sm={12} >
                                    <ProductCard product={product} />
                                </Grid>
                            )
                        })}
                    </Grid>
                    <div style={{ float: "right" }}>
                        {dashboardContent?.api4?.serviceTotalPages > 1 && <Pagination sx={{
                            "& .MuiPaginationItem-root.Mui-selected ": {
                                backgroundColor: "black !important",
                                color: "white !important"
                            },
                        }} count={dashboardContent?.api4?.serviceTotalPages} page={dashboardContent?.api4?.servpage + 1} onChange={props?.handlePageServChange} />}
                    </div>
                </>
                }
            </div>}
        </>
    )
}
export default RMDashboardSectionThree;