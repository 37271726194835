//Catalog API's
import { Axios1 } from "./config";// eslint-disable-next-line

export const getAlldropDownForCatalog = async (passingObject) => {
    var resultService = await Axios1
        .post(`/api/v1/productservice/getall-dropdown-productservice-catalogue`, passingObject)
    return resultService;
}
export const getAllBordeauxLiveData = async () => {
    var resultService = await Axios1
        .get(`/api/v1/biservice/get-bordeaux-live-trade`)
    return resultService;
}


export const getAllDetailsListCatalog = async (passingObject) => {
    var resultService = await Axios1
        .post(`/api/v1/productservice/getall-products-services-catalogue`, passingObject)
    return resultService;
}

export const getAllRecommentationList = async (passingObject) => {
    var resultService = await Axios1
        .post(`/api/v1/productservice/getall-portfolio-subscriptions`, passingObject)
    return resultService;
}

export const getIndividualData = async (passingObjests) => {
    var resultService = await Axios1
        .post(`/api/v1/productservice/get-products-services-catalogue-view`, passingObjests)
    return resultService;
}


export const confirmPaymentAPI = async (objectPassing) => {
    var resultService = await Axios1
        .post(`/api/v1/order-management/confirm-transaction-details`, objectPassing)
    return resultService;
}

export const getByBannerIDData = async (value) => {
    try {
        const resultofBanner = await Axios1.post(`/api/v1/productservice/get-product-service-cms-config`, value);
        if (resultofBanner) return resultofBanner;
    }
    catch (error) {
        console.log("error", error)
        return error;
    }
}


export const statusChangeAPI = async (valuePassed) => {
    var resultsofApi = await Axios1.post('/api/v1/order-management/update-child-status', valuePassed)
    return resultsofApi
}