import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear';
import ModalTableGridTable from "../../modules/Dashboard/DashboardModalController/DashboardModalWithGrid"
import { closeModal } from "../../../redux/slices/dashboardModalSlice"
import { useDispatch, useSelector } from "react-redux"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xl: "70%", sm: "80%", xs: "90%" },
    bgcolor: 'white',
    boxShadow: 8,
    borderRadius: "12px",
    p: 2,
    overflow: "auto"
};

function ModalWithTable(props) {
    const checkStatus = useSelector((state) => state.modal)
    const dispatch = useDispatch()
    return (
        <div>
            <Modal
                open={checkStatus?.modalShow}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    maxHeight: { xs: "95vh", sm: "95vh" },
                    '& .MuiBackdrop-root': {
                        background: "none"
                    }
                }}
            >
                <Box sx={style}>
                    <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ marginBottom: "15px", fontSize: "16px" }}>
                            {checkStatus?.titleofModal}
                        </Typography>
                        <ClearIcon onClick={() => { dispatch(closeModal()) }} />
                    </Grid>
                    <Grid container >
                        <ModalTableGridTable />
                    </Grid>
                </Box>
            </Modal>
        </div>
    )
}

export default ModalWithTable