import { createSlice } from "@reduxjs/toolkit";

const generalSlice = createSlice({
    name: "general",
    initialState: {
        clients: {},
        prospects: {},
        marketNews: {},
        isContactsLoading: false,
        isMarketNewsLoading: false,
        rmRecommendedProducts: [],
        rmRecommendedServices: [],
    },
    reducers: {
        setClientsData: (state, action) => {
            state.clients = action.payload
        },
        setProspectsData: (state, action) => {
            state.prospects = action.payload
        },
        setMarketNewsData: (state, action) => {
            state.marketNews = action.payload
        },
        setIsContactsLoading: (state, action) => {
            state.isContactsLoading = action.payload
        },
        setIsMarketNewsLoading: (state, action) => {
            state.isMarketNewsLoading = action.payload
        },
        setRmRecommendedProducts: (state, action) => {
            state.rmRecommendedProducts = action.payload
        },
        setRmRecommendedServices: (state, action) => {
            state.rmRecommendedServices = action.payload
        },
    },

});
export const {
    setClientsData,
    setProspectsData,
    setMarketNewsData,
    setIsContactsLoading,
    setIsMarketNewsLoading,
    setRmRecommendedProducts,
    setRmRecommendedServices,
} = generalSlice.actions

export default generalSlice.reducer