import React, { useEffect, useState, useRef } from 'react'
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import List from "@mui/material/List";
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { getAllNotification, updateNotification } from "../../../services/index"
import { navButtons } from "../../../components/Layout/SideNavBar"
import { useDispatch } from "react-redux"
import { setButton, setSurveyId, setText } from "../../../redux/slices/navSlice"
import { formatRelativeTime } from "../../common/Utils/DateFormat"
import throttle from 'lodash/throttle';
import Loader from "../../common/Loader/Loader";



function Notification(props) {

    const dispatch = useDispatch()

    const user = JSON.parse(window.localStorage.getItem('user'));
    const userId = user.userId
    const [notifications, SetNotifications] = useState([])
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const notificationListRef = useRef(null);

    useEffect(() => {
        getAllNotification({
            "userId": userId,
            "pageNo": 0,
            "pageSize": 8
        }).then((res) => {
            console.log(notifications, "notUseE")
            SetNotifications(res?.data?.body)
            console.log(notifications, "notUseN")
            setCount(res?.data?.totalUnreadCount)
            props?.setpushNotificationCount(res?.data?.totalUnreadCount)
            // props.onNotificationChange(res?.data?.totalUnreadCount)
            setTotalPages(res?.data?.totalPages)
        })// eslint-disable-next-line 
    }, [])



    // useEffect(() => {
    //     console.log(count, "useCounttttttttt")
    //     // props.onNotificationChange(count)
    //     // eslint-disable-next-line 
    // }, [count])
    const handleNotificationClick = (notification) => {
        console.log("clickedNotification", notification)
        if (notification.isRead === false) {
            updateNotification({
                "id": notification.id,
                "isRead": true,
                "userId": userId
            }).then(res => console.log(res, "update res"))
            // props.onNotificationChange(count)
            SetNotifications((prev) => prev.map((note) => note.id === notification.id ? { ...note, isRead: true } : { ...note })
            )
            setCount((ps) => (ps - 1))
            props?.setpushNotificationCount((ps) => (ps - 1))

        }

        // props.onClose()
        if (!notification?.path.includes("/clientprofile")) {
            const button = navButtons.filter(val => val.path === notification.path)[0]
            dispatch(setButton(button))
            console.log(button, "buttonnnnnn")
        }
        if (notification?.path.includes("/prospectprofile")) {
            // const button = navButtons.filter(val => val.path === notification.path)[0]
            dispatch(setButton({}))
            dispatch(setText("Prospect Profile"))

            // console.log(button, "buttonnnnnn")
        }

        if (notification?.requestId && notification?.path === "/survey") {
            dispatch(setSurveyId({ surveyId: notification?.requestId, refreshAPI: false }))
        }
        const notificationPathSplit = notification.path.split("/")[1]
        const selectedPathObj = navButtons.find(val => val.path === `/${notificationPathSplit}`)
        dispatch(setButton(selectedPathObj))
        dispatch(setText(selectedPathObj?.text))
        navigate(notification?.path)

    }

    console.log(count, "counttttttttttttttttttttttt")



    const handleShowMore = async () => {
        await setPage(ps => ps + 1)
        console.log(page, "page")
        console.log(totalPages, "total")

    }
    const handleScroll = throttle(() => {
        console.log("scroll")
        const notificationList = notificationListRef.current;
        console.log(notificationList, "check")
        console.log(notificationList.scrollTop + notificationList.clientHeight, notificationList.scrollHeight, "height")

        if (
            notificationList.scrollTop + notificationList.clientHeight >= notificationList.scrollHeight - 30
        ) {
            setLoader(true)
            // User has scrolled to the bottom
            handleShowMore();
        }
    }, 300);

    useEffect(() => {
        if (page < totalPages) {

            getAllNotification({
                "userId": userId,
                "pageNo": page,
                "pageSize": 3
            }).then((res) => {
                console.log(res.data, "notificationRes")
                let hell = res?.data?.body !== null && res?.data?.body?.length > 0
                console.log(hell, "hell")
                console.log(notifications, "secondNot")
                // res.data.body !== null && res.data.body.length > 0 && SetNotifications(ps => [...ps, ...res.data.body])
                res?.data?.body?.length > 0 && SetNotifications(ps => ([...ps, ...res.data.body]))
                setLoader(false)

            })
        }// eslint-disable-next-line 
    }, [page])

    return (
        <React.Fragment>

            <Menu
                anchorEl={props.anchorEl}
                id="account-menu"
                open={props.open}
                onClick={props.onClick}
                onClose={props.onClose}
                sx={{ position: "absolute", zIndex: 0 }}

                // disableHoverListener
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        top: "15vh",
                        width: "356px",
                        filter: 'drop-shadow(0px 1px 2px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiList-root': {
                            width: "350px",
                            fontSize: "12px",
                            padding: "5px",
                            background: "white"


                        },


                        '& .MuiAvatar-root': {
                            width: 28,
                            height: 28,
                            ml: -0.5,
                            mr: 1,
                            mt: -3.2,
                            fontSize: "15px",
                            background: "#693BE1",
                            color: "white"
                        },
                        // '& .MuiButtonBase-root': {
                        //     marginBottom: "5px",
                        //     borderRadius: "8px",
                        //     backgroundColor: "white"
                        // },
                        // '& .MuiButtonBase-root:hover': {
                        //     marginBottom: "5px",
                        //     borderRadius: "8px",
                        //     // backgroundColor: "#1159D529"
                        // },

                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 100,
                            right: 14,
                            width: 10,
                            height: 10,
                            // bgcolor: 'background.paper',
                            // transform: 'translateY(-50%) rotate(45deg)',
                            // zIndex: 0,
                        },
                    },
                }}

                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'bottom', vertical: 'bottom' }}
            >
                <MenuItem sx={{
                    cursor: 'default !important',
                    backgroundColor: 'white !important',
                    '&:hover': {
                        backgroundColor: 'white', // Add your desired background color on hover
                    },
                }} >
                    <ListItemText sx={{
                        flexDirection: "row", '&:hover': {
                            backgroundColor: 'white',
                        }, backgroundColor: 'white !important',
                    }}>
                        <Typography><span style={{ marginRight: "30px", fontSize: "17px", fontWeight: "600" }}>Notifications</span><span style={{ border: "1px solid #1159D5", background: "#1159D5", borderRadius: "6px", padding: "4px", color: "white", fontWeight: 900, display: count === 0 ? "none" : "" }}>{count}</span></Typography>


                    </ListItemText>
                    {/* <ListItemText><Typography>02</Typography>
                    </ListItemText> */}
                    <ListItemSecondaryAction>
                        <Typography sx={{ alignItems: "flex-end", color: "#E02020", cursor: 'pointer' }} mr={4} onClick={props.onClose}>Close</Typography>
                    </ListItemSecondaryAction>
                </MenuItem>
                <hr style={{ width: "90%", marginLeft: "5%" }} />
                <List ref={notificationListRef} onScroll={handleScroll} sx={{ maxHeight: "42vh", overflowY: "scroll" }}>
                    {console.log(notifications, "notifications")}
                    {notifications?.map((notification) => (<MenuItem onClick={() => handleNotificationClick(notification)} sx={{ background: notification.isRead ? "white" : "#1159D529", '&:hover': { background: notification.isRead ? "white" : "#1159D529" }, overflow: "hidden", textOverflow: "ellipsis", marginBottom: "3px", borderRadius: "8px" }}>
                        <Avatar>{notification?.senderName?.charAt(0)}</Avatar> <div style={{ marginTop: "8px" }}><Typography noWrap style={{ whiteSpace: 'pre-line', overflowWrap: 'break-word', color: "#495463", textTransform: "none" }}><span style={{ color: "black", fontWeight: "bold" }}>{notification.senderName}  </span>{notification.message}<span style={{ color: "#693BE1" }}>  Review</span></Typography>
                            <Typography sx={{ color: "#A7A7A7" }}>{formatRelativeTime(notification?.createdAt)}</Typography>
                        </div>


                    </MenuItem>)

                    )}
                    {(page >= totalPages) &&
                        (<MenuItem sx={{ display: "flex", justifyContent: "center", color: "#693BE1", height: "22px" }}>

                            <p style={{ cursor: "default" }}>No More Notifications </p>
                        </MenuItem>)}


                </List>
                <MenuItem sx={{ display: "flex", justifyContent: "center", color: "#693BE1" }}>
                    {(page < totalPages) && <Loader isLoading={loader} />}

                    {/* {(page < totalPages) && (<Typography onClick={handleShowMore}> Show More</Typography>)} */}
                </MenuItem>
            </Menu>
        </React.Fragment >
    )
}

export default Notification