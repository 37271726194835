/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useState } from "react";

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    setSelectedFolder,
    setFolders,
    setIsCreateFolderModalOpen,
    setIsUploadDocumentModalOpen,
    setIsDeleteFolderModalOpen,
    setIsDeleteDocumentModalOpen,
    setUploadedFiles,
    setIsDeleteFolderDisabled,
    setFiles
} from '../../../../redux/slices/ChatGptSlice'
import { toast } from 'react-toastify';



import {
    Box,
    Typography,
    Grid,
    Button,
    Modal,
    TextField,
    CircularProgress
}
    from '@mui/material'

import { styled } from '@mui/system';
import FileUpload from 'react-material-file-upload';

const StyledTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
})((p) => ({

    // input label when focused
    "& label.Mui-focused": {
        color: "#fff"
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
        borderBottomColor: "#fff"
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#fff"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        borderColor: "#fff",
        "&.Mui-focused fieldset": {
            borderColor: "#fff"
        }
    }
}));

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#6C6C6C',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    color: 'white',
    borderRadius: 4,
};


const accept = [
    'image/jpeg',
    'image/png',
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/html",
    "application/rtf",
    "text/plain",
    "application/pdf"
]

export default function Modals({ queryAPI }) {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isCreateFolderModalOpen = useSelector((state) => state.chatGpt.isCreateFolderModalOpen)
    const isUploadDocumentModalOpen = useSelector((state) => state.chatGpt.isUploadDocumentModalOpen)
    const isDeleteFolderModalOpen = useSelector((state) => state.chatGpt.isDeleteFolderModalOpen)
    const isDeleteDocumentModalOpen = useSelector((state) => state.chatGpt.isDeleteDocumentModalOpen)
    const uploadedFiles = useSelector((state) => state.chatGpt.uploadedFiles)
    const selectedFolder = useSelector((state) => state.chatGpt.selectedFolder)
    const folders = useSelector((state) => state.chatGpt.folders)
    const selectedFile = useSelector((state) => state.chatGpt.selectedFile)
    const files = useSelector((state) => state.chatGpt.files)

    const [createFolderText, setCreateFolderText] = useState("")
    const [deletedFolder, setDeletedFolder] = useState("")
    const [isUploading, setIsUploading] = useState(false)

    const handleUploadChange = (file) => {
        dispatch(setUploadedFiles(file))
    }

    const handleCreateFolderInput = (event, newValue) => {
        setCreateFolderText(event.target.value)
    }

    const handleSaveNewFolder = (event) => {
        const payload = { "index_name": createFolderText }
        queryAPI("index", "POST", "", JSON.stringify(payload))
            .then(response => {
                toast.success(t("Folder created successfully"))
                dispatch(setIsCreateFolderModalOpen(false))
                queryAPI("indexes", "GET", "", "")
                    .then(res_indexes => {
                        console.log("resindex2", res_indexes)
                        if (res_indexes[0].length > 0) {
                            let options = [""]
                            res_indexes[0].forEach(index => {
                                options.push(index.payload["index_id"])
                            })
                            dispatch(setFolders(options))
                            dispatch(setSelectedFolder(createFolderText))
                            dispatch(setIsDeleteFolderDisabled(false))
                            dispatch(setFiles([]))
                        }
                    })
            })
            .catch(error => {
                toast.error("There has been an error processing your request. Please contact your administrator. Error code 212")
            });
    };

    const handleDeleteFolder = (event) => {
        queryAPI("index", "DELETE", selectedFolder, "")
            .then(response => {
                toast.warning(t("Folder ") + selectedFolder + t(" successfully deleted"))
                setDeletedFolder(selectedFolder)
            }).then(() => {
                dispatch(setSelectedFolder(""))
            }).then(() => {
                if (deletedFolder !== '') {
                    const updatedFolders = folders.filter(obj => obj !== deletedFolder)
                    dispatch(setFolders(updatedFolders))
                }
            }).then(() => {
                dispatch(setIsDeleteFolderModalOpen(false))
            })
            .catch(error => {
                console.error("Error code 212B during API request:", error);
            });
    };

    const handleUploadFile = (event) => {
        setIsUploading(true)
        let formData = new FormData();
        console.log(typeof (uploadedFiles))
        console.log(uploadedFiles)
        uploadedFiles.forEach(file => {
            formData.append('index_name', selectedFolder);
            formData.append('file', file);
            queryAPI("document", "POST", "", formData)
                .then(response => {
                    toast.success(t("File uploaded successfully"))
                    dispatch(setUploadedFiles([]))
                })
                .then(() => {
                    let updatedFiles = [...files]
                    updatedFiles.push(file["name"])
                    dispatch(setFiles(updatedFiles))
                    dispatch(setIsUploadDocumentModalOpen(false))
                    setIsUploading(false)
                })
                .catch((e) => {
                    toast.error("There has been an error processing your request. Please contact your administrator. Error code 213")
                    console.log({ e })
                });
        })
    };

    const handleDeleteDocument = (event) => {
        console.log(selectedFile)
        queryAPI("document", "DELETE", "?filename=" + encodeURI(selectedFile) + "&index_name=" + encodeURI(selectedFolder), "")
            .then(response => {
                // add toast
                toast.success(t("Document deleted successfully"))
                dispatch(setIsDeleteDocumentModalOpen(false))
                const updatedFiles = files.filter(obj => obj !== selectedFile)
                dispatch(setFiles(updatedFiles))
            });
    };

    return (
        <>
            {/** Modal to create a internal data folder */}
            <Modal
                open={isCreateFolderModalOpen}
                onClose={() => { dispatch(setIsCreateFolderModalOpen(false)) }}
                aria-labelledby="modal-create-folder"
                aria-describedby="modal-create-folder"
            >
                <Box sx={modalStyle} >
                    <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                        {t("Create Internal Data Folder")}
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { width: '100%' },
                        }}
                        noValidate
                        width="100%"
                        autoComplete="off"
                    >
                        <StyledTextField
                            id="newFolderName"
                            label={t("Folder Name")}
                            variant="outlined"
                            width="100%"
                            onChange={handleCreateFolderInput}
                            sx={{ input: { color: '#fff', borderColor: "#fff" } }}
                            InputLabelProps={{
                                style: { color: '#fff', borderColor: "#fff" },
                                classes: {
                                    notchedOutline: { color: "#fff" }
                                }
                            }}
                        />
                    </Box>
                    <Grid display="flex" flexDirection="row" width="100%" justifyContent="center" columnGap={2} mt={2}>
                        <Button variant='contained' sx={{
                            width: '180px',
                            background: '#6442C4',
                            textTransform: 'none'
                        }} onClick={handleSaveNewFolder}>{t("Save")}</Button>
                        <Button variant='contained' sx={{
                            width: '180px',
                            color: "white",
                            border: "2px solid #6442C4",
                            background: '#6C6C6C',
                            textTransform: 'none'
                        }} onClick={() => { dispatch(setIsCreateFolderModalOpen(false)) }}>{t("Cancel")}</Button>
                    </Grid>
                </Box>
            </Modal>

            {/** Modal to delete a internal data folder */}
            <Modal
                open={isDeleteFolderModalOpen}
                onClose={() => { dispatch(setIsDeleteFolderModalOpen(false)) }}
                aria-labelledby="modal-delete-folder"
                aria-describedby="modal-delete-folder"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-delete-title" variant="h6" component="h2">
                        {t("Are you sure you want to delete the ")}{selectedFolder}{t(" folder?")}
                    </Typography>
                    <Grid display="flex" flexDirection="row" width="100%" justifyContent="center" columnGap={2} mt={2}>
                        <Button variant='contained' sx={{
                            width: '180px',
                            background: '#C44242'
                        }} onClick={handleDeleteFolder}>{t("Delete")}</Button>
                        <Button variant='contained' sx={{
                            width: '180px',
                            color: "white",
                            border: "2px solid #6442C4",
                            background: 'transparent',
                            textTransform: 'none'
                        }} onClick={() => { dispatch(setIsDeleteFolderModalOpen(false)) }}>{t("Cancel")}</Button>
                    </Grid>
                </Box>
            </Modal>

            {/** Modal to Upload a internal data document in dropzone */}
            <Modal
                open={isUploadDocumentModalOpen}
                onClose={() => { dispatch(setIsUploadDocumentModalOpen(false)) }}
                aria-labelledby="modal-upload-document"
                aria-describedby="modal-upload-document"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-upload-title" variant="h5" component="h2" mb={2}>
                        <strong>{t("Upload New Document in Folder")}<br /></strong>
                    </Typography>
                    {!isUploading ? <>
                        <Typography id="modal-upload-title" variant="h6" component="h2" mb={2}>
                            {t("Supported files: .doc, .docx, .txt, .rtf, .htm, .html, .pdf")}
                        </Typography>
                        <Typography id="modal-upload-title" sx={{ fontSize: "0.6rem" }} component="h2" mb={2}>
                            {t("Notes regarding pdfs:")}
                            {t("PDF that are image pdfs or using custom fonts will be loaded using character recognition techniques. This limits our ability to parse tables in such documents,")}
                            {t("therefore we encourage you to submit such documents in alternative formats for better accuracy.")}
                        </Typography>

                        <FileUpload
                            accept={accept}
                            value={uploadedFiles}
                            onChange={handleUploadChange}
                            sx={{
                                color: "#fff",
                                borderColor: "#fff",
                                '& .MuiSvgIcon-root': {
                                    color: "white !important"
                                }
                            }}
                            buttonProps={{ style: { color: "#000", backgroundColor: "#fff" } }}
                        />
                    </>
                        :
                        <>
                            <Grid sx={{ width: '100%', height: '175px' }} display="flex" alignItems="center" justifyContent="center">
                                <CircularProgress />
                            </Grid>
                            {t("Large pdf can take up to 5 mins to process, it is advised not to close this window.")}
                        </>
                    }
                    <Grid display="flex" flexDirection="row" width="100%" justifyContent="center" columnGap={2} mt={2}>
                        <Button variant='contained' sx={{
                            width: '180px',
                            background: '#6442C4'
                        }} onClick={handleUploadFile}>{t("Save")}</Button>
                        <Button variant='contained' sx={{
                            width: '180px',
                            color: "white",
                            border: "2px solid #6442C4",
                            background: 'transparent',
                            textTransform: 'none'
                        }} onClick={() => { dispatch(setIsUploadDocumentModalOpen(false)) }}>{t("Cancel")}</Button>
                    </Grid>
                </Box>
            </Modal>

            {/** Modal to delete a internal data document */}
            <Modal
                open={isDeleteDocumentModalOpen}
                onClose={() => { dispatch(setIsDeleteDocumentModalOpen(false)) }}
                aria-labelledby="modal-delete-document"
                aria-describedby="modal-delete-document"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-deletedoc-title" variant="h6" component="h2">
                        {t("Are you sure you want to delete this document?")}
                    </Typography>
                    <Grid display="flex" flexDirection="row" width="100%" justifyContent="center" columnGap={2} mt={2}>
                        <Button variant='contained' sx={{
                            width: '180px',
                            background: '#C44242'
                        }} onClick={handleDeleteDocument}>{t("Delete")}</Button>
                        <Button variant='contained' sx={{
                            width: '180px',
                            color: "white",
                            border: "2px solid #6442C4",
                            background: 'transparent',
                            textTransform: 'none'
                        }} onClick={() => { dispatch(setIsDeleteDocumentModalOpen(false)) }}>{t("Cancel")}</Button>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}
