/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { useState } from "react";
import {
    Autocomplete,
    Typography,
    FormControl,
    InputLabel,
    Select,
    Grid,
    IconButton,
    TextField,
    Button
}
    from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import {
    outlinedInputClasses,
    selectClasses,
    inputLabelClasses,
} from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    setSelectedFolder,
    setIsCreateFolderModalOpen,
    setIsUploadDocumentModalOpen,
    setIsDeleteFolderModalOpen,
    setIsDeleteDocumentModalOpen,
    setIsDeleteFolderDisabled,
    setSelectedFile,
    setFiles,
    setResponseList,
    setIsLoading
} from '../../../../redux/slices/ChatGptSlice'

import { styled } from '@mui/system';
import styles from '../sidebar.module.css'
import { formatDate } from "../../../common/Utils/DateFormat"

const StyledSelect = styled(Select)`
  color: #fff;
  border-color: #fff;

  & .${selectClasses.icon} {
    color: #fff;
  }

  & .${outlinedInputClasses.notchedOutline} {
    border-color: #fff;
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: #fff;
  }

  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} 
  {
    border-color: #fff !important;
  }
`;

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        color: "white",
    },
    "& .MuiInputLabel-shrink": {
        color: "white"
    },
    "& .MuiSvgIcon-root": {
        color: "white"
    },
    "&.Mui-focused .MuiInputLabel-outlined": {
        color: "white"
    },
    "& .MuiAutocomplete-inputRoot": {
        color: "white",

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        }
    }
});

const StyledInputLabel = styled(InputLabel)`
color: #fff;
&.${inputLabelClasses.shrink} {
    color: #fff;
}
`;

const StyledTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
})((p) => ({

    // input label when focused
    "& label.Mui-focused": {
        color: "#fff"
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
        borderBottomColor: "#fff"
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#fff"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        borderColor: "#fff",
        "&.Mui-focused fieldset": {
            borderColor: "#fff"
        }
    }
}));


export default function SidebarInternalData({ queryAPI }) {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const folders = useSelector((state) => state.chatGpt.folders)
    const selectedFolder = useSelector((state) => state.chatGpt.selectedFolder)
    const selectedFile = useSelector((state) => state.chatGpt.selectedFile)
    const isDeleteFolderDisabled = useSelector((state) => state.chatGpt.isDeleteFolderDisabled)
    const files = useSelector((state) => state.chatGpt.files)
    const [isDeleteFileDisabled, setIsDeleteFileDisabled] = useState(true)

    const handleFolderChange = (event, value) => {
        if (value === null) {
            value = ""
        }
        dispatch(setSelectedFolder(value))
        // eslint-disable-next-line
        if (value != "") {
            queryAPI("index", "GET", value, "").then(response => {
                dispatch(setFiles(response.documents.map(input_string => { return input_string })))
                dispatch(setIsDeleteFolderDisabled(false))
            })
        } else {
            dispatch(setIsDeleteFolderDisabled(true))
        }
    };

    const handleChangeFileSelector = (event) => {
        dispatch(setSelectedFile(event.target.value))
    };

    useEffect(() => {
        if (selectedFile !== "") { setIsDeleteFileDisabled(false) } else { setIsDeleteFileDisabled(true) }
    }, [selectedFile])

    const handleSummarization = () => {
        console.log("Summarizing...")
        console.log({selectedFile})
        const currentDate = new Date(); 
        dispatch(setIsLoading(true))
        queryAPI("ask_summarize", "GET", "?filename="+encodeURIComponent(selectedFile), "").then(response => {
            console.log(response)
            let summaryAnswer=[{
                'date': formatDate(currentDate, 'DD-MMM-YYYY'),
                'question': "Summary of " + selectedFile,
                'bookmark': "No",
                'answer': response,
                'chatGptId': 1,  //res?.data?.body[0]?.chatGptId
                'ticker': ""
            }]
            dispatch(setResponseList(summaryAnswer))
            dispatch(setIsLoading(false))
        })
    }

    return (<>
        <Grid display="flex" flexDirection="row" justifyContent="space-between" sx={{ marginX: 2 }}>
            <Typography color='white' mb={0}>{t("Pick Internal Data folder")}</Typography>
            <Grid display="flex" flexDirection="row" justifyContent="flex-end">
                <IconButton
                    aria-label="Delete Folder"
                    disabled={isDeleteFolderDisabled}
                    sx={{ cursor: 'pointer', padding: '0' }}
                    onClick={() => { dispatch(setIsDeleteFolderModalOpen(true)) }}
                >
                    <DeleteOutlineIcon
                        p={0}
                        sx={{ color: isDeleteFolderDisabled ? "gray" : "white" }} />
                </IconButton>
                <AddIcon p={0} sx={{ cursor: "pointer", color: "white" }} onClick={() => dispatch(setIsCreateFolderModalOpen(true))} />
            </Grid>
        </Grid>
        <StyledAutocomplete
            id="folder-selector"
            width="100%"
            size="small"
            sx={{ marginX: 2 }}
            options={folders}
            value={selectedFolder}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => <StyledTextField {...params} label={t("Folder")} />}
            onChange={handleFolderChange}
        />
        <Grid display="flex" flexDirection="row" justifyContent="space-between" sx={{ marginX: 2 }}>
            <Typography color='white' mb={0}>{t("Documents in folder")}</Typography>
            <Grid display="flex" flexDirection="row" justifyContent="flex-end">
                <IconButton
                    aria-label="Delete File"
                    disabled={isDeleteFileDisabled}
                    sx={{ cursor: 'pointer', padding: '0' }}
                    onClick={() => dispatch(setIsDeleteDocumentModalOpen(true))}
                >
                    <DeleteOutlineIcon
                        p={0}
                        sx={{ color: isDeleteFileDisabled ? "gray" : "white" }} />
                </IconButton>
                <IconButton
                    aria-label="Upload File"
                    disabled={selectedFolder === ""}
                    sx={{ cursor: 'pointer', padding: '0' }}
                    onClick={() => dispatch(setIsUploadDocumentModalOpen(true))}
                >
                    <AddIcon p={0} sx={{ color: selectedFolder === "" ? "gray" : "white" }} />
                </IconButton>
            </Grid>
        </Grid>
        <FormControl sx={{ marginX: 2 }}>
            <StyledInputLabel shrink htmlFor="select-file">
                {t("File")}
            </StyledInputLabel>
            <StyledSelect
                className={styles}
                multiple
                native
                value={[selectedFile]}
                onChange={handleChangeFileSelector}
                label="Native"
                inputProps={{
                    id: 'select-file',
                }}
            >
                {files?.map((file) => (
                    <option key={file} value={file}>
                        {file}
                    </option>
                ))}
            </StyledSelect>
        </FormControl>
        {/** Summarization button that becomes active when a file is selected */}
        <Button
            disabled={selectedFile === ""}
            sx={{
                textTransform: 'none',
                width: '90%',
                marginX: 2,
                marginY: '1px',
                color: 'white',
                borderColor: '#707070',
                ':hover': {
                    borderColor: '#6442c4',
                    fontWeight: 'bold',
                    borderWidth: '2px',
                    backgroundColor: '#6442c4'
                },
                backgroundColor: '#6442c4'
            }}
            onClick={handleSummarization}
            variant='contained'
        >
            {t("Summarize document")}
        </Button>
    </>
    )
}
