/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
    setSelectedHni,
    setIsLoading,
    setResponseList,
    setNotificationClick,
    setTableData,
    setGraphData,
    setStockData,
    setChartData,
    setDisplayType
} from '../../../../redux/slices/ChatGptSlice'

import {
    Autocomplete,
    Typography,
    Grid,
    TextField,
    Button
}
    from '@mui/material'

import { styled } from '@mui/system';
import { formatDate } from "../../../common/Utils/DateFormat"

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        color: "white",
    },
    "& .MuiInputLabel-shrink": {
        color: "white"
    },
    "& .MuiSvgIcon-root": {
        color: "white"
    },
    "&.Mui-focused .MuiInputLabel-outlined": {
        color: "white"
    },
    "& .MuiAutocomplete-inputRoot": {
        color: "white",

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        }
    }
});

const StyledTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
})((p) => ({

    // input label when focused
    "& label.Mui-focused": {
        color: "#fff"
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
        borderBottomColor: "#fff"
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#fff"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        borderColor: "#fff",
        "&.Mui-focused fieldset": {
            borderColor: "#fff"
        }
    }
}));


export default function Portfolio() {

    const { t } = useTranslation();

    let auth_header = ""
    if (JSON.parse(window.localStorage.getItem("user"))?.idToken) {
        const user = window.localStorage.getItem("user")
        if (user) {
            auth_header = `Bearer ${JSON.parse(user).idToken}`;
        }
    }
    let headers = {
        'authorization': auth_header,
        "env": process.env.REACT_APP_DOMAIN_NAME,
        "content-type": "application/json",
    }
    const base_url = "https://gaia.kairoswealth.com/"

    const dispatch = useDispatch();

    const hniList = useSelector((state) => state.chatGpt.hniList)
    const selectedHni = useSelector((state) => state.chatGpt.selectedHni)
    const isLoading = useSelector((state) => state.chatGpt.isLoading)
    const [buttonsDisabled, setButtonsDisabled] = useState(true)

    const handleHniChange = (clientId) => {
        dispatch(setSelectedHni(clientId))
        setButtonsDisabled(false)
    };

    const handleGetRecommendationsClick = async (event) => {
        console.log("GET recommendations")
        dispatch(setNotificationClick(false))
        dispatch(setTableData(""))
        dispatch(setGraphData({}))
        dispatch(setStockData({}))
        dispatch(setChartData({}))
        dispatch(setIsLoading(true))
        dispatch(setDisplayType('MessagesList'))

        let url = base_url + "ask_recommendations/?clientId=" + selectedHni
        
        dispatch(setIsLoading(true))
        const response = await fetch(url, {
            method: "GET", 
            headers: headers, 
            credentials: 'include'
        })
        
        const currentDate = new Date();

        let initialResponse = [{
            'date': formatDate(currentDate, 'DD-MMM-YYYY'),
            'question': "",
            'bookmark': "No",
            'answer': "",
            'chatGptId': "", 
            'ticker': ""
        }]
        dispatch(setResponseList(initialResponse))
        let fullText = ""
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        // process streaming fragments until done
        dispatch(setIsLoading(false))
        while (true) {
            const { done, value } = await reader.read();
            if (done) {
                break;
            }
            // Convert the Uint8Array to a string and update the state
            const text = decoder.decode(value, { stream: true });
            let intermediateAnswer = [{
                'date': formatDate(currentDate, 'DD-MMM-YYYY'),
                'question': "",
                'bookmark': "No",
                'answer': fullText + text,
                'chatGptId': "",  
                'ticker': ""
            }]
            dispatch(setResponseList(intermediateAnswer))
            fullText = fullText + text
        }
        let finalAnswer = [{
            'date': formatDate(currentDate, 'DD-MMM-YYYY'),
            'question': "",
            'bookmark': "No",
            'answer': fullText,
            'chatGptId': "999999",  
            'ticker': ""
        }]
        dispatch(setResponseList(finalAnswer))
    }

    const handlePortfolioRebalancingAssetClassesClick = (event) => {
        console.log("GET Portfolio Rebalancing Asset Classes")
        console.log({selectedHni})
        let url = base_url + "ask_rebalancing/?clientId=" + selectedHni + "&type=assets"
        fetch(url, {
            method: "GET", 
            headers: headers, 
        }).then(response => {
            return response.json()
        }).then((res) => {
            const currentDate = new Date();
            let updatedResponseList = [{
                'date': formatDate(currentDate, 'DD-MMM-YYYY'),
                'question': "Robo-advisor (Asset Classes)",
                'bookmark': "No",
                'answer': res,
                'ticker': ""
            }]
            dispatch(setResponseList(updatedResponseList))
        })
        .catch((e) => {
            toast.error("There has been an error processing your request. Please contact your administrator. Error code 710")
            console.log({ e })
        });
    }

    return (
        <>
            <Grid display="flex" flexDirection="row" justifyContent="space-between" sx={{ marginX: 2 }}>
                <Typography color='white' mb={0}>{t("Select a Client's portfolio")}</Typography>
            </Grid>
            <StyledAutocomplete
                id="hni-selector"
                width="100%"
                size="small"
                sx={{ marginX: 2 }}
                options={hniList.map((hni) => hni.firstName + hni.lastName)}
                renderInput={(params) => <StyledTextField {...params} label={t("Client")} />}
                onChange={(event, value) => {
                    const selectedClientId = hniList.find((hni) => hni.firstName + hni.lastName === value)?.userId;
                    handleHniChange(selectedClientId);
                }}
            />

            {buttonsDisabled ? 
                <Typography variant="caption" pl={2} sx={{color: 'red'}}>{t("Please select a client")}</Typography>
            : null}
            {/** Action buttons */}
            <Button
                disabled={buttonsDisabled}
                sx={{
                    textTransform: 'none',
                    width: '90%',
                    marginX: 2,
                    marginY: '1px',
                    color: 'white',
                    borderColor: '#707070',
                    ':hover': {
                        borderColor: '#6442c4',
                        fontWeight: 'bold',
                        borderWidth: '2px',
                        backgroundColor: '#6442c4'
                    },
                    backgroundColor: '#6442c4'
                }}
                onClick={handleGetRecommendationsClick}
                variant='contained'
            >
                {t("Get Portfolio Recommendations")}
            </Button>
            <Button
                disabled={buttonsDisabled}
                sx={{
                    textTransform: 'none',
                    width: '90%',
                    marginX: 2,
                    marginY: '1px',
                    color: 'white',
                    borderColor: '#707070',
                    ':hover': {
                        borderColor: '#6442c4',
                        fontWeight: 'bold',
                        borderWidth: '2px',
                        backgroundColor: '#6442c4'
                    },
                    backgroundColor: '#6442c4'
                }}
                onClick={handlePortfolioRebalancingAssetClassesClick}
                variant='contained'
            >
                {t("Robo-Advisor (Asset Classes)")}
            </Button>
        </>
    )
}
