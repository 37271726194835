/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from "react-router-dom"
import TimeOutWarning from './TimeOutWarning'
import { logOut } from '../../services/index';
import {clearLocalStorageExceptPersistRoot} from "../../constant/ClearLocalStorage"
import { getRefreshToken } from "../../services/config";
import { jwtDecode } from 'jwt-decode';
const timeout = 1000 * 60 * 14.3;
const additionalSec = 1000 * 60 * .5
export default function TimeOut() {
    // const [remaining, setRemaining] = useState(timeout)
    const [open, setOpen] = useState(false)

    const user = JSON.parse(window.localStorage.getItem('user'))
    const accessToken = user?.accessToken
    const navigate = useNavigate()
    const handlelogOut = () => {
        logOut({ "accessToken": accessToken, "userId": user?.userId }).then(res => {
            console.log(res.data.status, "status res")
        })
       // localStorage.clear(); clear local storage except presist
       clearLocalStorageExceptPersistRoot()
        navigate("/login")
        setOpen(false)
    }
    React.useEffect(() => {
        const modalsValue = localStorage.getItem("modals");
        if (modalsValue === undefined) {
            let sessionTimeout = JSON.parse(localStorage.getItem('user'));

            const decodedToken = jwtDecode(sessionTimeout?.idToken);

            // localStorage.setItem('sectimer', decodedToken?.exp);

            // Convert the expiration time from seconds to milliseconds
            const expirationTime = decodedToken?.exp * 1000;

            // Get the current time in milliseconds
            const currentTime = new Date().getTime();

            // Compare the expiration time with the current time
            if (currentTime >= expirationTime) {
                toast.warning("Sorry for the inconvenience, please Login again. ");
                handlelogOut();
            }
        }

        if (modalsValue) {
            setOpen(true);

        }
    }, [])
    const handleContinueSession = async () => {

        let userObj = JSON.parse(window.localStorage.getItem('user'));
        const res = await getRefreshToken(userObj);
        if (res?.data?.status === true) {
            setOpen(false);
            userObj.accessToken = res?.data?.body?.accessToken;
            userObj.idToken = res?.data?.body?.idToken;
            const currentTime = new Date().getTime();
            localStorage.setItem('lastActiveTime', currentTime.toString());
            localStorage.setItem("modals", false)
            localStorage.removeItem("sectimer")
            localStorage.removeItem("modals")
            window.localStorage.setItem("user", JSON.stringify(userObj));
        } else {
            localStorage.clear();
            navigate(`/login`);


        }

    };
    const onIdle = () => {
        // let sessionTimeout = JSON.parse(localStorage.getItem('user'));

        // if (sessionTimeout?.idToken?.exp)
        setOpen(true)
        localStorage.setItem("modals", true)
        const currentTimeSec = new Date().getTime();
        const futureTimeSec = currentTimeSec + additionalSec;
        if (!localStorage.getItem('sectimer')) {
            localStorage.setItem('sectimer', futureTimeSec.toString());
        }


        // logOut({ "accessToken": accessToken, "userId": user?.userId }).then(res => {
        //     console.log(res.data.status, "status res")
        // })
        // localStorage.removeItem("user");
        // localStorage.removeItem('refreshToken');


    }
    useIdleTimer({
        onIdle,
        timeout,
        throttle: 500
    })
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setRemaining(Math.ceil(getRemainingTime() / 1000))
    //     }, 500)

    //     return () => {
    //         clearInterval(interval)
    //     }
    // },[])
    return (
        <>
            <TimeOutWarning open={open} handlelogOut={handlelogOut} handleContinueSession={handleContinueSession} />
        </>
    )
}
