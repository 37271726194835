/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import { Box } from '@mui/material'
import RMDashboardSectionOne from "./RMDashboardSectionOne"
import RMDashboardSectionThree from "./RMDashboardSectionThree"
import { getDashboardDataForRM, getRecProductsForRM, getRecServicesForRM } from '../../../../services';
import { getAUMLearderboardData, getRevenueLearderboardData } from '../../../../services/DashboardAPI';
import { formatNumber } from '../../../../constant/DollorFormats';
import { getListOfProspects, getListOfClients } from '../../../../services/index';
import { updateAPI1, updateAPI2, updateAPI3, updateAPI4, updateAPI5, updateAPI6 } from '../../../../redux/slices/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import {
    setIsContactsLoading, setIsMarketNewsLoading
} from '../../../../redux/slices/generalSlice'

const LazyLoadingRM = () => {
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));// eslint-disable-next-line
    const [isLoading, setLoading] = useState(false);
    const decodedToken = jwtDecode(user?.idToken);// eslint-disable-next-line  
    // eslint-disable-next-line
    const [aumpage, setAumpage] = useState(1);// eslint-disable-next-line
    const [revenuepage, setRevenuepage] = useState(1);// eslint-disable-next-line
    const [finProdServLoader, setFinProdServLoader] = useState(false);
    const [lifProdServLoader, setLifProdServLoader] = useState(false);
    const [handleLoader, setHandleLoader] = useState(false);
    const [clientsPage, setClientsPage] = useState(0)
    const [prospectsPage, setProspectsPage] = useState(0)
    const dashboardContent = useSelector((state) => state.dashboard[btoa(decodedToken?.email)]);

    useEffect(() => {
        const obj = {
            "loginUserId": user?.userId,
            "pageNo": 0,
            "pageSize": 10,
        }
        if (!dashboardContent?.api1 && !dashboardContent?.api2 && !dashboardContent?.api3 && !dashboardContent?.api4 && !dashboardContent?.api5 && !dashboardContent?.api6) {
            setLoading(true);
            dispatch(setIsMarketNewsLoading(true))
            dispatch(setIsContactsLoading(true))
        }
        getDashboardDataForRM({ ...obj, "sequence": 1 }).then(seqresponse1 => {

            if (seqresponse1?.data?.status) {

                // filter cardData to remove objects with certain labels
                const cardsData = seqresponse1?.data?.body?.cardData.filter(card => ["Total AUM", "Total Client Net Worth", "TTM Revenue"].includes(card.header))

                dispatch(updateAPI1({
                    user: btoa(decodedToken?.email), data: {
                        cardList: cardsData,
                        aggAssetAllocation: seqresponse1?.data?.body?.aggregateAssetAllocation,
                        aggSectorAllocation: seqresponse1?.data?.body?.sectorAssetAllocation,
                        aggGeoAllocation: seqresponse1?.data?.body?.geographicalAssetAllocation,
                    }
                }));
                setLoading(false);

            }
        })
        getDashboardDataForRM({ ...obj, "sequence": 2 }).then(seqresponse2 => {
            if (seqresponse2?.data?.status) {

                dispatch(updateAPI2({
                    user: btoa(decodedToken?.email), data: {
                        aumleaderboard: seqresponse2?.data?.body?.aumLeaderboard,
                        revenueleaderboard: seqresponse2?.data?.body?.revenueLeaderboard,
                        totalResponse: seqresponse2?.data?.body,
                        ttmAggAum: seqresponse2?.data?.body?.ttmAggregateAumBarChart,
                        ttmInvWealth: seqresponse2?.data?.body?.ttmInvestedWealthBarChart
                    }
                }));
            }
        })


        getDashboardDataForRM({ ...obj, "sequence": 3 }).then(seqresponse3 => {
            if (seqresponse3?.data?.status) {
                //setFinportfolio(seqresponse3?.data?.body?.financialPortfolio)
                const formatValues = seqresponse3?.data?.body?.hniAsset
                formatValues?.forEach(val => {
                    val.asset = formatNumber(val.asset)
                })
                // setHnileaderboard(formatValues)
                // setHniList(seqresponse3?.data?.body?.hniList)
                // setLifportfolio(seqresponse3?.data?.body?.lifestylePortfolio)
                dispatch(setIsMarketNewsLoading(false))

                const formatProductValues = seqresponse3?.data?.body?.productRevenue
                formatProductValues?.forEach(val => {
                    val.revenue = formatNumber(val.revenue)
                })
                // setProductleaderboard(formatProductValues)
                const formatServiceValues = seqresponse3?.data?.body?.serviceRevenue
                formatServiceValues?.forEach(val => {
                    val.revenue = formatNumber(val.revenue)
                })
                // setServiceleaderboard(formatServiceValues)


                dispatch(updateAPI3({
                    user: btoa(decodedToken?.email), data: {
                        hniList: seqresponse3?.data?.body?.hniList,
                        productleaderboard: formatProductValues,
                        serviceleaderboard: formatServiceValues,
                        lifportfolio: seqresponse3?.data?.body?.lifestylePortfolio,
                        finportfolio: seqresponse3?.data?.body?.financialPortfolio,
                        hnileaderboard: formatValues,
                        marketNews: seqresponse3?.data?.body?.marketNews?.marketNews

                    }
                }));
            }
        })
        getDashboardDataForRM({ ...obj, "sequence": 4, "pageSize": 4 }).then(seqresponse4 => {
            if (seqresponse4?.data?.status) {
                // setAdvsuite(seqresponse4?.data?.body?.manageYourClientAdvisorySuite)
                // setRecProducts(seqresponse4?.data?.body?.rmDashboardRecommendedProducts?.rmDashboardRecommendedProducts)
                // setProductTotalPages(seqresponse4?.data?.body?.rmDashboardRecommendedProducts?.totalPages)
                // setRecServices(seqresponse4?.data?.body?.rmDashboardRecommendedServices?.rmDashboardRecommendedServices)
                // setServiceTotalPages(seqresponse4?.data?.body?.rmDashboardRecommendedServices?.totalPages)

                dispatch(updateAPI4({
                    user: btoa(decodedToken?.email), data: {
                        prodpage: 0,
                        servpage: 0,
                        advsuite: seqresponse4?.data?.body?.manageYourClientAdvisorySuite,
                        recProducts: seqresponse4?.data?.body?.rmDashboardRecommendedProducts?.rmDashboardRecommendedProducts,
                        productTotalPages: seqresponse4?.data?.body?.rmDashboardRecommendedProducts?.totalPages,
                        serviceTotalPages: seqresponse4?.data?.body?.rmDashboardRecommendedServices?.totalPages,
                        recServices: seqresponse4?.data?.body?.rmDashboardRecommendedServices?.rmDashboardRecommendedServices,
                    }
                }));

            }
        })

        const clientsRequestPayload = {
            loginUserId: user?.userId,
            pageNo: clientsPage,
            pageSize: 200,
            rmId: user?.userId,
            entityId: user?.entityId
        };


        getListOfClients(clientsRequestPayload).then((resClients) => {
            if (resClients?.data?.status) {
                const newItems = resClients?.data?.body ?? [];
                if (newItems?.length > 0) {
                    // adding the clientt to redux presist
                    dispatch(updateAPI5({
                        user: btoa(decodedToken?.email), data: {
                            ...dashboardContent?.api5,
                            clients: newItems,

                        }
                    }));
                    // as we discuss , remove the page variable in future its required we can add here
                    // setClientsPage((prevClientsPage) => prevClientsPage + 1);
                }
                // as we discuss , remove the page variable in future its required we can add here

            }
            dispatch(setIsContactsLoading(false))
        })

        const prospectsRequestPayload = {
            loginUserId: user?.userId,
            pageNo: prospectsPage,
            pageSize: 200,
            rmId: user?.userId,
            entityId: user?.entityId
        }

        getListOfProspects(prospectsRequestPayload).then((resProspects) => {
            if (resProspects?.data?.status) {
                const newItemsL = resProspects?.data?.body ?? [];
                if (newItemsL?.length > 0) {

                    dispatch(updateAPI6({
                        user: btoa(decodedToken?.email), data: {
                            ...dashboardContent?.api6,
                            prospects: newItemsL,

                        }
                    }));
                    // as we discuss , remove the page variable in future its required we can add here
                    //  setProspectsPage((prevProspectPage) => prevProspectPage + 1);
                }
                // // as we discuss , remove the page variable in future its required we can add here

            }
        })
        setHandleLoader(true);
        // eslint-disable-next-line
    }, [])


    const handlePageProdChange = async (e, value) => {

        // setProdpage(value - 1)
        const productObj = {
            "loginUserId": user?.userId,
            "pageNo": value - 1,
            "pageSize": 4,
        }

        if (handleLoader) {
            setFinProdServLoader(true)
        }
        await getRecProductsForRM(productObj).then((prodresponse) => {
            if (prodresponse?.data?.status) { //rmDashboardRecommendedProducts
                dispatch(updateAPI4({
                    user: btoa(decodedToken?.email), data: {
                        ...dashboardContent?.api4,
                        prodpage: value - 1,
                        recProducts: prodresponse?.data?.body?.rmDashboardRecommendedProducts?.rmDashboardRecommendedProducts,
                        productTotalPages: prodresponse?.data?.body?.rmDashboardRecommendedProducts?.totalPages,
                    }
                }));

                setFinProdServLoader(false)
            }
        })
    }
    const handlePageServChange = async (e, value) => {
        const serviceObj = {
            "loginUserId": user?.userId,
            "pageNo": value - 1,
            "pageSize": 4,
        }
        if (handleLoader) {
            setLifProdServLoader(true)
        }

        await getRecServicesForRM(serviceObj).then((servresponse) => {
            if (servresponse?.data?.status) {
                dispatch(updateAPI4({
                    user: btoa(decodedToken?.email), data: {
                        ...dashboardContent?.api4,
                        servpage: value - 1,
                        recServices: servresponse?.data?.body?.rmDashboardRecommendedServices?.rmDashboardRecommendedServices,
                        serviceTotalPages: servresponse?.data?.body?.rmDashboardRecommendedServices?.totalPages,

                    }
                }));

                setLifProdServLoader(false)
            }
        })
    }
    const handleAumPagination = async (e, value) => {
        setAumpage(value);
        let paginateObj = {
            loginUserId: user?.userId,
            pageNo: value - 1,
            pageSize: 10
        }
        let aumResponse = await getAUMLearderboardData(paginateObj)
        if (aumResponse?.data?.status) {
            dispatch(updateAPI2({ user: btoa(decodedToken?.email), data: { ...dashboardContent?.api2, aumleaderboard: aumResponse?.data?.body?.aumLeaderboard } }));
        }
    }

    const handleRevenuePagination = async (e, value) => {
        setRevenuepage(value);
        let paginateObj = {
            loginUserId: user?.userId,
            pageNo: value - 1,
            pageSize: 10
        }
        let revenueResponse = await getRevenueLearderboardData(paginateObj);
        if (revenueResponse?.data?.status) {
            dispatch(updateAPI2({ user: btoa(decodedToken?.email), data: { ...dashboardContent?.api2, revenueleaderboard: revenueResponse?.data?.body?.revenueLeaderboard, } }));
        }
    }


    return (
        <>
           {!isLoading && <Box id="lazyloadingrm-box" minHeight="100vh" style={{ padding: '20px', marginBottom: "100px" }}>
                <Box key={"section1"} marginBottom={2}>
                    <RMDashboardSectionOne
                        handleAumPagination={handleAumPagination}
                        handleRevenuePagination={handleRevenuePagination}
                    />
                </Box>
                <Box key={"section2"} marginBottom={2}>
                    <RMDashboardSectionThree
                        handlePageProdChange={handlePageProdChange}
                        handlePageServChange={handlePageServChange}
                        finProdServLoader={finProdServLoader}
                        lifProdServLoader={lifProdServLoader}
                    />
                </Box>
                {/* <div ref={loaderRef} id="lazyloadingVisible"></div> */}
            </Box>}
            </>
    );
};




export default LazyLoadingRM;
