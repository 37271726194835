import React, { useState } from 'react';
import { Modal, Box, } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const dotsAnimation = keyframes`
  0% {
    box-shadow: 22.4px 0,-22.4px 0;
    background: #474bff;
  }
  33% {
    box-shadow: 22.4px 0,-22.4px 0 rgba(71,75,255,0.13);
    background: rgba(71,75,255,0.13);
  }
  66% {
    box-shadow: 22.4px 0 rgba(71,75,255,0.13),-22.4px 0;
    background: rgba(71,75,255,0.13);
  }
`;

const Dots = styled('div')`
  width: 13.4px;
  height: 13.4px;
  background: #474bff;
  border-radius: 50%;
  box-shadow: 22.4px 0, -22.4px 0;
  animation: ${dotsAnimation} 1s infinite linear alternate;
`;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AnimatedDotsModal = (props) => {
    const [open, setOpen] = useState(props?.open);

    const handleClose = () => setOpen(false);

    return (
        <div>
            {/* <Button onClick={handleOpen}>Open Modal</Button> */}
            <Modal
                open={open}
                onClose={handleClose}
                sx={{
                    maxHeight: { xs: "95vh", sm: "95vh" },
                    '& .MuiBackdrop-root': {

                        background: "grey"
                    }
                }}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Dots />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default AnimatedDotsModal;
