import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import Header from "../../../common/Header/Header"
import { Formik } from "formik";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useState } from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../../theme";// eslint-disable-next-line
import { useLocation, useNavigate } from "react-router-dom";

import Modal from '@mui/material/Modal';
import { checkoutSchema } from "./formutilities";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Timer from "./Timer"
import '../../../../theme/styles/globalStyles.css'
import { AES256_GCM_ENCRYPT } from "../../../../constant/EncryptionDecryptionUtils"
import { otpVerified, generateOTP } from "../../../../services/index"

const doubleColor = {

    background: "linear-gradient(#fff, #000)",
    WebkitBackgroundClip: "text !important",
    WebkitTextFillColor: "transparent",
}// eslint-disable-next-line
const modalFirst = {
    textAlign: "center",
    color: "white",
    padding: "30px",
    marginTop: "45px !important",
    fontSize: "20px",
}





const Form = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const { state } = useLocation();
    const isMobileTab = useMediaQuery(theme.breakpoints.down('md'));// eslint-disable-next-line 
    const [generateOtp, setGenerateOtp] = useState(state?.guestUser === true ? true : false)// eslint-disable-next-line
    const navigate = useNavigate();
    const [otp, setOtp] = useState('')
    const [open, setOpen] = useState(false);
    const [isFirstTime, setIsFirstTime] = useState(false);
    const statesd = { email: "rm@gmail.com", mobile: "8870230519" };
    const initialValues = state || statesd;
    //const initialValues = { email: "rm@gmail.com", mobile: "8870230519" };
    const guestUser = JSON.parse(localStorage.getItem("guestUser"));

    // useEffect(() => {
    //     if (guestUser !== null) {
    //         setGenerateOtp(true)

    //     }
    // }, [])
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async (event) => {
        event.preventDefault();
        if (state?.guestUser === true) {
            setOpen(false);
            navigate('/guestdashboard')
        } else {
            setOpen(false);
            navigate('/login')
        }

    };

    const handleChangeOTP = (newValue) => {
        // let telephone = newValue
        // var regexp = /^[0-9\b]+$/
        // // if value is not blank, then test the regex
        // if (telephone === ' ' && telephone === '' || regexp.test(telephone)) {
        //     setOtp(telephone)
        // }
        setOtp(newValue)


    }
    const handleFormSubmit = async (values) => {
        if (state?.guestUser) {
            if (otp === guestUser?.otpValue) {
                setIsFirstTime(true)
                handleClickOpen();
            } else {
                toast("Please enter correct otp")

            }

        } else {


            if (generateOtp === false) {
                let result = await generateOTP({ email: await AES256_GCM_ENCRYPT(state.email) });

                if (result.data.statusMessage && result.data.status) {
                    toast.success(result.data.statusMessage)
                    setGenerateOtp(true);
                }
                else {
                    toast("Something Went Wrong!")
                }


            }
            else {
                var responseFromApi = await otpVerified({ email: await AES256_GCM_ENCRYPT(state.email), confirmationCode: await AES256_GCM_ENCRYPT(otp), password: await AES256_GCM_ENCRYPT(state.password) })

                if (responseFromApi.data.statusMessage && responseFromApi.data.status) {
                    //toast.success(responseFromApi.data.message)
                    if (responseFromApi?.data?.first) {
                        setIsFirstTime(true)
                        handleClickOpen();
                    }
                    else {
                        handleClickOpen();
                    }

                }
                else if (!responseFromApi.data.status) {
                    toast(responseFromApi.data.errorMessage)
                    if (responseFromApi.data.errorMessage === "Attempt limit exceeded, please try after some time.") {
                        setTimeout(() => {
                            navigate("/login")
                        }, 3000)
                    }
                }
                // 
            }

        }
    };
    return (
        <Box sx={{ width: "300px", mt: "25px", mb: "25px", overflowY: "auto !important" }}>
            {state?.guestUser ? <Header title="Verification" subtitle="We will send you an One Time Password on your Mobile" dark={!generateOtp ? true : false} isNonMobile={isNonMobile} fontFamilies={"Mona Sans"} /> : <Header title="Verification" subtitle="We will send you an One Time Password on your Email" dark={!generateOtp ? true : false} isNonMobile={isNonMobile} fontFamilies={"Mona Sans"} />
            }

            <Modal
                open={open}
                onClose={handleClose}>
                <Box //className="modalBoxs"

                    // sx={modalBoxs}

                    style={{
                        position: 'absolute',
                        marginTop: "10px",
                        top: '50%',
                        paddingTop: "25px",
                        backgroundColor: "#693BE1",
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        minWidth: 330,
                        height: "200px",
                        borderRadius: "8px"
                    }}
                >
                    {/* //Verified! You have successfully verified your account! */}
                    {isFirstTime &&
                        <div sx={{ marginTop: "40% !important", paddingTop: "10px" }}><Typography id="modal-modal-title1" variant="h1" sx={{ fontSize: "20px", textAlign: "center", color: "white" }}>
                            Verified!
                        </Typography>
                            <Typography id="modal-modal-description" sx={{ marginTop: "25px", fontSize: "14px", textAlign: "center", color: "white" }}>
                                You have successfully verified your account!
                            </Typography></div>
                    }
                    {/* {state?.guestUser &&
                        <div sx={{ marginTop: "40% !important", paddingTop: "10px" }}><Typography id="modal-modal-title1" variant="h1" sx={{ fontSize: "20px", textAlign: "center", color: "white" }}>
                            Verified!
                        </Typography>
                            <Typography id="modal-modal-description" sx={{ marginTop: "25px", fontSize: "14px", textAlign: "center", color: "white" }}>
                                You have successfully verified your account!
                            </Typography></div>
                    } */}
                    {!isFirstTime &&
                        <div sx={{ marginTop: "40px !important", paddingTop: "10px" }}>
                            <Typography id="modal-modal-title" variant="h1" sx={{ fontSize: "20px", textAlign: "center", color: "white", fontFamily: "Mona Sans" }}>
                                Password Changed!
                            </Typography>
                            <Typography id="modal-modal-descriptiond" sx={{ marginTop: "25px", fontSize: "14px", textAlign: "center", color: "white", fontFamily: "Mona Sans" }}>
                                Your Password has been Successfully changed.
                            </Typography></div>
                    }



                    <div style={{ justifyContent: "center", alignItem: "center", textAlign: "center", marginTop: 27 }}>
                        <Button onClick={handleClose} style={{
                            fontFamily: "Mona Sans",
                            width: "120px",
                            height: "45px",
                            borderRadius: 8,
                            backgroundColor: "#FFFFFF",
                            color: "#693BE1",
                            fontSize: "18px"
                        }} variant="contained">
                            OK
                        </Button>
                    </div>

                </Box>
            </Modal>






            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            marginBottom={"30px"}
                            // mt={"1px"}
                            gap="10px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },

                            }}
                        >

                            {isMobileTab && <Typography variant="h6" style={{ fontSize: "13px", fontFamily: "Mona Sans", color: "white", width: "200px" }}>
                                Email address
                            </Typography>}
                            <TextField
                                fullWidth
                                variants="standard"
                                type="text"
                                disabled
                                label={isMobileTab ? "" : "Email address"}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{

                                    gridColumn: "span 4",
                                    height: '40px',



                                    "& .MuiInputLabel-root ": {

                                        color: !isNonMobile ? doubleColor : "",
                                    },


                                    // width: '350px',
                                    '& input::placeholder': {
                                        textOverflow: 'ellipsis !important',
                                        color: '#363636 !important',
                                        opacity: 1,
                                    },

                                    '& .MuiInputBase-root': {
                                        height: '40px !important',
                                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                                        borderRadius: '8px',

                                    },
                                }}

                            />
                            {isMobileTab && <Typography variant="h6" style={{ fontSize: "13px", fontFamily: "Mona Sans", color: "white" }}>
                                Mobile
                            </Typography>}
                            <TextField
                                fullWidth
                                variants="standard"
                                id="phonenumberIds"
                                type="text"
                                disabled
                                label={isMobileTab ? "" : "Mobile"}

                                name="mobile"
                                error={!!touched.mobile && !!errors.mobile}
                                helperText={touched.mobile && errors.mobile}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.mobile}
                                sx={{

                                    gridColumn: "span 4",
                                    height: '40px',
                                    "& .MuiInputLabel-root ": {
                                        color: !isNonMobile ? doubleColor : "",
                                    },


                                    '& .MuiInputBase-root': {
                                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                                        borderRadius: '8px',
                                        height: '40px !important',


                                    },
                                    '& input::placeholder': {
                                        textOverflow: 'ellipsis !important',
                                        color: '#363636',
                                        opacity: 1,
                                    },
                                }}

                            >


                            </TextField>


                        </Box>
                        {generateOtp && <div style={{ marginTop: "10px" }}>
                            {state?.guestUser ? < Header title="Enter OTP" subtitle="OTP has been sent to your Mobile. It will be Valid for 30 minutes." dark={true} isNonMobile={isNonMobile} fontFamilies={"Mona Sans"} /> : <Header title="Enter OTP" subtitle="OTP has been sent to your Email address. It will be Valid for 30 minutes." dark={true} isNonMobile={isNonMobile} fontFamilies={"Mona Sans"} />
                            }
                            <MuiOtpInput length={6} TextFieldsProps={{ placeholder: '-' }} sx={{
                                maxWidth: "330px",
                                height: '40px',
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "16px !important",
                                '& input::placeholder': {

                                    color: '#363636',
                                    opacity: 1,
                                },
                                '& .MuiInputBase-root': {
                                    height: '40px !important',
                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                    borderRadius: '8px',
                                    padding: isNonMobile === false ? "0px" : ""
                                },
                                '& .MuiOutlinedInput-input': {
                                    borderRadius: '8px',
                                    padding: isNonMobile === false ? "0px !important" : ""
                                },

                                // 
                            }} value={otp} onChange={handleChangeOTP} /></div>}
                        {!generateOtp &&
                            <Box display="flex" justifyContent="center" mt="15px">
                                <Button className='PrimaryColor' type="submit" data-testid="buttonGenerate" variant="contained" fullWidth style={{
                                    borderRadius: 8,
                                    marginBottom: "10px",
                                    fontFamily: "Mona Sans",
                                    backgroundColor: "#1DB954",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                    textTransform: 'capitalize'
                                }}>
                                    Generate OTP
                                </Button>
                            </Box>
                        }
                        {generateOtp &&
                            <Box display="flex" justifyContent="center" mt="20px">
                                <Button type="submit" data-testid="verify-submit-button" fullWidth disabled={otp.length === 6 ? false : true}
                                    style={{
                                        borderRadius: 8,
                                        fontFamily: "Mona Sans",
                                        marginBottom: "10px",
                                        backgroundColor: otp.length === 6 ? "#693BE1" : "gray",
                                        color: "#FFFFFF",
                                        fontSize: "14px"
                                    }} variant="contained">
                                    Verify OTP
                                </Button>
                            </Box>
                        }

                        {generateOtp && <Box display="flex" justifyContent="left" mt="10px" mb="30px">
                            <Typography variant="h6" color={!isNonMobile ? "white" : colors.grey[700]} style={{ fontSize: "12px", fontFamily: "Mona Sans" }}>
                                Didn't receive the Verification OTP? <span style={{ fontWeight: "bold", color: !isNonMobile ? "white" : "#383838", cursor: "pointer" }} ><Timer guestUserTimer={state?.guestUser} email={state.email} isNonMobile={isNonMobile} /></span>
                            </Typography>
                        </Box>}
                    </form>
                )}
            </Formik>
        </Box>
    );
};




export default Form;
