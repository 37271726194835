import React, { Component } from 'react'
import { generateOTP } from "../../../../services/index"
import { toast } from 'react-toastify';
import { SendSms } from '../../../../services/guestUserServices';

import { AES256_GCM_ENCRYPT } from "../../../../constant/EncryptionDecryptionUtils"
export default class Timer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            minutes: 3,
            seconds: 0,
            email: AES256_GCM_ENCRYPT(props.email),
            guestUserTimer: props.guestUserTimer

        }
    }


    resendOTP = async () => {
        const guestUser = JSON.parse(localStorage.getItem("guestUser"))
        if (guestUser && this.props.guestUserTimer) {
            let otpValue = '';
            const digits = '0123456789';
            for (let i = 0; i < 6; i++) {
                const randomIndex = Math.floor(Math.random() * digits.length);
                otpValue += digits[randomIndex];
            }
            const guestUserobj = {
                mobile: '+' + guestUser?.currencyCode + guestUser?.mobileNumber,
                name: guestUser?.firstName + " " + guestUser?.lastName,
                message: `Welcome to Kairos this is your one time password ${otpValue}`,
                subject: 'one time password'
            }
            let result = await SendSms(guestUserobj)
            if (this.state.guestUserTimer && result?.data?.status) {
                guestUser.otpValue = otpValue
                this.setState({
                    minutes: 3,
                    seconds: 0,
                })
                this.run();
                localStorage.setItem("guestUser", JSON.stringify(guestUser));
                toast.success(result?.data?.body)

            }
        } else {
            let result = await generateOTP({ email: await AES256_GCM_ENCRYPT(this.props.email) })
            if (result?.data?.status) {
                this.setState({
                    minutes: 3,
                    seconds: 0,
                })
                this.run();
                toast.success(result?.data?.statusMessage)

            }
            else if (!result?.data?.status) {
                toast(result?.data?.errorMessage)
                if (result?.data?.errorMessage === "Attempt limit exceeded, please try after some time.") {
                    setTimeout(() => {
                        this.props.navigate("/login")
                    }, 3000)
                }
            }
        }
    }
    run = () => {
        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }
        }, 1000)
    }

    componentDidMount() {
        this.run()
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    render() {
        const { minutes, seconds } = this.state
        return (
            <>
                {minutes === 0 && seconds === 0
                    ? <span onClick={this.resendOTP} style={{ fontFamily: "Mona Sans" }} >Resend Again!</span>
                    : <>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</>
                }
            </>
        )
    }
}