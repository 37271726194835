import * as React from "react";
import CatalogIndex from "./CatalogIndex";
import { useParams } from 'react-router-dom';
import { getAlldropDownForCatalog } from "../../../services/catalogProductandService"
import withPermissionsHoc from "../../Layout/PermissionsHoc/PermissionsHoc"
import { FormModalProducts } from "./FormModal/FormModal";
import { useDispatch, useSelector } from 'react-redux';
import { updateDropdownValues } from '../../../redux/slices/ProductServiceSlices';

import Loader from "../../common/Loader/Loader";
import "../../../theme/styles/Catalog.css"
function ProductsServiceCatalog(props) {

    const dropdownValues = useSelector(state => state.productService.dropdownValues);
   const [passingValueForrender, setpassingValueForrender] = React.useState(!dropdownValues?{}:dropdownValues)
    const { id } = useParams()
    const [isLoading, setisLoading] = React.useState(true)
    const { formFieldProducts } = FormModalProducts;
    const user = JSON.parse(localStorage.getItem('user'))
    const urls = window.location.pathname;
    const dispatch = useDispatch();
    React.useEffect(() => {
         setisLoading(true)
            if(Object.keys(dropdownValues).length !== 0){
                setTimeout(()=>{
                    setisLoading(false) 
                },100)
            }
        getDropDownValue()
        // eslint-disable-next-line
    }, [urls])

    const getDropDownValue = async () => {
        const listDropDown = await getAlldropDownForCatalog({
            catalogue: true,
            loginUserId: user?.userId,
            clientId: user?.userId,
        });

        const filterDropDown = listDropDown?.data?.body;
        if (filterDropDown) {
            setpassingValueForrender(filterDropDown) 
            // Update the Dropdown values in redux store
            dispatch(updateDropdownValues(filterDropDown));
            setTimeout(async () => {
                setisLoading(false)
            }, 900)
        }
    }

    return (<>
        {isLoading && <Loader isLoading={isLoading} />}
        {!isLoading &&
            <CatalogIndex 
                url={props?.url} 
                passingValueForrender={passingValueForrender} 
                formFieldProducts={formFieldProducts} 
                recommentation={props?.recommentation} 
                id={id ? atob(id) : null} 
                portfolio={props?.portfolio} 
                portfoliaChanges={props?.portfoliaChanges} 
            />
        }</>);
}


export default withPermissionsHoc(ProductsServiceCatalog, "Product");
