/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, AvatarGroup, Avatar } from '@mui/material'
import moment from "moment";

import { UseCurrencyHook } from '../../common/GlobalCurrency/useCurrencyHook';
import { TitleConverstion } from '../../../constant/DollorFormats';
import { useSelector } from 'react-redux';
// import all icons from theme/images/ProductCardsIcons
// Financial icons first
import EquityIcon from '../../../theme/images/ProductCardsIcons/icon_justice_scale_outline.svg'
import FixedIncomeIcon from '../../../theme/images/ProductCardsIcons/icon_coin_share_outline.svg'
import CashIcon from '../../../theme/images/ProductCardsIcons/icon_dollar_coin_outline.svg'
import StructuredProductsIcon from '../../../theme/images/ProductCardsIcons/icon_stacked_boxes_outline.svg'
import FundsAndETFsIcon from '../../../theme/images/ProductCardsIcons/icon_graph_bar_increase_outline.svg'
import CommoditiesIcon from '../../../theme/images/ProductCardsIcons/icon_stacked_gold_outline.svg'
import RealEstateIcon from '../../../theme/images/ProductCardsIcons/icon_building_outline.svg'
import ESGIcon from '../../../theme/images/ProductCardsIcons/icon_leaf_outline.svg'
import InsuranceIcon from '../../../theme/images/ProductCardsIcons/icon_shield_outline.svg'
import CryptoIcon from '../../../theme/images/ProductCardsIcons/icon_bitcoin_outline.svg'
import PrivateEquityIcon from '../../../theme/images/ProductCardsIcons/icon_diamond_outline.svg'
import GrowthEquityIcon from '../../../theme/images/ProductCardsIcons/icon_stock_outline.svg'
import VentureCapitalInvestmentIcon from '../../../theme/images/ProductCardsIcons/icon_money_lightbulb_outline.svg'
import PrivateCreditIcon from '../../../theme/images/ProductCardsIcons/icon_padlock_outline.svg'
import PreIpoProductsIcon from '../../../theme/images/ProductCardsIcons/icon_flag_progress_outline.svg'
import DirectDealProductsIcon from '../../../theme/images/ProductCardsIcons/icon_money select_outline.svg'
import OtherAlternativesIcon from '../../../theme/images/ProductCardsIcons/icon_four_circle_outline.svg'
// Then Services icons
import PortfolioManagementIcon from '../../../theme/images/ProductCardsIcons/icon_hierarchy_outline.svg'
import RiskManagementIcon from '../../../theme/images/ProductCardsIcons/icon_rock_slide_outline.svg'
import WealthPlanningIcon from '../../../theme/images/ProductCardsIcons/icon_desktop_dollar_outline.svg'
import TaxPlanning from '../../../theme/images/ProductCardsIcons/icon_receipt_outline.svg'
import LegalIcon from '../../../theme/images/ProductCardsIcons/icon_justice_hammer_outline.svg'
import SuccessionIcon from '../../../theme/images/ProductCardsIcons/icon_crown_outline.svg'
import OtherServicesIcon from '../../../theme/images/ProductCardsIcons/icon_four_circle_outline.svg'
// Then Lifestyle icons
import ArtIcon from '../../../theme/images/ProductCardsIcons/icon_paint_palette_outline.svg'
import WineIcon from '../../../theme/images/ProductCardsIcons/icon_wine_outline.svg'
import BordeauxIcon from '../../../theme/images/ProductCardsIcons/icon_wine_outline.svg'
import WellnessIcon from '../../../theme/images/ProductCardsIcons/icon_hot_spring_outline.svg'
import FoodIcon from '../../../theme/images/ProductCardsIcons/icon_serving_dome_outline.svg'
import HealthIcon from '../../../theme/images/ProductCardsIcons/icon_insurance_hand_outline.svg'
import SportIcon from '../../../theme/images/ProductCardsIcons/icon_ball_outline.svg'
import TravelIcon from '../../../theme/images/ProductCardsIcons/icon_airplane_outline.svg'
import OtherLifestyleIcon from '../../../theme/images/ProductCardsIcons/icon_four_circle_outline.svg'


const ProductCard = ({ product, data }) => {
    const productObject = product;
    const currency = useSelector(state => state.globalCurrency.currency);
    const currencyTitle = useSelector(state => state.globalCurrency.titleCurrency);
    const user = JSON.parse(localStorage.getItem('user'));
    const rmvalue = user?.userTypeId?.description
    const [productType, setIsProductOrService] = React.useState("product");

    const navigate = useNavigate();


    const handleClickViewProduct = (productid) => {
        if (product?.recommendation) {
            navigate("/viewProductDetails/" + btoa(productid), { state: { recommentation: product?.recommendation } })
        } else {
            navigate("/viewProductDetails/" + btoa(productid), { state: { recommentation: "dashboard" } })
        }
    }
    const handleClickViewService = (serviceid) => {
        if (product?.recommendation) {
            navigate("/viewServiceDetails/" + btoa(serviceid), { state: { recommentation: product?.recommendation } })
        } else {
            navigate("/viewServiceDetails/" + btoa(serviceid), { state: { recommentation: "dashboard" } })
        }
    }

    const getIcon = (label) => {
        let productIcon = ""
        switch (label) {
            case "EQUITIES": productIcon = EquityIcon; break;
            case "FIXED INCOME": productIcon = FixedIncomeIcon; break;
            case "CASH OR EQUIVALENT": productIcon = CashIcon; break;
            case "STRUCTURED PRODUCTS": productIcon = StructuredProductsIcon; break;
            case "FUNDS AND ETFS": productIcon = FundsAndETFsIcon; break;
            case "COMMODITIES": productIcon = CommoditiesIcon; break;
            case "REAL ESTATE": productIcon = RealEstateIcon; break;
            case "ESG/IMPACT INVESTING": productIcon = ESGIcon; break;
            case "INSURANCE": productIcon = InsuranceIcon; break;
            case "CRYPTO": productIcon = CryptoIcon; break;
            case "PRIVATE EQUITY": productIcon = PrivateEquityIcon; break;
            case "GROWTH EQUITY": productIcon = GrowthEquityIcon; break;
            case "VENTURE CAPITAL INVESTMENT": productIcon = VentureCapitalInvestmentIcon; break;
            case "PRIVATE CREDIT": productIcon = PrivateCreditIcon; break;
            case "PRE-IPO PRODUCTS": productIcon = PreIpoProductsIcon; break;
            case "DIRECT DEAL PRODUCTS": productIcon = DirectDealProductsIcon; break;
            case "OTHER ALTERNATIVES": productIcon = OtherAlternativesIcon; break;

            case "PORTFOLIO MANAGEMENT": productIcon = PortfolioManagementIcon; break;
            case "RISK MANAGEMENT": productIcon = RiskManagementIcon; break;
            case "WEALTH PLANNING": productIcon = WealthPlanningIcon; break;
            case "TAX PLANNING": productIcon = TaxPlanning; break;
            case "LEGAL AND COMPLIANCE": productIcon = LegalIcon; break;
            case "SUCCESSION PLANNING": productIcon = SuccessionIcon; break;
            case "OTHER SERVICES": productIcon = OtherServicesIcon; break;

            case "TRAVEL": productIcon = TravelIcon; break;
            case "ART": productIcon = ArtIcon; break;
            case "WINE": productIcon = WineIcon; break;
            case "BORDEAUX": productIcon = BordeauxIcon; break;
            case "WELLNESS": productIcon = WellnessIcon; break;
            case "FOOD AND DRINK": productIcon = FoodIcon; break;
            case "FOOD": productIcon = FoodIcon; break;
            case "HEALTH": productIcon = HealthIcon; break;
            case "SPORTS": productIcon = SportIcon; break;
            case "OTHERS": productIcon = OtherLifestyleIcon; break;
            default: break;
        }
        return productIcon;
    }
    let productIcon = getIcon(productObject?.primaryCategoryId?.description);

    useEffect(() => {
        if (productObject.productServiceTypeId?.description === "LIFESTYLE SERVICES" || productObject.productServiceTypeId?.description === "FINANCIAL SERVICES") {
            setIsProductOrService("service")
        } else {
            setIsProductOrService("product")
        }
    }, [productObject])

    return (
        <Grid sx={{
            fontFamily: "Mona Sans",
            boxShadow: "none",
            width: "100%",
            minHeight: "326px",
            maxHeight: "326px",
            borderRadius: "12px",
            border: "1px solid #EBEBEB",
            backgroundSize: "cover",
            height: "100%",
            display: "flex",
            flexDirection: "column",
        }}>
            {/** Top part of the card which have the gradient */}
            <Grid
                sx={{
                    height: "146px", display: "flex", gap: 1, flexDirection: "column", alignItems: "center", padding: "20px 20px", borderRadius: "12px 12px 0 0",
                    background: productType === "service" ? "linear-gradient(61deg, #D2C6FC -0.84%, #9FB3F0 83.4%)" : "linear-gradient(16deg, #DBEBF9 -12.44%, #77B3E8 106.29%)"
                }}>
                {/** Asset Class or Product Category*/}
                <Typography title={productObject?.primaryCategoryId?.description} sx={{ width: "auto", textAlign: "center", padding: "2px 4px", fontSize: "12px", color: "#1e1e1e", backgroundColor: "#E2E8FB", borderRadius: "4px", alignSelf: "flex-end" }}>
                    {productObject?.primaryCategoryId?.description}
                </Typography>
                {/** Asset class icon */}
                <img src={productIcon} width="40px" sx={{ marginX: "auto" }} alt="" />
                {/** Previous purchases avatar group */}
                {rmvalue === "Entity RM" &&
                    <AvatarGroup sx={{ alignSelf: "flex-end", mt: 1, '& .MuiAvatar-root': { width: 12, fontSize: 12, height: 14, } }}>
                        {product?.hniDto?.slice(0, 3).map((item, index) => { return <Avatar key={"avatar" + index.toString()} src={item?.profilePhotoUrl} alt={item?.name} /> })}
                        <Typography className='typographyTextStyles'>{(data?.hniCount - 3 > 0 ? data?.hniCount - 3 : null)}</Typography>
                    </AvatarGroup>
                }
            </Grid>

            <Grid sx={{ height: "180px", display: "flex", flexDirection: "column", justifyContent: "space-between", backgroundColor: 'white', borderRadius: "0 0 12px 12px", padding: "20px 20px" }}>
                {/** Main descriptive block */}
                {/** Product title */}
                <Typography
                    onClick={() => productType === 'product' ? handleClickViewProduct(productObject?.productServiceId) : handleClickViewService(productObject?.productServiceId)}
                    title={productObject.productServiceName}
                    sx={{ fontSize: "16px", cursor: "pointer" }}>
                    {productObject?.productServiceName}
                </Typography>

                {/** Min Value and Expiry */}
                <Grid display="flex" flexDirection="row" justifyContent="space-between">
                    {/** Minimum Value */}
                    <Grid display="flex" flexDirection={"column"} sx={{ marginTop: "8px" }}>
                        <Typography title={"Minimum Value"} sx={{ fontSize: "12px", color: "#777777" }}>
                            MIN. VALUE
                        </Typography>
                        <Typography title={TitleConverstion(currencyTitle?.currencyCode, productObject?.sellingPrice, user, currency)} sx={{ fontSize: "12px" }}>
                            <UseCurrencyHook val={productObject?.sellingPrice} />
                        </Typography>
                    </Grid>
                    {/** Expiry */}
                    <Grid display="flex" flexDirection={"column"} sx={{ marginTop: "8px" }}>
                        <Typography title="Expiry Date" sx={{ fontSize: "12px", color: "#777777" }}>
                            EXPIRES IN
                        </Typography>
                        <Grid title={moment(productObject.endDate).format("D MMMM  YYYY")} sx={{ fontSize: "12px" }}>
                            <Typography sx={{ fontSize: "12px" }}>{moment(productObject.endDate).format("D MMMM  YYYY")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {/** Learn more button */}
                <Typography
                    onClick={() => productType === 'product' ? handleClickViewProduct(productObject?.productServiceId) : handleClickViewService(productObject?.productServiceId)}
                    sx={{ color: "#6442c4", fontSize: "16px", fontWeight: 500, cursor: "pointer" }}
                >
                    Learn more
                </Typography>
            </Grid>
        </Grid>
    )
}
export default ProductCard
