import * as React from 'react';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { openModal, setTableColumnData } from "../../../../redux/slices/dashboardModalSlice";
import { Typography, Grid } from '@mui/material';
import { createColumnDynamicPie } from "../../../../constant/DynamicTableColumsUtils";
import DonutCharts from "../../../common/ChartsComponent/DonutCharts";
import { getClientsAssestAllocations, getClientGeoGraphicalAllocations, getClientsSectorAllocations } from "../../../../services/DashboardAPI";
import NoDataImage from "../../../../theme/images/ProductCardsIcons/emptytransactions.svg";

export default function PieChartController(props) {
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));
    const isHni = (user?.userTypeId?.description === "Client Head" || user?.userTypeId?.description === "Client Family Member");
    let pieData = props?.data || { data: [], label: [] };
    // aggregate the data from the props into 4 + 1 "Others" category
    if (pieData !== undefined && pieData.data?.length > 0) {
        // sort the data in descending order
        let indices = Array.from(pieData.data.keys());
        indices.sort((a, b) => pieData.data[b] - pieData.data[a]);
        const sortedData = indices.map(i => pieData.data[i]);
        const sortedLabels = indices.map(i => pieData.label[i]);
        if (sortedLabels.length > 4) {
            const otherData = sortedData.slice(4).reduce((a, b) => a + b, 0);
            pieData = {
                data: sortedData.slice(0, 4).concat(otherData),
                label: sortedLabels.slice(0, 4).concat("Others")
            };
        } else {
            pieData = {
                data: sortedData,
                label: sortedLabels
            };
        }
    }

    const apiCalls = async () => {
        let passingObject = {
            loginUserId: user?.userId,
            pageNo: 0,
            pageSize: 10
        };
        let dataFromResponse;
        //depending on category Name the swith will return
        switch (props?.category) {
            case "Asset":
                dataFromResponse = await getClientsAssestAllocations(passingObject);
                return dataFromResponse?.data?.body?.clientWiseAggregateAssetAllocation;
            case "Geographical":
                dataFromResponse = await getClientGeoGraphicalAllocations(passingObject);
                return dataFromResponse?.data?.body?.clientWiseGeographicalAssetAllocation;
            default:
                dataFromResponse = await getClientsSectorAllocations(passingObject);
                return dataFromResponse?.data?.body?.clientWiseSectorAssetAllocation;
        }
    };

    const dataModalReducers = async () => {
        let dataObjects = await apiCalls();
        if (dataObjects) {
            let columsDynamics = await createColumnDynamicPie(dataObjects[0]);
            dispatch(openModal(true));
            dispatch(setTableColumnData({ titleofModal: props?.title, data: dataObjects, column: columsDynamics, loading: false }));
        }
    };

    return (
        <Box sx={{
            width: '100%', height: "100%", background: "#FFFFFF",
            borderRadius: "12px",
            border: "1px solid #EBEBEB",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start"
        }}>
            <Grid container sx={{ flex: '0 0 auto', justifyContent: "space-between", flexDirection: "row", display: "flex", p: "20px 15px" }}>
                <Grid item lg={12} md={12} xs={12}>
                    <Typography sx={{
                        fontFamily: "Mona Sans",
                        fontSize: "18px",
                        fontWeight: 500,
                        color: "#1E1E1E",
                        lineHeight: "24px",
                    }}>
                        {props?.title}
                    </Typography>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    {!isHni && <Typography sx={{
                        fontFamily: "Mona Sans",
                        fontSize: "10px",
                        letterSpacing: "0px",
                        color: "#1E1E1E",
                        cursor: "pointer",
                    }} onClick={() => { dataModalReducers() }}>
                        {"See client wise details"}
                    </Typography>}
                </Grid>
            </Grid>
            <Grid container sx={{ flex: '1 0 auto', justifyContent: "center", alignItems: "center", flexDirection: "column", pt: "10px", pb: "10px" }}>
                {pieData.data?.length > 0 && <DonutCharts data={pieData} />}
                {((!props.isloading && pieData.data?.length === 0) || (pieData?.data === undefined)) && <CenterErrorEmpty />}
                {props.isloading && <p style={{ paddingLeft: '10px' }}>loading...</p>}
            </Grid>
        </Box>
    );
}

const CenterErrorEmpty = (props) => {
    return (
        <Grid alignItems="center" container justifyContent="center" minHeight={"120px"} >
            <Grid item>
                <Typography align="center" variant="h5">
                    {((props.isloading || props?.isloading === undefined) && (props?.data?.data?.length > 0)) ? 'Loading...' :
                        <Grid sx={{ display: "flex", flexDirection: "column" }}>
                            <img src={NoDataImage} alt="No data available" sx={{ paddingX: "10px", marginBottom: 1 }} />
                            <>{"No data available"}</>
                        </Grid>}
                </Typography>
            </Grid>
        </Grid>
    );
};
