import React from 'react'
import { Modal, Typography, Box, Button, Chip, TextField, Tooltip, Grid } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid';
import { rejectwithReasonsorApproval } from "../../../../services/productManagement"// eslint-disable-next-line
import { managApproveorReject, approveRejectProductsServices, approveUserStatus, rejectUserStatus, compApproveorReject, getAdminDashboardDataWIthSection } from '../../../../services';
import { useNavigate } from 'react-router-dom';
import Loader from "../../../common/Loader/Loader"
import { ReactComponent as View } from '../../../../theme/images/View.svg'
import { submitVendorRejection } from "../../../../services/vendormanagement"
import { toast } from 'react-toastify';
import GridLayout from './gridLayout';
import { formatDate } from "../../../common/Utils/DateFormat";
import { getIndividualTaskPaginations,  } from "../../../../services/DashboardAPI"
import { updateAPI1, updateAPI2, updateAPI3, updateAPI4 } from '../../../../redux/slices/dashboardSlice';
import {  useDispatch ,useSelector} from 'react-redux';
import { jwtDecode } from 'jwt-decode';

function Cards({ readPermission, writePermission, approvePermission }) {

    const user = JSON.parse(localStorage.getItem('user'))
    const userDescription = user?.userTypeId?.description
    const complianceUser = JSON.parse(localStorage.getItem('user'))
    const compliancevalue = complianceUser?.userSubtypeId?.description
    const decodedToken = jwtDecode(user?.idToken);
    const navigate = useNavigate()
    const [initialAPIResponnse, setInitialAPIResponnse] = React.useState({});
    const [initialAPIResponnseSecond, setInitialAPIResponnseSecond] = React.useState({});
    const [page, setPage] = React.useState(1); // eslint-disable-next-line 
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [isLoading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [reason, setReason] = React.useState("");
    const [rejId, setRejId] = React.useState('')
    const [search, setSearch] = React.useState("");
    const [rejectpath, setRejectpath] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [mountingFirst,setMountingFirst] = React.useState(true);
    const dispatch = useDispatch();
   //depending on user mail id -> We use base64 format to encrypt the user mail id., the data fetch from redux presist. 
    const dashboardContent = useSelector((state) => state.dashboard[btoa(decodedToken?.email)]);

   
    React.useEffect(() => {
        const req = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: (page - 1),
            pageSize: rowsPerPage,
            search: search
        }
        setMountingFirst(false)
        const timeOut = setTimeout(() => taskAPICALL(req), 1000);
        return () => clearTimeout(timeOut);
    
        // eslint-disable-next-line
    }, [search])

    React.useEffect(() => {
        const req = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: (page - 1),
            pageSize: rowsPerPage,
            search: ''
        }
        listapi(req)
        // eslint-disable-next-line 
    }, [])

    const handleClickReject = (id, path, message) => {
        setOpen(true);
        setRejId(id)
        setRejectpath(path)
        setStatus(message)
    }

    const handleApprove = (e, id, path, profilestatus) => {
        console.log(profilestatus, 'profilestatus')
        setLoading(true)
        e.preventDefault();
        const req = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: (page - 1),
            pageSize: rowsPerPage,
            search: ''
        }
        const obj = {
            status: "Approve",
            loginUserId: user?.userId
        }

        if (path.includes('/userManagement')) {
            approveUserStatus({ ...obj, userId: Number(id) }).then(response => {
                if (response.data.status === true) {
                    listapi(req)
                    toast.success(response.data.statusMessage)
                }
                else {
                    toast.error(response.data.statusMessage)
                }
                setLoading(false)
            }).catch(error => {
                console.log("errors inside catch", error)
            })

        } else if (path.includes('/lifestyleproducts') || path.includes('/financialproducts')) {
            const serviceobj = {
                productServiceId: Number(id),
                status: "Approve",
                userId: user.userId
            }
            rejectwithReasonsorApproval(serviceobj).then((resultOfAPI) => {
                if (resultOfAPI.data.statusMessage && resultOfAPI.data.status) {
                    listapi(req)
                    toast.success(resultOfAPI.data.statusMessage)

                }
                else {
                    toast.error(resultOfAPI.data.errorMessage)
                }
                setLoading(false)

            })
        } else if (path.includes('/financialservices') || path.includes('/lifestyleservices')) {
            console.log("approve obj", obj)
            const serviceobj = {
                productServiceId: Number(id),
                status: "Approve",
                userId: user.userId
            }
            approveRejectProductsServices(serviceobj).then(response => {
                if (response.data.status === true) {
                    listapi(req)
                    toast.success(response.data.statusMessage)
                }
                else {
                    toast.error(response.data.statusMessage)
                }
                setLoading(false)

            }).catch(error => {
                console.log("errors inside catch", error)
            })
        } else if (path.includes('/vendorManagement')) {
            const passingValue = {
                userId: user.userId,
                vendorId: Number(id),

                status: "Approve",
                reason: ''
            }
            submitVendorRejection(passingValue).then((resultOfAPI) => {
                if (resultOfAPI.data.statusMessage && resultOfAPI.data.status) {
                    listapi(req)
                    toast.success(resultOfAPI.data.statusMessage)
                }
                else {
                    toast.error(resultOfAPI.data.errorMessage)
                }
                setLoading(false)

            })


        } else if (path.includes('/clientprofile') || path.includes('/prospectprofile')) {

            if (profilestatus === "compliance-approve-or-reject" || profilestatus === 'Pending for Compliance Approval') {
                let profileobj = { "userId": user.userId, "prospectId": Number(id), "status": "Approve", "reason:": "" }
                compApproveorReject(profileobj).then(approveresponse => {
                    if (approveresponse?.data?.status) {
                        listapi(req)
                        toast.success(approveresponse?.data?.statusMessage)
                    } else {
                        toast.error(approveresponse?.data?.errorMessage)
                    }
                    setLoading(false)

                })
            }
            if (profilestatus === "management-approve-or-reject") {
                console.log("approve obj", obj)
                let profileobj = { "userId": user.userId, "prospectId": Number(id), "status": "Approve" }
                managApproveorReject(profileobj).then(approveresponse => {
                    if (approveresponse?.data?.status) {
                        listapi(req)
                        toast.success(approveresponse?.data?.statusMessage)
                    } else {
                        toast.error(approveresponse?.data?.errorMessage)
                    }
                    setLoading(false)

                })
            }
        }

    }

    const handleClickView = (path) => {
        navigate(path)
    }

    const columns = [
        {
            field: 'updatedAt', headerName: 'Date',
            width: 90,
            renderCell: (params) => (
                <>
                    {formatDate(params.value, 'DD-MMM-YYYY')}
                </>
            )
        },
        ...userDescription === "Kairos Admin" ? [{
            field: 'entityName',
            headerName: 'Entity',
            width: 60,
        }] : [],
        {
            field: 'senderName',
            headerName: 'Request by',
            width: 85,
        },
        {
            field: 'typeOfApproval',
            headerName: 'Type of Approval',
            width: 120,
        },
        {
            field: 'message',
            headerName: 'Details',
            minWidth: "140",
            flex: 1,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Action',
            width: 200,
            align: 'left',
            renderCell: (params) => (
                <>
                    <Grid container>
                        <Grid item lg={10.5}>
                            {(params.row.messageType === 'Approved' || params.row.messageType === 'Rejected') ?
                                <><Tooltip title={`${params.row.messageType} by ${params.row.approverName}`} placement="top" arrow><Typography sx={{ cursor: "pointer", paddingLeft: "65px", fontSize: "0.75rem", color: params.row.messageType === 'Approved' ? "#1DB954" : "#E00000" }}>{params.row.messageType}</Typography></Tooltip></> :
                                <><GridActionsCellItem
                                    icon={
                                        (compliancevalue === 'COMPLIANCE') ? <Chip
                                            label="Approve"
                                            sx={{

                                                width: 'auto',
                                                height: '20px',
                                                borderRadius: '6px',
                                                color: "white",
                                                opactity: (params.row.messageType === 'Approved' || params.row.messageType === 'Rejected' || params.row.messageType === 'Pending for Management Approval' || isLoading) ? 0.3 : null,
                                                background: '#6442C4',
                                            }}
                                            disabled={params.row.messageType === 'Approved' || params.row.messageType === 'Pending for Management Approval' || params.row.messageType === 'Rejected' || isLoading}
                                            onClick={(e) => handleApprove(e, params.row.requestId, params.row.path, params.row.messageType === "Pending for Compliance Approval" ? "compliance-approve-or-reject" : "management-approve-or-reject")}
                                        /> :
                                            <Chip
                                                label="Approve"
                                                sx={{
                                                    width: 'auto',
                                                    height: '20px',
                                                    borderRadius: '6px',
                                                    color: "white",
                                                    background: '#6442C4',
                                                    opactity: (params.row.messageType === 'Rejected' || params.row.messageType === 'Approved' || params.row.messageType === ' Pending for Compliance Approval' || isLoading) ? 0.3 : null
                                                }}
                                                onClick={(e) => handleApprove(e, params.row.requestId, params.row.path, params.row.messageType === "Pending for Management Approval" ? "management-approve-or-reject" : "compliance-approve-or-reject")}
                                                disabled={params.row.messageType === 'Rejected' || params.row.messageType === 'Approved' || params.row.messageType === ' Pending for Compliance Approval' || isLoading}

                                            />}
                                />
                                    <GridActionsCellItem
                                        icon={(compliancevalue === 'COMPLIANCE') ? <Chip
                                            label="Decline"
                                            sx={{
                                                width: 'auto',
                                                height: '20px',
                                                borderRadius: '6px',
                                                color: "white",
                                                background: 'black',
                                                opactity: (params.row.messageType === 'Rejected' || params.row.messageType === 'Approved' || params.row.messageType === 'Pending for Management Approval' || isLoading) ? 0.3 : null

                                            }}
                                            disabled={params.row.messageType === 'Pending for Management Approval' || params.row.messageType === 'Rejected' || params.row.messageType === 'Approved' || isLoading}
                                            onClick={() => handleClickReject(params.row.requestId, params.row.path, params.row.messageType)}
                                        /> : <Chip
                                            label="Decline"
                                            sx={{
                                                width: 'auto',
                                                height: '20px',
                                                borderRadius: '6px',
                                                color: "white",
                                                background: 'black',
                                                opactity: (params.row.messageType === 'Rejected' || params.row.messageType === 'Approved' || isLoading) ? 0.3 : null

                                            }}
                                            disabled={params.row.messageType === 'Rejected' || params.row.messageType === 'Approved' || isLoading}
                                            onClick={() => handleClickReject(params.row.requestId, params.row.path, params.row.messageType)}
                                        />}

                                    /></>}
                        </Grid>
                        <Grid lg={1.5} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Grid container  >
                                <View onClick={() => handleClickView(params.row.path)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            ),
        },

    ];

    const handlePageChange = async (e, value) => {
        setPage(value)
        const listObj = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: value - 1,
            pageSize: rowsPerPage,
            search: search ? search : ''
        }
        taskAPICALL(listObj)
    }

    const handleReasonChange = (e) => {
        setReason(e.target.value)
    }

    const handleReasonSubmission = (profilestatus) => {
        setLoading(true)
        const req = {
            key: "adminDashboard",
            loginUserId: user?.userId,
            pageNo: (page - 1),
            pageSize: rowsPerPage,
            search: ''
        }
        if (reason === "") {
            toast.error("Reason is mandatory")
            setOpen(true);
        }
        else {
            if (rejectpath.includes('/userManagement')) {
                const obj = {
                    userId: Number(rejId),
                    status: "Rejected",
                    reason: reason,
                    loginUserId: user?.userId
                }
                rejectUserStatus(obj).then(response => {
                    if (response.data.status === true) {
                        toast.success(response.data.statusMessage)
                        setOpen(false);
                        taskAPICALL(req)
                    }
                    else {
                        toast.error(response.data.statusMessage)
                        setOpen(false);
                    }
                    setLoading(false)

                }).catch(error => {
                    console.log("errors inside catch of rejection", error)
                })
            } else if (rejectpath.includes('/lifestyleproducts') || rejectpath.includes('/financialproducts')) {
                const passingValue = {
                    userId: user.userId,
                    productServiceId: Number(rejId),
                    status: "Rejected",
                    reason: reason
                }
                if (reason.trim()?.length > 0) {
                    rejectwithReasonsorApproval(passingValue).then((resultOfAPI) => {
                        if (resultOfAPI.data.statusMessage && resultOfAPI.data.status) {
                            toast.success(resultOfAPI.data.statusMessage)
                            taskAPICALL(req)
                        }
                        else {
                            toast.error(resultOfAPI.data.errorMessage)
                        }
                        setLoading(false)
                    })
                }
            } else if (rejectpath.includes('/financialservices') || rejectpath.includes('/lifestyleservices')) {
                const obj = {
                    userId: user?.userId,
                    status: "Rejected",
                    productServiceId: Number(rejId),
                    reason: reason
                }
                approveRejectProductsServices(obj).then(response => {
                    if (response.data.status === true) {
                        toast.success(response.data.statusMessage)
                        setOpen(false);
                    }
                    else {
                        toast.error(response.data.statusMessage)
                        setOpen(false);
                    }
                    setLoading(false)
                }).catch(error => {
                    console.log("errors inside catch of rejection", error)
                })
            } else if (rejectpath.includes('/vendorManagement')) {
                const passingValue = {
                    userId: user.userId,
                    vendorId: Number(rejId),
                    status: "Rejected",
                    reason: reason
                }
                if (reason.trim()?.length > 0) {
                    submitVendorRejection(passingValue).then((resultOfAPI) => {
                        if (resultOfAPI.data.statusMessage && resultOfAPI.data.status) {
                            toast.success(resultOfAPI.data.statusMessage)
                            taskAPICALL(req)
                        }
                        else {
                            toast.error(resultOfAPI.data.errorMessage)
                        }
                        setLoading(false)
                    })
                }
            } else if (rejectpath.includes('/clientprofile') || rejectpath.includes('/prospectprofile')) {
                if (profilestatus === "compliance-approve-or-reject" || profilestatus === 'Pending for Compliance Approval') {
                    let obj = { "userId": user.userId, "prospectId": Number(rejId), "status": "Rejected", "reason": reason }
                    compApproveorReject(obj).then(approveresponse => {
                        if (approveresponse?.data?.status) {
                            taskAPICALL(req)
                            toast.success(approveresponse?.data?.statusMessage)
                        } else {
                            toast.error(approveresponse?.data?.errorMessage)
                        }
                        setLoading(false)
                    })
                }
                if (profilestatus === "management-approve-or-reject" || profilestatus === 'Pending for Management Approval') {
                    let obj = { "userId": user.userId, "prospectId": Number(rejId), "status": "Rejected", "reason": reason }
                    console.log("approve obj", obj)
                    managApproveorReject(obj).then(approveresponse => {
                        if (approveresponse?.data?.status) {
                            taskAPICALL(req)
                            toast.success(approveresponse?.data?.statusMessage)
                        } else {
                            toast.error(approveresponse?.data?.errorMessage)
                        }
                        setLoading(false)
                    })
                }
            }
        }
        setOpen(false);
    }

    const handlefilter = (e) => {
        setPage(1)
        const keyword = e.target.value;
        const trimmedKeyword = keyword.trimStart();
        setSearch(trimmedKeyword)
    }

    // API Call to get the list of tasks
    const taskAPICALL = async (req) => {
        
        await getIndividualTaskPaginations(req).then((res) => {
            if (res?.data?.status) {
                if(!mountingFirst){
                    dispatch(updateAPI3({ user: btoa(decodedToken?.email), data: {...dashboardContent?.api3,totalCount:res?.data?.body?.task?.totalPages,list:res?.data?.body?.task?.notifications,} })); 
                }
            } 
        })
    }

    const listapi = async (req) => {
        if(!dashboardContent?.api1 && !dashboardContent?.api2 && !dashboardContent?.api3 && !dashboardContent?.api4){
            setLoading(true)
        }
       
        for (let [index, promise] of [{ ...req, sequence: 1 }, { ...req, sequence: 2 }, { ...req, sequence: 3 }, { ...req, sequence: 4 }].entries()) {
            try {
                const res = await getAdminDashboardDataWIthSection(promise);
                if (index === 0) {
                    if (res?.data?.status) {
                        // All available fields to filter:  "Invested Wealth" "Total AUM" "Total Client Net Worth"  "No. of Clients" "No. of RMs" "Total Partners/Vendor"
                        const cardsData = res?.data?.body?.cardData.filter(card => ["Total AUM", "Invested Wealth", "No. of Clients"].includes(card.header))
                     await   dispatch(updateAPI1({ user: btoa(decodedToken?.email), data: {cardList: cardsData,ttmInvestedWealthBarChart:res?.data?.body?.ttmInvestedWealthBarChart,ttmAggregateAumBarChart:res?.data?.body?.ttmAggregateAumBarChart} }));
                    }
                    setLoading(false)
                }
                if (index === 1) {
                    if (res?.data?.status) {
                        setInitialAPIResponnse({ ...initialAPIResponnse, revenueTotalPages: res?.data?.body?.revenueTotalPages, aumTotalPages: res?.data?.body?.aumTotalPages })
                       await dispatch(updateAPI2({ user: btoa(decodedToken?.email), data: {aggregateSectorAllocation:res?.data?.body?.sectorAssetAllocation,aggregateAssetAllocation:res?.data?.body?.aggregateAssetAllocation,geographicalAssetAllocation:res?.data?.body?.geographicalAssetAllocation,revenueLeaderboardLocalState:res?.data?.body?.revenueLeaderboard,aumLeaderboardLocalState:res?.data?.body?.aumLeaderboard,initialAPIResponnse:{...initialAPIResponnse, revenueTotalPages: res?.data?.body?.revenueTotalPages, aumTotalPages: res?.data?.body?.aumTotalPages}} }));
                    }
                    setLoading(false)
                }
                if (index === 2) {
                    if (res?.data?.status) {
                        setInitialAPIResponnseSecond({ ...initialAPIResponnseSecond, prospectTopNetworthTotalPages: res?.data?.body?.prospectTopNetworthTotalPages, clientTopNetworthTotalPages: res?.data?.body?.clientTopNetworthTotalPages })
                       await dispatch(updateAPI3({ user: btoa(decodedToken?.email), data: {initialAPIResponnseSecond:{ ...initialAPIResponnseSecond, prospectTopNetworthTotalPages: res?.data?.body?.prospectTopNetworthTotalPages, clientTopNetworthTotalPages: res?.data?.body?.clientTopNetworthTotalPages},totalCount:res?.data?.body?.task?.totalPages,list:res?.data?.body?.task?.notifications,prospectTopNetWorthLocalState:res?.data?.body?.prospectTopNetworth,clientTopNetWorthLocalState:res?.data?.body?.clientTopNetworth} }));
                    }
                    setLoading(false)
                }
                if (index === 3) {
                    if (res?.data?.status) {
                        dispatch(updateAPI4({ user: btoa(decodedToken?.email), data: {marketDetails:res?.data?.body?.marketNews?.marketNews,} }));
                    }
                    setLoading(false)
                }
            } catch (error) {
                console.log(error.message);
            }
        }
    }

    return (
        <>
            {isLoading && <Loader isLoading={isLoading} />}
            {/** Main layout */}
            <GridLayout 
                    search={search}  //search text 
                    columns={columns} //column for the Grid table
                    handlefilter={handlefilter} // filter method to change the state 
                    handlePageChange={handlePageChange} // handle page change for Server siide pagination
                />

            {/** Approval modal */}
            <Modal
                open={open}
                disableAutoFocus={true}
                disableEscapeKeyDown
                onClose={() => setOpen(false)}
            >
                <Box
                    style={{
                        position: 'absolute',
                        top: '45%',
                        backgroundColor: "white",
                        left: '45%',
                        transform: 'translate(-50%, -50%)',
                        minWidth: "40%",
                        borderRadius: "12px",
                        margin: "5%",
                        padding: "20px"
                    }}
                >
                    <Typography id="open-open-title" sx={{ fontSize: "18px", textAlign: "center", color: "#1e1e1e" }} >
                        Reason for rejection
                    </Typography>
                    <TextField
                        sx={{
                            minHeight: "50%", width: "100%",
                            marginBottom: 2,
                            paddingLeft: "50px", paddingRight: "50px",
                            '& input::placeholder': {
                                textOverflow: 'ellipsis !important',
                                color: '#363636',
                            },
                            '& .MuiInputBase-root': {
                                background: '#FFFFFF 0% 0%',
                                borderRadius: '8px',
                            },
                        }}
                        onChange={(e) => handleReasonChange(e)}
                        placeholder='Enter your comment here'
                        multiline
                        rows={4}
                    />
                    <Box style={{ textAlign: "center", mb: "30px", mt: "20px" }}>
                        {(status === "Pending for Compliance Approval" || status === "Pending for Management Approval") ? <Button style={{
                            width: "100px",
                            height: "35px",
                            borderRadius: "40px",
                            backgroundColor: "black",
                            color: "white",
                            fontSize: "14px"
                        }} variant="contained"
                            onClick={() => handleReasonSubmission(status === "Pending for Compliance Approval" ? "compliance-approve-or-reject" : "management-approve-or-reject")}>
                            Reject
                        </Button> : <Button style={{
                            width: "100px",
                            height: "35px",
                            borderRadius: "40px",
                            backgroundColor: "black",
                            color: "white",
                            fontSize: "14px"
                        }} variant="contained"
                            onClick={() => handleReasonSubmission('')}>
                            Reject
                        </Button>}

                        <Button style={{
                            marginLeft: 5,
                            width: "100px",
                            height: "35px",
                            borderRadius: "40px",
                            backgroundColor: "#FFFFFF",
                            color: "#1e1e1e",
                            fontSize: "14px"
                        }} variant="contained" onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal >
        </>

    )
}

export default Cards;
