import React, { useEffect, useState } from 'react'
import { Box, TextField, Grid, InputAdornment, Button, Paper, Select, MenuItem } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
import GridTab from "./GridTab"
import { getBillofMaterials, downloadBill } from "../../../services/index"
import { formatDate } from "../../common/Utils/DateFormat"
import { useFormik } from 'formik';
import { useSearchParams } from "react-router-dom"
import * as Yup from 'yup';
import { ReactComponent as Download } from '../../../theme/images/Download.svg';
import Loader from "../../common/Loader/Loader"
import PaginationComponent from '../../common/Pagination/Pagination';// eslint-disable-next-line 
import { formatNumber } from "../../../constant/DollorFormats"
import { getClientDropdown } from '../../../services/index'





// const rows = [
//     { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
//     { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
//     { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
//     { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
//     { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: 10 },
//     { id: 6, lastName: 'Melisandre', firstName: "new", age: 150 },
//     { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//     { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//     { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
//     { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: 10 },
//     { id: 6, lastName: 'Melisandre', firstName: "new", age: 150 },
//     { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//     { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//     { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ]

function BillofMaterials() {


    const [list, setList] = useState([])
    const user = JSON.parse(window.localStorage.getItem("user"))
    const userId = user?.userId
    const [search, setSearch] = useState("");        // eslint-disable-next-line
    const [totalValue, setTotalValue] = useState("")
    const [searchParams, setsearchParams] = useSearchParams();// eslint-disable-next-line
    const [page, setPage] = useState(parseInt(searchParams.get('pageNumber')) || 1);// eslint-disable-next-line
    const [searchVal, setSearchVal] = useState("")// eslint-disable-next-line
    const [rowsPerPage, setRowsPerPage] = useState(parseInt(searchParams.get('ListCount')) || 10);// eslint-disable-next-line
    const [isLoading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0)
    const [isFocusedFrom, setIsFocusedFrom] = useState(false);
    const [isFocusedTo, setIsFocusedTo] = useState(false);
    const isSuperAdmin = JSON.parse(localStorage.getItem("user"))?.userTypeId?.description === "Kairos Admin" ? true : false;
    // const entityValues = [{ id: 1, description: "Arrow" }, { id: 2, description: "Arrow 2" }]
    const [filterEntity, setFilterEntity] = useState(atob(localStorage.getItem("entityId")));
    console.log(filterEntity, "fillll")// eslint-disable-next-line
    const [options, setOptions] = useState([]);// eslint-disable-next-line
    const [entityValues, setEntityValues] = useState([]);
    // const dispatch = useDispatch();
    // const fromDate = useSelector((state) => state.nav.fromDate)
    // const toDate = useSelector((state) => state.nav.toDate)
    // const [pageChange, setPageChange] = useState(false)
    const validationSchema = Yup.object().shape({
        fromDate: Yup.date().required('From Date is required'),
        toDate: Yup.date()
            .min(Yup.ref('fromDate'), 'To Date must be after From Date'),


    });


    const formik = useFormik({
        initialValues: {
            fromDate: null,
            toDate: null

        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values, "valllllllllllllllllllllllll")
            setPage(1)
            const obj = {

                "loginUserId": userId,
                "pageNo": 0,
                "pageSize": rowsPerPage,
                "search": search,
                "fromDate": formik?.values?.fromDate,
                "toDate": formik?.values?.toDate

            }
            setPage(1)
            setsearchParams({ pageNumber: 1, ListCount: rowsPerPage })
            setLoading(true)
            getBillofMaterials(obj).then(res => {
                console.log(res, "resssssssssss")
                if (res?.data?.status) {
                    setList(res?.data?.body?.billOfMaterials)
                    setTotalValue(res?.data?.body?.totalValue)
                    setTotalCount(res?.data?.totalPages)

                } else {
                    setList([])
                    setTotalValue("")
                    setTotalCount(0)

                }
                setLoading(false)

            })



        }
    })


    useEffect(() => {

        getClientDropdown().then((response) => {
            if (response?.data?.status === true) {
                setOptions(response?.data?.body?.usertype)

                if (JSON.parse(localStorage.getItem("user"))?.userTypeId?.description === "Kairos Admin") {
                    setEntityValues(response?.data?.body?.entity)
                    setFilterEntity(response?.data?.body?.entity[0]?.id)
                    localStorage.setItem("entityId", btoa(response?.data?.body?.entity[0]?.id))
                }

            } else {
                setOptions([])
                setEntityValues([])
            }
            // setisLoading(false);
        })
    }, [])
    const initialMountCall = async (check) => {
        console.log(atob(localStorage.getItem("entityID")), "btttttttttt")
        let pageCountd;
        if (searchParams.get('ListCount') && !check) {
            console.log("enter")

            pageCountd = searchParams.get('pageNumber');
            setsearchParams({ pageNumber: searchParams.get('pageNumber'), ListCount: 10 })
        }
        else {                   // eslint-disable-next-line
            pageCountd = 0;// eslint-disable-next-line
            setsearchParams({ pageNumber: 1, ListCount: 10 })
        }
        if (!check) {
            console.log("enter3333333")

            // setisLoading(true);
            getClientDropdown().then((response) => {
                if (response?.data?.status === true) {
                    setOptions(response?.data?.body?.usertype)

                    if (JSON.parse(localStorage.getItem("user"))?.userTypeId?.description === "Kairos Admin") {
                        setEntityValues(response?.data?.body?.entity)
                        setFilterEntity(response?.data?.body?.entity[0]?.id)
                        localStorage.setItem("entityId", btoa(response?.data?.body?.entity[0]?.id))
                    }

                } else {
                    setOptions([])
                    setEntityValues([])
                }
                // setisLoading(false);
            })
        }

        // !check && let dropDownListDataForFilter = await vendormanagementDropDownList();
        // !check && const listOfData = dropDownListDataForFilter[0]?.data?.body;
        // !check && setentityValues(listOfData?.entity);
        // !check && setFilterEntity(listOfData?.entity[0]?.id)
        // !check && localStorage.setItem("entityId", btoa(listOfData?.entity[0]?.id))

        setPage(1)
        // setLoad(!load)
        // setisLoading(true);

        const obj = {
            "loginUserId": userId,
            "pageNo": page - 1,
            "pageSize": rowsPerPage,
            "search": search,
            // "fromDate": moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
            // "toDate": moment(new Date()).format('YYYY-MM-DD')
            fromDate: formik?.values?.fromDate,
            toDate: formik?.values?.toDate


        }
        setLoading(true)

        getBillofMaterials(obj).then(res => {
            console.log(res, "resssssssssss")
            if (res?.data?.status) {
                setList(res?.data?.body?.billOfMaterials)
                setTotalValue(res?.data?.body?.totalValue)
                setTotalCount(res?.data?.totalPages)

            } else {
                setList([])
                setTotalValue("")
                setTotalCount(0)


            }
            setLoading(false)

        })// eslint-disable-next-line        

    }

    useEffect(() => {

        const obj = {
            "loginUserId": userId,
            "pageNo": page - 1,
            "pageSize": rowsPerPage,
            "search": search,
            // "fromDate": moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
            // "toDate": moment(new Date()).format('YYYY-MM-DD')
            fromDate: formik?.values?.fromDate,
            toDate: formik?.values?.toDate


        }
        setLoading(true)

        getBillofMaterials(obj).then(res => {
            if (res?.data?.status) {
                setList(res?.data?.body?.billOfMaterials)
                setTotalValue(res?.data?.body?.totalValue)
                setTotalCount(res?.data?.totalPages)

            } else {
                setList([])
                setTotalValue("")
                setTotalCount(0)


            }
            setLoading(false)

        })// eslint-disable-next-line        
        // eslint-disable-next-line
    }, [page, filterEntity])


    useEffect(() => {
        (async () => {
            await initialMountCall(false);

        })();// eslint-disable-next-line
    }, [search])


    const columns = [
        {
            field: 'productName',
            headerName: 'Product Name',
            width: 150,
        },
        {
            field: 'productCode',
            headerName: 'Product Code',
            width: 110,
        },
        {
            field: 'hniId',
            headerName: 'Client ID',
            renderCell: (params) => (
                <>
                    {params.value?.hniId}
                </>
            ),
            width: 80,

        },
        {
            field: 'hniName',
            headerName: 'Client Name',
            renderCell: (params) => (
                <>

                    {params.row.hniId?.hniName}                </>
            ),
            width: 150,

        },
        {
            field: 'dateOfPurchase',
            headerName: 'Date of Purchase',
            renderCell: (params) => (
                <>
                    {formatDate(params.value, 'DD-MMM-YYYY')}
                </>
            ),
            width: 132,


        },

        {
            field: 'transactionId',
            headerName: 'Transaction ID',
            width: 112,

        },
        // {
        //     field: 'age',
        //     headerName: 'Take Rate %',

        // },
        {
            field: 'shippingCharges',
            headerName: 'Shipping Charges ($)',
            width: 156,


            renderCell: (params) => (
                <>
                    {`US $${formatNumber(params.value)}`}
                </>
            ),

        }, {
            field: 'purchaseValue',
            headerName: 'Purchase Value ($)',
            width: 140,
            renderCell: (params) => (
                <>
                    {`US $${formatNumber(params.value)}`}
                </>
            ),
        }, {
            field: 'settlementAmount',
            headerName: 'Settlement Amount ($)',
            width: 170,
            renderCell: (params) => (
                <>
                    {`US $${formatNumber(params.value)}`}
                </>
            ),
        },

    ]

    const handlefilter = (e) => {
        setPage(1)
        const keyword = e.target.value;
        setSearchVal(e.target.value)
        const trimmedKeyword = keyword.trimStart();

        setSearch(trimmedKeyword)

    };
    const handleDownload = async () => {
        setLoading(true);
        const response = await downloadBill({
            "loginUserId": userId,
            "search": search,
            "fromDate": formik?.values?.fromDate,
            "toDate": formik?.values?.toDate
        })
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Bill of Materials.xls');
        document.body.appendChild(link);
        link.click();
        setLoading(false);
    }
    const handlePageChange = async (e, value) => {
        setPage(value)
        setsearchParams({ pageNumber: value, ListCount: rowsPerPage })

        // setsearchParams({ pageNumber: value, ListCount: rowsPerPage })
        // const filteredArray = options.filter(val => val.id === selectedOptions)
        // const [filterValue] = filteredArray;
        const obj = {

            "loginUserId": userId,
            "pageNo": value - 1,
            "pageSize": rowsPerPage,
            "search": search,
            // "fromDate": moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
            // "toDate": moment(new Date()).format('YYYY-MM-DD')
            "fromDate": formik?.values?.fromDate,
            "toDate": formik?.values?.toDate


        }
        setLoading(true)
        getBillofMaterials(obj).then(res => {
            console.log(res, "resssssssssss")
            if (res?.data?.status) {
                setList(res?.data?.body?.billOfMaterials)
                setTotalValue(res?.data?.body?.totalValue)
                setTotalCount(res?.data?.totalPages)

            } else {
                setList([])
                setTotalValue("")
                setTotalCount(0)


            }
            setLoading(false)

        })
        // loadData(listObj)
    }

    const handleFocusFrom = () => {
        setIsFocusedFrom(true);
    };

    const handleBlurFrom = () => {
        setIsFocusedFrom(false);
    };
    const handleFocusTo = () => {
        setIsFocusedTo(true);
    };

    const handleBlurTo = () => {
        setIsFocusedTo(false);
    };
    const handleSelectEntityAndLocalStorage = (e) => {
        localStorage.setItem("entityId", btoa(e.target.value));

        handleSelectEntity(e);

    };
    const handleSelectEntity = async (e) => {

        setFilterEntity(e.target.value)
        await initialMountCall(true);
    }
    return (
        <>
            {isLoading && <Loader isLoading={isLoading} />}

            <Box sx={{ backgroundColor: '#FFFFFF', border: '1px solid #EAEAEA', borderRadius: '8px', width: 'auto', marginRight: '10px', boxShadow: '0.47px 3px 10px #7777771A', marginBottom: "20px", height: "80vh" }}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container sx={{ padding: '20px 20px', display: "flex", gap: "20px", alignItems: "center" }}>

                        <Grid item xs={12} lg={3} md={3}>
                            <TextField
                                variant="outlined"
                                type="text"
                                // value={searchVal}
                                // value=""
                                placeholder={"Search"}
                                // onChange={(e) => setValuesForSearch(e.target.value)}
                                onChange={(e) => handlefilter(e)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                }}
                                sx={{
                                    // width: '320px',
                                    '& input::placeholder': {
                                        fontFamily: "Mona Sans",
                                        textOverflow: 'ellipsis !important',
                                        color: '#BBC5D5 !important',
                                        opacity: 1,
                                    },
                                    height: '40px',
                                    width: "100%",
                                    '& .MuiInputBase-root': {
                                        height: '40px !important',
                                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                                        borderRadius: '8px',

                                    },
                                }}
                            />

                        </Grid>
                        {isSuperAdmin && <Grid item xs={12} lg={2} md={5}>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={filterEntity}
                                sx={{

                                    width: "100%",
                                    height: "40px",

                                    // width: '320px',
                                    '& input::placeholder': {
                                        textOverflow: 'ellipsis !important',
                                        color: '#363636 !important',
                                        opacity: 1,
                                    },
                                    '& .MuiInputBase-root': {
                                        height: '40px !important',
                                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                                        borderRadius: '8px',

                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        // height: '40px !important',

                                        borderRadius: '8px',
                                    },

                                }}
                                onChange={(e) => {
                                    handleSelectEntityAndLocalStorage(e)

                                }}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {console.log(entityValues, "entity")}
                                {entityValues?.map((option) => (
                                    <MenuItem key={option?.id} value={option?.id} >{option?.name}</MenuItem>))}


                            </Select>
                        </Grid>}

                        <p style={{ fontFamily: "Mona Sans" }}>Filter by</p>

                        <Grid item xs={1.7} sx={{ position: 'relative', marginLeft: "-15px" }}>
                            {/* <Typography variant="h6" style={{ color: '#FFFFFF' }}>
                        {'From Date'}
                    </Typography> */}
                            <TextField
                                id='fromDate'
                                name='fromDate'
                                type="date"
                                onFocus={handleFocusFrom}
                                onBlur={handleBlurFrom}
                                onChange={
                                    formik.handleChange
                                    // (e) => {
                                    //     formik?.setFieldValue("fromDate", e.target.value)
                                    //     dispatch(setFromDate(e.target.value))
                                    //     console.log(e.target.value, "frommmmmmmmmmm")
                                    // }
                                }
                                value={formik.values.fromDate}
                                sx={{
                                    width: "100%",
                                    colorScheme: "dark",
                                    // gridColumn: "span 2",
                                    // height: '30px',
                                    color: '#FFFFFF !important',
                                    backgroundColor: 'transparent',
                                    '& input[value=""]:not(:focus)': {
                                        color: 'transparent',
                                    },
                                    '& input::placeholder': {
                                        textOverflow: 'ellipsis !important',
                                        color: '#FF0000 !important', // Change the color value here
                                        opacity: 1,
                                        content: "'from date'",


                                    },
                                    '& .MuiInputBase-root': {
                                        height: '38px !important',
                                        // maxWidth: "auto",
                                        color: '#FFFFFF !important',
                                        // minWidth: "250px",
                                        background: 'black 0% 0% no-repeat padding-box',
                                        borderRadius: "6px",
                                        // filter: brightness(100%) saturate(0%) invert(100%);


                                    },


                                }}


                                InputProps={{ endAdornment: null }}
                            />


                            {!isFocusedFrom && !formik.values.fromDate && (
                                <label
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '12px',
                                        transform: 'translateY(-50%)',
                                        color: 'white',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    From Date
                                </label>
                            )}
                        </Grid>
                        <Grid item xs={1.7} sx={{ position: 'relative', }}>
                            {/* <Typography variant="h6" style={{ color: '#FFFFFF' }}>
                        {'To Date'}
                    </Typography> */}
                            <TextField
                                id='toDate'
                                name='toDate'
                                type="date"
                                onFocus={handleFocusTo}
                                onBlur={handleBlurTo}
                                onChange={
                                    formik.handleChange
                                    // (e) => {
                                    //     formik?.setFieldValue("toDate", e.target.value)
                                    //     dispatch(setToDate(e.target.value))
                                    //     console.log(e.target.value, "toooooo")
                                    // }
                                }
                                value={formik.values.toDate}
                                // inputProps={{
                                //     min: new Date().toISOString()?.split("T")[0]
                                // }}
                                // defaultValue={moment(new Date()).format('yyyy-mm-dd')}
                                sx={{
                                    width: "100%",
                                    // gridColumn: "span 2",
                                    // height: '40px',
                                    color: '#FFFFFF',
                                    colorScheme: "dark",
                                    backgroundColor: 'transparent',
                                    '& input[value=""]:not(:focus)': {
                                        color: 'transparent',
                                    },
                                    '& input::placeholder': {
                                        textOverflow: 'ellipsis !important',
                                        color: '#FF0000 !important', // Change the color value here
                                        opacity: 1,
                                    },
                                    '& .MuiInputBase-root': {
                                        height: '38px !important',
                                        maxWidth: "auto",
                                        color: '#FFFFFF !important',

                                        // color: 'white ',
                                        // color: "transparent",
                                        width: "100% !important",
                                        // minWidth: "250px",
                                        background: 'black 0% 0% no-repeat padding-box',
                                        borderRadius: "6px"

                                    },


                                }}



                            />
                            {!isFocusedTo && !formik.values.toDate && (
                                <label
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '12px',
                                        transform: 'translateY(-50%)',
                                        color: 'white',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    To Date
                                </label>
                            )}
                            {/* <FormHelperText error={formik.errors.toDate ? true : false} id="hniValue-helper">{formik.touched.toDate && formik.errors.toDate ? formik.errors.toDate : null}</FormHelperText> */}

                        </Grid>

                        <Grid item xs={1}>
                            <Button type="submit" sx={{
                                fontFamily: "Mona Sans",
                                backgroundColor: "#6A3BDE", fontSize: "13px", borderRadius: "6px", minHeight: '38px', color: "white", width: "80px", opacity: (Object.keys(formik?.errors).length === 0) && (formik?.values?.fromDate !== null && formik?.values?.toDate !== null) ? 1 : 0.3, "&:hover": {
                                    backgroundColor: "#6A3BDE"
                                },
                                cursor: (Object.keys(formik?.errors).length === 0) && (formik?.values?.fromDate !== null && formik?.values?.toDate !== null) ? "pointer" : "default"

                            }}>Apply</Button>
                        </Grid>


                        <Grid item xs={12} lg={0.5} md={5} sx={{ marginLeft: "15px" }}>
                            <Paper variant="outlined" sx={{ width: 40, height: "40px", textAlign: "center", borderRadius: "8px", }} >
                                <Download onClick={handleDownload} style={{ textAlign: "center", marginTop: "5px",cursor: "pointer" }} data-testid="download-icon" />
                            </Paper>

                        </Grid>
                    </Grid>
                </form>


                <GridTab columns={columns} rows={list} data={"height"} totalValue={totalValue} />
                <div style={{ dispply: "flex", alignItems: "end", justifyContent: "end", textAlign: "end", margin: '3px', marginTop: "8vh" }}>
                    <PaginationComponent handlePageChange={handlePageChange} page={page} totalCount={totalCount} size={"small"} />
                </div>
            </Box >
        </>
    )
}

export default BillofMaterials