import { FormModalProducts } from '../FormModal/FormModals';// eslint-disable-next-line
import { productIDVALUE } from "../../../../../services/productManagement"

const {
    formFieldProducts: {
        vendorId,
        productServiceId, productServiceSymbol,
        productServiceName,
        productServiceDescription,
        productServiceTypeId,
        primaryCategoryId,
        secondaryCategoryId,
        startDate,
        endDate,
        returnsLevel,
        riskLevel,
        liquidity,
        hnisInvested,
        taxStatus,
        tags,
        regionId,
        investmentTerm,
        takeRate,
        //  currencyTypeId,
        takeRateParameterId,
        value,
        actualPerQuantityPrice,
        sellingPrice,
        inBuild,
        emailCheck,
        orderEmail,
        apiUrl,
        token, taxRate, purchaseHappenThroughStripe


    }
} = FormModalProducts;

export const fieldValuesProduct = {
    [vendorId.name]: '',
    [productServiceId.name]: null,
    [productServiceSymbol.name]: null,
    [productServiceName.name]: '',
    [productServiceDescription.name]: '',
    [productServiceTypeId.name]: '',
    [primaryCategoryId.name]: '',
    [secondaryCategoryId.name]: '',
    [takeRate.name]: '',
    [takeRateParameterId.name]: '',
    [startDate.name]: '',
    [endDate.name]: '',
    [investmentTerm.name]: '',
    [value.name]: '',
    [returnsLevel.name]: '',
    [riskLevel.name]: '',
    [liquidity.name]: '',
    [sellingPrice.name]: '',
    // [currencyTypeId.name]: '233',
    [hnisInvested.name]: '',
    [taxStatus.name]: '',
    [tags.name]: [],
    [regionId.name]: '',
    [actualPerQuantityPrice.name]: null,
    [inBuild.name]: true,
    [emailCheck.name]: true,
    [orderEmail.name]: "",
    [apiUrl.name]: '',
    [token.name]: '',
    [taxRate.name]: '',
    [purchaseHappenThroughStripe.name]: false


};

// export const fieldValuesVendorEdit = {

//     [vendorId.name]: productIDVALUE?.productIDDetails?.vendorId,
//     [productServiceId.name]: productIDVALUE?.productIDDetails?.productServiceId,
//     [productServiceName.name]: productIDVALUE?.productIDDetails?.productServiceName,
//     [productServiceDescription.name]: productIDVALUE?.productIDDetails?.productServiceDescription,
//     [vendorSubtypeId.name]: productIDVALUE?.productIDDetails?.vendorSubtypeId?.id,
//     [businessIdentityNo.name]: productIDVALUE?.productIDDetails?.businessIdentityNo,
//     [taxId.name]: productIDVALUE?.productIDDetails?.taxId,
//     [takeRate.name]: productIDVALUE?.productIDDetails?.takeRate,
//     [takeRateParameterId.name]: productIDVALUE?.productIDDetails?.takeRateParameterId?.id,
//     [emailId.name]: productIDVALUE?.productIDDetails?.emailId,
//     [keyPointOfContact.name]: productIDVALUE?.productIDDetails?.keyPointOfContact,
//     [keyPocPrefix.name]: productIDVALUE?.productIDDetails?.keyPocPrefix?.id,
//     [contactDetails.name]: productIDVALUE?.productIDDetails?.contactDetails,
//     [countryPrifixId.name]: productIDVALUE?.productIDDetails?.countryPrifixId?.id,
//     [registeredAddress.name]: productIDVALUE?.productIDDetails?.registeredAddress,
//     [city.name]: productIDVALUE?.productIDDetails?.city,
//     [state.name]: productIDVALUE?.productIDDetails?.state,
//     [country.name]: productIDVALUE?.productIDDetails?.country,
//     [zip.name]: productIDVALUE?.productIDDetails?.zip,


// };


