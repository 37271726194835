import React from 'react'
import { Button, Box, Divider, Grid, Typography } from "@mui/material"
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom"
import { setBookmark, setBookmarkMessage, setClose, setNotificationClick, setReceivedNotification, setNotificationCount, setPage } from '../../../redux/slices/ChatGptSlice'
import { ReactComponent as Bookmarkoutline } from '../../../theme/images/Bookmark outline.svg'
import ClearIcon from '@mui/icons-material/Clear';
import { getRmNotificationList } from "../../../services/index"
//import colorKairos from "../../../theme/images/GuestKairos Logo.svg";

const BookMarkList = () => {
    const bookmarkList = useSelector((state) => state?.chatGpt?.bookmarkList)
    const close = useSelector((state) => state.chatGpt.close)
    // const notificationClick = useSelector((state) => state.chatGpt.notificationClick)
    const user = JSON.parse(localStorage.getItem("user"));
    const notificationCount = useSelector((state) => state.chatGpt.notificationCount)
    const targetValue = useSelector((state) => state.nav.targetValue)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    console.log(targetValue, 'targetValue')

    const handleClick = () => {
        dispatch(setPage(0))
        if (targetValue === 'llm') {
            navigate("/kairosbot/history")
        } else {
            navigate("/chatgpt/history")

        }
        dispatch(setBookmark(true))
    }
    const handleNotificationClick = () => {
        if (user?.userTypeId?.description !== "Entity RM") {
            getRmNotificationList(user?.userId, targetValue).then((res) => {
                if (res?.data?.status) {
                    dispatch(setReceivedNotification(res?.data?.body))
                    dispatch(setNotificationCount(0))
                }

            })
        }
        dispatch(setNotificationClick(true))

        // dispatch(setBookmark(true))
        // navigate("/chatgpt/notification")
    }

    const handleBookmark = (list, index) => {
        dispatch(setBookmarkMessage(list?.question))
        dispatch(setClose(list?.chatGptId))
        // bookmarkList.map((val) => {
        //     if (val.chatGptConfigId === list.chatGptConfigId) {
        //         dispatch(setClose(true))

        //     }
        // })
    }

    const handleClose = () => {
        dispatch(setBookmarkMessage(''))
        dispatch(setClose(''))
    }

    return (<>

        <Typography sx={{ display: { xs: "none", sm: "block" }, fontSize: "22px", fontWeight: "550", marginBottom: "20px", marginInline: "auto", cursor: "pointer !important", width: "40px", height: "auto" }} >
            <img src={user?.entityLogo} alt="logoIcon" sx={{ cursor: "pointer !important", display: { xs: "none", sm: "block" } }} onClick={() => { navigate("/dashboard") }} />
        </Typography>

        <Typography pr={2} pl={2} sx={{ color: "#8E8EA0", fontSize: "20px", marginTop: "0px !important", marginBottom: "16px", marginInline: "auto" }}>BookMark</Typography>
        {bookmarkList?.map((list) => {
            return (<Box key={list?.chatGptId} className="navList" sx={{ color: '#FFFFFF' }}>
                <Grid container pr={2} pl={2}>

                    <Grid item xl={2} lg={2} sm={2} md={2} pt={0} pr={1}>
                        {(close !== list?.chatGptId) ? <Bookmarkoutline onClick={() => handleBookmark(list)} /> :
                            <ClearIcon onClick={() => handleClose()} />
                        }
                        {/* <Typography className="icon"><img src={button.icon} alt="" style={{ width: "15px", height: "15px", filter: button.path === Button?.path ? "invert(1)" : "invert(0)" }} /></Typography> */}
                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={10} pt={0.2}>
                        {<Typography pt={0} style={{
                            fontSize: "12px", textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: '150px',
                        }} title={list?.question}
                            onClick={() => (close !== list?.chatGptId) ? handleBookmark(list) : handleClose()}
                        >{list?.question}</Typography>}
                    </Grid>



                </Grid>
            </Box>)


        })
        }
        <Divider style={{
            width: '100%',
            position: 'absolute',
            top: '85vh',
            border: '0.7px solid #4C4C4C'

        }} />

        <Grid xs={10}>
            <Button variant='contained' sx={{
                width: '180px',
                position: 'absolute',
                top: '87vh',
                left: '16px',
                background: '#6442C4'
            }} onClick={handleClick}>History</Button></Grid>


        <Grid xs={10}>
            <Button variant='outlined' sx={{
                width: '180px',
                position: 'absolute',
                top: '93vh',
                left: '16px',
                color: "white",
                border: "2px solid #6442C4",
                // backgroundColor: notificationCount > 0 ? 'transparent' : 'gray', 
                // background: '#6442C4'
            }}
                // disabled={notificationCount > 0 ? false: true }
                onClick={handleNotificationClick}>{user?.userTypeId?.description === "Entity RM" ? "Notification Sent" : "Notification"}{user?.userTypeId?.description !== "Entity RM" && <span style={{
                    color: "white", display: notificationCount > 0 ? "inline-block" : "none",
                    width: "20px",
                    height: "20px",
                    borderRadius: " 50%",
                    backgroundColor: "red",
                    textAlign: "center",
                    lineHeight: "22px",
                    fontSize: "15px",
                    marginLeft: "8px"
                }}>{user?.userTypeId?.description !== "Entity RM" ? notificationCount : ""}</span>}</Button></Grid>
    </>




    )

};
export default BookMarkList;