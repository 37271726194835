import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormControl from '@mui/material/FormControl';
import { Select, MenuItem, Typography } from '@mui/material';
import { toast } from 'react-toastify';

// import 'react-toastify/dist/ReactToastify.css';
// import { result } from 'lodash';

import "./guestUserLogin.css";
import Loader from '../../common/Loader/Loader';
import '../../../theme/styles/globalStyles.css';
import { GetCountries, SendSms } from '../../../services/guestUserServices';
// import InputAdornment from "@mui/material/InputAdornment";

const validationSchema = yup.object({

    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),

    mobileNumber: yup
        .string('Enter your password')
        .required('Mobile Number is required')
        .matches(/^[0-9]{7,15}$/, "Mobile number is not valid"),
    firstName: yup
        .string('Enter your password')
        .required('First Name  is required'),
    lastName: yup
        .string('Enter your password')
        .required('Last Name  is required'),
});


const GuestLogin = () => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();
    const isLoading = useSelector((state) => state?.auth.loading);
    const [Country, setCountry] = useState([])
    const [phoneCodes, setPhoneCodes] = useState([])

    useEffect(() => {
        GetCountries().then((res) => {
            if (res?.data?.status) {
                setCountry(res?.data?.body?.countries)
                setPhoneCodes(res?.data?.body?.phoneCodes)
            }
        })
    }, [])
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            mobileNumber: '',
            currencyCode: 232
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            // setFlag(true)
            let otpValue = '';
            const digits = '0123456789';
            let phoneCodes = Country?.find(i => i.id === values.currencyCode)?.phoneCode
            for (let i = 0; i < 6; i++) {
                const randomIndex = Math.floor(Math.random() * digits.length);
                otpValue += digits[randomIndex];
            }
            const guestUserobj = {

                mobile: '+' + phoneCodes + values.mobileNumber,

                name: values.firstName + " " + values.lastName,

                message: `Welcome to Kairos this is your one time password ${otpValue}`,

                subject: 'one time password'
            }


            const guestData = {
                otpValue: otpValue,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                mobileNumber: values.mobileNumber,
                currencyCode: values.currencyCode,
                date: new Date()
            }
            const res = await SendSms(guestUserobj)
            console.log(res, 'res')
            if (res?.data?.status) {
                localStorage.setItem('guestUser', JSON.stringify(guestData));
                toast.success(res?.data?.body)
                navigate('/verify', { state: { email: values.email, mobile: values.mobileNumber, otp: otpValue, guestUser: true } })
            }
            // }


        },
    });
    //eslint-disable-next-line
    function countryCodeNumber(selectVal) {
        console.log(Country, 'Countries')
        let phoneCodes = Country?.filter(i => i.id === selectVal)
        console.log(phoneCodes, "phoneCode")
        if (phoneCodes.length > 0) {
            return "+" + phoneCodes[0]?.phoneCode
        }
        else {
            return null
        }

    }

    console.log("formik", formik)
    // const theme = useTheme();
    // const colors = tokens(theme.palette.mode);
    return (
        <div>
            {isLoading && <Loader isLoading={isLoading} />}
            <Box
                sx={{
                    width: 300,
                    height: 'auto',
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "7%"
                }}>
                <form onSubmit={formik.handleSubmit}>
                    <section className='form-copy' style={{ textAlign: "left", marginBottom: "25px", color: matches ? "white" : null }}>
                        <h2>Guest User Details</h2>
                    </section>
                    <div className='input-container email'>
                        <TextField
                            size="small"
                            fullWidth
                            id="firstName"
                            name="firstName"
                            // label="First Name"
                            label={
                                <Typography variant="inherit">
                                    First Name<span style={{ color: 'red' }}>*</span>
                                </Typography>
                            }

                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                            sx={{
                                marginBottom: "10%",
                                borderColor: matches ? "white" : "black",

                                '& input::placeholder': {
                                    textOverflow: 'ellipsis !important',
                                    color: matches ? "black !important" : "",
                                    opacity: 1,
                                },

                                '& .MuiInputBase-root': {
                                    height: '40px !important',
                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                    borderRadius: '8px',

                                },

                            }}

                        />
                        <TextField
                            size="small"
                            fullWidth
                            id="lastName"
                            name="lastName"
                            // label="Last Name"
                            label={
                                <Typography variant="inherit" >
                                    Last Name<span style={{ color: 'red' }}>*</span>
                                </Typography>
                            }
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                            sx={{
                                marginBottom: "10%",
                                borderColor: matches ? "white" : "black",

                                '& input::placeholder': {
                                    textOverflow: 'ellipsis !important',
                                    color: matches ? "black !important" : "",
                                    opacity: 1,
                                },

                                '& .MuiInputBase-root': {
                                    height: '40px !important',
                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                    borderRadius: '8px',

                                },

                            }}

                        />
                        <TextField
                            size="small"
                            type='email'
                            fullWidth
                            id="email"
                            name="email"
                            // label="Email address"
                            // sx={{color: matches ? '#white':'#6E6E6E'}}
                            label={
                                <Typography variant="inherit" >
                                    Email address<span style={{ color: 'red' }}>*</span>
                                </Typography>
                            }
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            sx={{
                                marginBottom: "10%",
                                borderColor: matches ? "white" : "black",

                                '& input::placeholder': {
                                    textOverflow: 'ellipsis !important',
                                    color: matches ? "black !important" : "",
                                    opacity: 1,
                                },

                                '& .MuiInputBase-root': {
                                    height: '40px !important',
                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                    borderRadius: '8px',

                                },

                            }}

                        />
                    </div>
                    <Box sx={{ display: "flex", flexDirection: "row", height: 50 }}>
                        <FormControl size="small">
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                name='currencyCode'
                                value={formik.values.currencyCode}
                                onChange={formik.handleChange}
                                className='subTitle'
                                sx={{
                                    height: '40px',
                                    iconFilled: "filled",
                                    borderColor: matches ? "white" : "black",
                                    "& input::placeholder": {
                                        textOverflow: "ellipsis !important",
                                        color: matches ? "black !important" : "",
                                        opacity: 1
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderRadius: "8px 0 0 8px"
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        //  backgroundColor: '#EAEAEA !important',
                                        paddingRight: "75px !important",
                                        fontSize: "11px !important",
                                        overflow: "inherit"
                                    },
                                    '& .MuiSelect-select ': {
                                        overflow: "inherit !important",
                                    }
                                }}
                            >
                                <MenuItem selected disabled value="">
                                    <em>Select</em>
                                </MenuItem>
                                {phoneCodes && phoneCodes?.map((item, index) => (
                                    <MenuItem key={item?.id} value={item?.id}>
                                        +{item?.phoneCode}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            size="small"
                            fullWidth
                            id="mobileNumber"
                            name="mobileNumber"
                            label={
                                <Typography variant="inherit">
                                    Mobile<span style={{ color: 'red' }}>*</span>
                                </Typography>
                            }
                            type='number'
                            inputProps={{ min: 0 }}
                            value={formik.values.mobileNumber}
                            onChange={formik.handleChange}
                            error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                            sx={{
                                marginBottom: "10%",
                                '& input::placeholder': {
                                    textOverflow: 'ellipsis !important',
                                    color: '#363636',
                                    opacity: 1,
                                },
                                '& .MuiInputBase-root': {
                                    height: '40px !important',
                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                    borderRadius: "0px 8px 8px 0px",
                                },
                            }}
                            onKeyPress={(e) => {
                                if (e.key === '-') {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Box>
                    {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                        <Typography variant="caption" sx={{ color: 'red', marginLeft: '10px' }}>
                            {formik.errors.mobileNumber}
                        </Typography>
                    )}

                    <div style={{ display: 'flex', marginBottom: '30px' }}>
                        <Button
                            // disabled={!(formik.isValid && formik.dirty)}
                            sx={{
                                color: matches ? "black !important" : "white",
                                // borderColor: matches ? "white" : "black",
                                border: matches ? "1px solid white !important" : "1px solid #767676 !important",
                                '& .Mui-disabled': {
                                    color: 'white !important',

                                },

                            }}
                            // disabled={!formik.isValid}
                            // disabled={( formik.errors.firstName && formik.errors.lastName && formik.errors.email && formik.errors.mobileNumber) ? true : false} 
                            className='GenarateButton'
                            variant="contained"
                            type="submit" >
                            Genarate Otp
                        </Button>
                        <Button sx={{
                            color: matches ? "white !important" : "#767676 !important",
                            border: matches ? "1px solid white !important" : "1px solid #767676 !important",
                        }} className='cancelButton' variant="contained" type="submit"  >
                            Cancel
                        </Button>
                    </div>
                </form>
            </Box>
        </div >
    )
}

export default GuestLogin