
import Grid from '@mui/system/Unstable_Grid';
import { Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import image from "../theme/images/Mobile-BG.png"
import imageBG from "../theme/images/BG2.png"
import { CssBaseline, Link, ThemeProvider, Typography, IconButton } from "@mui/material";
import KairosWithLabel from "../theme/images/Klogowhite.svg"
import { ColorModeContext, useMode } from "../theme";
import { PublicRouteConfig } from "./publiceRoutesConfig"
import { Instagram, LinkedIn } from '@mui/icons-material';
import "./PublicRoute.css"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


export function PublicRoute() {
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const myStyle = {
        backgroundRepeat: "no-repeat",
        backgroundColor: "black",
        width: windowSize[0],
        height: windowSize[1],
        padding: "20px",
        backgroundImage: windowSize[0] >= 750 ? `url(${imageBG})` : `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        overflowY: windowSize[1] <= 700 ? "auto" : "none",
        filter: "brightness(0.9)",
    };



    const [theme, colorMode] = useMode();
    const themes = useTheme();
    const isMd = useMediaQuery(themes.breakpoints.up('md'));
    const isSm = useMediaQuery(themes.breakpoints.up('sm'));
    const currentYear = new Date();



    return (
        <>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />

                    <Grid style={myStyle} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" height="100%">

                        {/** Logo on top */}
                        <Typography style={{ color: "white", fontSize: "56px" }}>
                            <Link href="https://kairoswealth.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', cursor: 'pointer' }} >
                                <img src={KairosWithLabel} alt="logoIcon" style={{ width: "100%", maxHeight: "56px", height: "auto" }} />
                            </Link>
                        </Typography>

                        {/** Block with main functional component */}
                        <Grid sx={{ display: "flex", flexDirection: "row", width: "100%", maxWidth: "1440px", justifyContent: "space-around", alignItems: "center", paddingX: isMd ? "20px" : "0px" }} >
                            <Grid display={isMd ? "flex" : "none"} flexDirection="column" textAlign="left">
                                <Typography sx={{ fontFamily: "The Seasons", fontSize: "72px", color: "white" }}>Elevate your wealth,</Typography>
                                <Typography sx={{ fontFamily: "The Seasons", fontSize: "72px", color: "#00fdc1" }}>effortlessly</Typography>
                            </Grid>
                            <Grid container spacing={0} direction="column" alignItems={"center"} justifyContent="center" sx={{ backgroundColor: isSm ? "white" : null, borderRadius: "12px", minWidth: isSm ? "430px" : "300px" }} >
                                <Routes>
                                    {PublicRouteConfig.map(({ name, path, element }) => (
                                        <Route key={name + 'first'} path={path} element={element} />
                                    ))}
                                </Routes>
                            </Grid>
                        </Grid>

                        {/** Footer */}
                        <Grid display="flex" flexDirection="column" gap={2}>
                            <div style={{ display: "flex", justifyContent: "center", justifyItems: "center", alignContent: "center", color: "white", paddingTop: "70px" }} >
                                <IconButton
                                    sx={{ color: "whitesmoke" }}
                                    component="a"
                                    href="https://www.instagram.com/kairoswealth.ai/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    name='InstagramIcon'
                                    alt='Instagram Logo'
                                >
                                    <Instagram sx={{ fontSize: 36 }} />
                                </IconButton>
                                <IconButton
                                    sx={{ color: "whitesmoke" }}
                                    component="a"
                                    href="https://www.linkedin.com/company/kairoswealth/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    name='LinkedInIcon'
                                    alt='LinkedIn Logo'
                                >
                                    <LinkedIn sx={{ fontSize: 36 }} />
                                </IconButton>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", justifyItems: "center", alignContent: "center", textAlign: "center", color: "white", fontSize: "14px" }}>
                                <div> Copyright © {currentYear.getFullYear()} Kairoswealth Pte Ltd - All Rights Reserved. </div>
                            </div>
                        </Grid>
                    </Grid>

                </ThemeProvider>
            </ColorModeContext.Provider >
        </>
    );
};


