import React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Paper, Grid, Typography, useMediaQuery } from '@mui/material';
import { formatNumber } from "../../../constant/DollorFormats";
import { useTheme } from '@mui/material/styles';

const DynamicDashboardCards = ({ data, cardsPerRow }) => {
    const Item = styled(Paper)(({ theme }) => ({
        background: 'white',
        border: "1px solid #ebebeb",
        textAlign: 'left',
        boxShadow: "none",
        padding: '20px 20px',
        height: '100%',
        color: '#1e1e1e',
        borderRadius: '8px',
    }));

    const calculateGridSize = () => {
        const totalCards = data?.length;
        const defaultCardsPerRow = cardsPerRow || 3; // Change this value as needed
        return Math.min(totalCards, defaultCardsPerRow);
    };

    const theme = useTheme();
    const isLg = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <div>
            {data?.length > 0 && <Grid container spacing={2} sx={{
                marginBottom: '10px',
            }}>
                {data?.map((item, index) => (
                    <Grid key={`gridItem_${index}`} item lg={12 / calculateGridSize()} xs={12} sm={6} md={4} >
                        <Item>
                            <Typography sx={{ fontSize: isLg ? '14px' : '18px', fontWeight: "500", fontFamily: 'Mona Sans', marginBottom: 3 }}>{item?.header}</Typography>
                            <Typography sx={{ fontSize: isLg ? '26px' : '40px', fontWeight: "500", marginBottom: 2 }}>{item?.format === "currency" ? `US $${formatNumber(item?.value)}` : item?.format === "number" ? `${formatNumber(item?.value, 0)}` : item?.value}</Typography>
                        </Item>
                    </Grid>
                ))}
            </Grid>}

        </div>
    );
};

export default DynamicDashboardCards;
