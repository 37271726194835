/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React from 'react'
import { useState } from "react";
import {
    Autocomplete,
    Typography,
    FormControl,
    FormControlLabel,
    Checkbox,
    Grid,
    TextField
}
    from '@mui/material'
import { styled } from '@mui/system';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    setSelectedPrimaryStock,
    setSelectedSecondaryStock,
    setIsCompareCheckboxChecked
} from '../../../../redux/slices/ChatGptSlice'

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        color: "white",
    },
    "& .MuiInputLabel-shrink": {
        color: "white"
    },
    "& .MuiSvgIcon-root": {
        color: "white"
    },
    "&.Mui-focused .MuiInputLabel-outlined": {
        color: "white"
    },
    "& .MuiAutocomplete-inputRoot": {
        color: "white",

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
        }
    }
});

const StyledTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
})((p) => ({

    // input label when focused
    "& label.Mui-focused": {
        color: "#fff"
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
        borderBottomColor: "#fff"
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#fff"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        borderColor: "#fff",
        "&.Mui-focused fieldset": {
            borderColor: "#fff"
        }
    }
}));

export default function SidebarStocks() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const tickersList = useSelector((state) => state.chatGpt.tickersList)
    const selectedPrimaryStock = useSelector((state) => state.chatGpt.selectedPrimaryStock)
    const selectedSecondaryStock = useSelector((state) => state.chatGpt.selectedSecondaryStock)
    const isCompareCheckboxChecked = useSelector((state) => state.chatGpt.isCompareCheckboxChecked)

    const handlePrimaryTickerChange = (event, newValue) => {
        if (newValue === null) {
            dispatch(setSelectedPrimaryStock(""));
        } else {
            dispatch(setSelectedPrimaryStock(newValue.ticker));
        }
    };

    const handleSecondaryTickerChange = (event, newValue) => {
        if (newValue === null) {
            dispatch(setSelectedSecondaryStock(""));
        } else {
            dispatch(setSelectedSecondaryStock(newValue.ticker));
        }
    };

    const handleCompareChecked = (event) => {
        dispatch(setIsCompareCheckboxChecked(!isCompareCheckboxChecked))
    };

    return (
        <Grid width="100%" display="flex" flexDirection="column" sx={{ rowGap: 0 }}>
            <Typography color='white' marginX={2} mb={1}>{t("Pick a stock:")}</Typography>
            <StyledAutocomplete
                id="primary-ticker"
                width="100%"
                size="small"
                sx={{ marginX: 2 }}
                value={tickersList.find(ticker => ticker.ticker === selectedPrimaryStock) || null}
                options={tickersList}
                getOptionLabel={(ticker) => ticker.ticker + " - " + ticker.company_name}
                isOptionEqualToValue={(option, value) => option.ticker === value.ticker}
                renderInput={(params) => <StyledTextField {...params} label={t("Ticker")} />}
                onChange={handlePrimaryTickerChange}
            />
            <FormControl sx={{ marginX: 2 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isCompareCheckboxChecked}
                            sx={{
                                color: "#fff",
                                "&.Mui-checked": {
                                    color: "#fff"
                                }
                            }}
                        />
                    }
                    label={t("Compare")}
                    sx={{ color: '#fff' }}
                    onChange={handleCompareChecked}
                />
            </FormControl>
            <StyledAutocomplete
                id="secondary-ticker"
                width="100%"
                size="small"
                sx={{ marginX: 2 }}
                value={tickersList.find(ticker => ticker.ticker === selectedSecondaryStock) || null}
                disabled={!isCompareCheckboxChecked}
                options={tickersList}
                getOptionLabel={(ticker) => ticker.ticker + " - " + ticker.company_name}
                isOptionEqualToValue={(option, value) => option.ticker === value.ticker}
                renderInput={(params) => <StyledTextField {...params} label={t("Ticker")} />}
                onChange={handleSecondaryTickerChange}
            />
        </Grid>
    )
}
