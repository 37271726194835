/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    setBookmark,
    setBookmarkList,
    setBookmarkMessage,
    setClose,
    setNotificationClick,
    setReceivedNotification,
    setNotificationCount,
    setPage,
    setSelectedButton,
    setTickersList,
    setFolders,
    setDisplayHelpModal,
    setSelectedPrimaryStock,
    setStockGraphPeriod,
    setEnglishSelectedButton
} from '../../../redux/slices/ChatGptSlice'
import { getRmNotificationList, updateBookmarkStatus } from "../../../services/index"

import logo from "../../../theme/images/GuestKairos Logo.svg"

import {
    Box,
    Typography,
    Grid,
    Button,
    Tabs,
    Tab,
    AppBar
}
    from '@mui/material'
import SwipeableViews from 'react-swipeable-views';

import SidebarStocks from "./Sidebar/stocks"
import SidebarInternalData from "./Sidebar/internalData"
import Modals from "./Sidebar/modals"
import SidebarPortfolio from "./Sidebar/portfolio"
import SidebarAgent from "./Sidebar/agent"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { motion } from "framer-motion";
import { changeLanguage } from 'i18next';
import { supportedLanguages } from './constants';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function Sidebar() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [value, setValue] = React.useState(0);

    const user = JSON.parse(localStorage.getItem("user"));
    const hniUser = user?.userTypeId?.description;
    const isHni = (hniUser === "Client Head" || hniUser === "Client Family Member") ? true : false;
    const targetValue = useSelector((state) => state.nav.targetValue)
    const bookmarkList = useSelector((state) => state?.chatGpt?.bookmarkList)
    const selectedButton = useSelector((state) => state.chatGpt.selectedButton)
    const englishSelectedButton = useSelector((state) => state.chatGpt.englishSelectedButton)
    const selectedPrimaryStock = useSelector((state) => state.chatGpt.selectedPrimaryStock)
    const stockGraphPeriod = useSelector((state) => state.chatGpt.stockGraphPeriod)


    if (isHni && targetValue === "llm") {
        navigate("/chatgpt")
    }

    const handleChangeLanguage = async (dest_lng) => {
        await changeLanguage(dest_lng);
        const updatedSelectedButton = t(englishSelectedButton, { lng: dest_lng })
        dispatch(setSelectedButton(updatedSelectedButton))
    }

    const handleButtonClick = (buttonIndex) => {
        // Update the selected button index
        dispatch(setSelectedButton(buttonIndex.value));
        dispatch(setEnglishSelectedButton(buttonIndex.englishValue));
    };

    useEffect(() => {
        console.log("selectedB After dispatch2", selectedButton);
    }, [selectedButton]);

    const buttons = [
        {
            value: t('Stocks'),
            englishValue: "Stocks"
        },
        {
            value: t('Internal Data'),
            englishValue: "Internal Data"
        },
        {
            value: t('Portfolio'),
            englishValue: "Portfolio"
        },
        {
            value: t('Agent'),
            englishValue: "Agent"
        }
    ];

    useEffect(() => {
        // initialize redux variables that have not been translated
        if (selectedButton === undefined) {
            dispatch(setSelectedButton(t('Stocks')))
        }
        if (stockGraphPeriod === undefined) {
            dispatch(setStockGraphPeriod(t('daily')))
        }


        // Check if the data is already in local storage
        let storedData = localStorage.getItem('tickersList');
        if (storedData !== null && storedData !== undefined && storedData !== 'undefined') {
            // If data is in local storage, parse and set it in the state
            dispatch(setTickersList(JSON.parse(storedData)));
        } else {
            // If data is not in local storage, fetch it
            queryAPI("tickers", "GET", "", "")
                .then(res_tickers => {
                    localStorage.setItem('tickersList', JSON.stringify(res_tickers.tickers));
                    dispatch(setTickersList(res_tickers.tickers))
                });
        }
        queryAPI("indexes", "GET", "", "")
            .then(res_indexes => {
                console.log("resindex1", res_indexes)
                if (res_indexes[0].length > 0) {
                    let options = [""]
                    res_indexes[0].forEach(index => {
                        options.push(index.payload["index_id"])
                    })
                    dispatch(setFolders(options))
                    console.log("Dispatched options")
                }
            })// eslint-disable-next-line 
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);

        if (newValue === 1) {
            navigate("/chatgpt")
        } else {
            navigate("/kairosbot")
        }
    };

    const handleChangeIndex = (index) => {
        console.log("handleChangeIndex")
        //queryAPI("")
        setValue(index);
    };

    const handleBookmark = (list, index) => {
        dispatch(setBookmarkMessage(list?.question))
        // check if list.ticker exists
        if (list?.ticker) {
            dispatch(setSelectedPrimaryStock(list?.ticker))
        }
        dispatch(setClose(list?.chatGptId))
    }

    const handleDeleteBookmark = (chatGptId, data) => {
        let obj = {
            "chatGptId": chatGptId,
            "bookmark": "No",
            "userId": user?.userId,
            "streamedData": data.answer || "",
            "ticker": selectedPrimaryStock || "",
            "bookmarkType": targetValue === 'ChatGpt' ? targetValue : selectedButton
        };

        const res = updateBookmarkStatus(obj)
        let updatedBookmarksList = bookmarkList.filter(item => item.chatGptId !== chatGptId);
        dispatch(setBookmarkList(updatedBookmarksList))
    }

    const handleHistoryClick = () => {
        dispatch(setPage(0))
        if (targetValue === 'llm') {
            navigate("/kairosbot/history")
        } else {
            navigate("/chatgpt/history")

        }
        dispatch(setBookmark(true))
    };

    const handleNotificationsClick = () => {
        if (user?.userTypeId?.description !== "Entity RM") {
            getRmNotificationList(user?.userId, targetValue).then((res) => {
                if (res?.data?.status) {
                    dispatch(setReceivedNotification(res?.data?.body))
                    dispatch(setNotificationCount(0))
                }
            })
        }
        dispatch(setNotificationClick(true))
    }

    const handleHelp = () => {
        dispatch(setDisplayHelpModal(true))
    }

    const queryAPI = (object, method, query, payload) => {
        let auth_header = ""
        if (JSON.parse(window.localStorage.getItem("user"))?.idToken) {
            const user = window.localStorage.getItem("user")
            if (user) {
                auth_header = `Bearer ${JSON.parse(user).idToken}`;
            }
        }

        const env = process.env.REACT_APP_DOMAIN_NAME;

        const base_api_url = "https://gaia.kairoswealth.com/"
        let headers = {}
        if (object === "document" && method === "POST") {
            headers = {
                'authorization': auth_header,
                "env": env,
                //"content-type": "multipart/form-data"
            }
        } else {
            headers = {
                'authorization': auth_header,
                "env": env,
                "content-type": "application/json",
            }
        }
        const url = base_api_url + object + "/" + query
        console.log(payload)
        console.log({ url })
        let data = {}
        if (payload !== "") {
            data = {
                method: method,
                headers: headers,
                body: payload
            }
        } else {
            data = {
                method: method,
                headers: headers,
            }
        }
        return fetch(
            url,
            data
        ).then(response => {
            if (response.status === 200) {
                const res = response.json()
                return res
            }
            else {
                return 1
            }
        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    return (
        <Grid container id="SidebarContainer" sx={{ rowGap: 2, bgcolor: "#313034", overflowY: "none", maxHeight: "100%" }} width="100%" height="100%" direction="column" alignItems="center" justifyContent="space-between" wrap="nowrap">
            {/** Group all the top elements */}
            <Grid display="flex" flexDirection="column" width="100%" wrap="nowrap" maxHeight="100%" sx={{ overflowY: 'auto' }}  >
                {/** logo */}
                <Grid item sx={{ marginX: "auto" }} mb={1} mt={1}><img src={user?.entityLogo} style={{ width: '125px', cursor: 'pointer' }} alt="logoIcon" onClick={() => { navigate("/dashboard") }} /></Grid>
                
                <Grid item width="100%" borderBottom="1px #4c4c4c solid"></Grid>

                {/** Financial / lifestyle selector */}
                <Box sx={{ width: '100%' }}>
                    <AppBar position="static">
                        {!isHni ?
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="finacial and lifestyle selector"
                                sx={{
                                    backgroundColor: '#6c6c6c',
                                }}
                            >
                                <Tab sx={{ textTransform: 'none' }} label={t("Financial")} {...a11yProps(0)} />
                                <Tab sx={{ textTransform: 'none' }} label={t("Lifestyle")} {...a11yProps(1)} />
                            </Tabs>
                            :
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="financial and lifestyle selector"
                                sx={{
                                    backgroundColor: '#6c6c6c',
                                }}
                            >
                                <Tab sx={{ textTransform: 'none' }} label={t("Lifestyle")} {...a11yProps(1)} />
                            </Tabs>
                        }
                    </AppBar>
                    {!isHni ?
                        <SwipeableViews
                            axis={'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                            height="100%"
                        >
                            {/** Financial tab section (only accessible by RM */}
                            <TabPanel value={value} index={0} dir={'x'} height="100%'">
                                <Grid container flexDirection="column" sx={{ rowGap: 1.5 }} wrap="nowrap">
                                    {/** Financial menu */}
                                    <Grid container width="100%" display="flex" justifyContent="center" alignItems="center" margin="0" sx={{ rowGap: 1.5, marginTop: 1.5 }}>
                                        {buttons.map((label, index) => (
                                            <Button
                                                key={index}
                                                sx={{
                                                    textTransform: 'none',
                                                    width: '45%',
                                                    marginX: '2%',
                                                    marginY: '1px',
                                                    color: 'white',
                                                    borderColor: '#707070',
                                                    ':hover': {
                                                        borderColor: '#6442c4',
                                                        fontWeight: 'bold',
                                                        borderWidth: '2px',
                                                        marginY: '0px',
                                                        backgroundColor: selectedButton === label.value ? '#6442c4' : ''
                                                    },
                                                    backgroundColor: selectedButton === label.value ? '#6442c4' : ''
                                                }}
                                                onClick={() => handleButtonClick(label)}
                                                variant={selectedButton === label.value ? 'contained' : 'outlined'}
                                            >
                                                {label.value}
                                            </Button>
                                        ))}
                                    </Grid>

                                    <Grid item width="100%" borderBottom="1px #4c4c4c solid"></Grid>

                                    {/** Financial menu sub display : Stocks */}
                                    {selectedButton === t('Stocks') && <>

                                        {/** Main sub component */}
                                        <SidebarStocks />

                                        {/** Divider */}
                                        <Grid item width="100%" borderBottom="1px #4c4c4c solid"></Grid>

                                        {/** Bookmarks component */}
                                        <Grid display="flex" flexDirection="column" width="100%">
                                            <Typography pr={2} pl={2} pb={1.5} sx={{ color: "#8E8EA0", fontSize: "18px" }}>{t("Bookmarks")}</Typography>
                                            {/** Display bookmarks */}
                                            {bookmarkList?.map((bookmark) => {
                                                if (bookmark.bookmarkType === t('Stocks')) {
                                                    return (
                                                        <Box
                                                            key={bookmark?.chatGptId}
                                                            display="flex" flexDirection="row"
                                                            sx={{ paddingX: 2, color: '#FFFFFF', cursor: 'pointer', marginBottom: 1 }}
                                                            onClick={() => handleBookmark(bookmark)}
                                                        >
                                                            <Typography pt={0}
                                                                component={motion.div}
                                                                whileHover={{
                                                                    translateX: 5,
                                                                    transition: { duration: 0.3 }
                                                                }}
                                                                sx={{
                                                                    backgroundColor: "#201F23",
                                                                    fontSize: "12px",
                                                                    overflow: "hidden",
                                                                    cursor: 'pointer',
                                                                    border: '1px solid gray',
                                                                    color: '#BFBFBF',
                                                                    borderRadius: '8px',
                                                                    width: '100%',
                                                                    padding: '8px'
                                                                }}
                                                                display="flex" flexDirection="row" justifyContent="space-between"
                                                                width="100%"
                                                                title={bookmark?.question}
                                                            >
                                                                <>{bookmark?.question}</>
                                                                <DeleteOutlineIcon fontSize="small" sx={{ cursor: 'pointer', color: '#808080', '&:hover': { color: '#ffffff' } }} onClick={() => handleDeleteBookmark(bookmark?.chatGptId, bookmark)} />
                                                            </Typography>
                                                        </Box>);
                                                } else { return null }
                                            })}
                                        </Grid>
                                    </>}

                                    {/** Financial menu sub display : Internal Data */}
                                    {selectedButton === t('Internal Data') && <>

                                        {/** Main sub component */}
                                        <SidebarInternalData queryAPI={queryAPI} />

                                        {/** Divider */}
                                        <Grid item width="100%" borderBottom="1px #4c4c4c solid"></Grid>

                                        {/** Bookmarks component */}
                                        <Grid display="flex" flexDirection="column" width="100%">
                                            <Typography pr={2} pl={2} pb={1.5} sx={{ color: "#8E8EA0", fontSize: "18px" }}>{t("Bookmarks")}</Typography>
                                            {/** Display bookmarks */}
                                            {bookmarkList?.map((bookmark) => {
                                                if (bookmark.bookmarkType === t('Internal Data')) {
                                                    return (
                                                        <Box
                                                            key={bookmark?.chatGptId}
                                                            display="flex" flexDirection="row"
                                                            sx={{ paddingX: 2, color: '#FFFFFF', cursor: 'pointer', marginBottom: 1 }}
                                                            onClick={() => handleBookmark(bookmark)}
                                                        >
                                                            <Typography pt={0}
                                                                component={motion.div}
                                                                whileHover={{
                                                                    translateX: 5,
                                                                    transition: { duration: 0.3 }
                                                                }}
                                                                sx={{
                                                                    backgroundColor: "#201F23",
                                                                    fontSize: "12px",
                                                                    overflow: "hidden",
                                                                    cursor: 'pointer',
                                                                    border: '1px solid gray',
                                                                    color: '#BFBFBF',
                                                                    borderRadius: '8px',
                                                                    width: '100%',
                                                                    padding: '8px'
                                                                }}
                                                                display="flex" flexDirection="row" justifyContent="space-between"
                                                                width="100%"
                                                                title={bookmark?.question}
                                                            >
                                                                <>{bookmark?.question}</>
                                                                <DeleteOutlineIcon fontSize="small" sx={{ cursor: 'pointer', color: '#808080', '&:hover': { color: '#ffffff' } }} onClick={() => handleDeleteBookmark(bookmark?.chatGptId, bookmark)} />
                                                            </Typography>
                                                        </Box>)
                                                } else { return null }
                                            })}
                                        </Grid>
                                    </>}

                                    {/** Financial menu sub display : Portfolio */}
                                    {selectedButton === t('Portfolio') && <>
                                        {/** Main sub component */}
                                        <SidebarPortfolio queryAPI={queryAPI} />

                                        {/** Divider */}
                                        <Grid item width="100%" borderBottom="1px #4c4c4c solid"></Grid>

                                        {/** Bookmarks component */}
                                        <Grid display="flex" flexDirection="column" width="100%">
                                            <Typography pr={2} pl={2} pb={1.5} sx={{ color: "#8E8EA0", fontSize: "18px" }}>{t("Bookmarks")}</Typography>
                                            {/** Display bookmarks */}
                                            {bookmarkList?.map((bookmark) => {
                                                if (bookmark.bookmarkType === t('Portfolio')) {
                                                    return (
                                                        <Box
                                                            key={bookmark?.chatGptId}
                                                            display="flex" flexDirection="row"
                                                            sx={{ paddingX: 2, color: '#FFFFFF', cursor: 'pointer', marginBottom: 1 }}
                                                            onClick={() => handleBookmark(bookmark)}
                                                        >
                                                            <Typography pt={0}
                                                                component={motion.div}
                                                                whileHover={{
                                                                    translateX: 5,
                                                                    transition: { duration: 0.3 }
                                                                }}
                                                                sx={{
                                                                    backgroundColor: "#201F23",
                                                                    fontSize: "12px",
                                                                    overflow: "hidden",
                                                                    cursor: 'pointer',
                                                                    border: '1px solid gray',
                                                                    color: '#BFBFBF',
                                                                    borderRadius: '8px',
                                                                    width: '100%',
                                                                    padding: '8px'
                                                                }}
                                                                display="flex" flexDirection="row" justifyContent="space-between"
                                                                width="100%"
                                                                title={bookmark?.question}
                                                            >
                                                                <>{bookmark?.question}</>
                                                                <DeleteOutlineIcon fontSize="small" sx={{ cursor: 'pointer', color: '#808080', '&:hover': { color: '#ffffff' } }} onClick={() => handleDeleteBookmark(bookmark?.chatGptId, bookmark)} />
                                                            </Typography>
                                                        </Box>)
                                                } else { return null }
                                            })}
                                        </Grid>
                                    </>}

                                    {/** Financial menu sub display : Private equity */}
                                    {selectedButton === t('Agent') && <>

                                        {/** Private Equity Sub Component */}
                                        <SidebarAgent queryAPI={queryAPI} />

                                    </>}
                                </Grid>
                            </TabPanel>
                            {/** Lifestyle tab section */}
                            <TabPanel value={value} index={1} dir={'x'}>
                                <Grid display="flex" flexDirection="column" width="100%">
                                    <Typography pr={2} pl={2} pb={1.5} mt={1.5} sx={{ color: "#8E8EA0", fontSize: "18px" }}>{t("Bookmarks")}</Typography>
                                    {/** Display bookmarks */}
                                    {bookmarkList?.map((bookmark) => {
                                        return (
                                            <Box
                                                key={bookmark?.chatGptId}
                                                display="flex" flexDirection="row"
                                                sx={{ paddingX: 2, color: '#FFFFFF', cursor: 'pointer', marginBottom: 1 }}
                                                onClick={() => handleBookmark(bookmark)}
                                            >
                                                <Typography pt={0}
                                                    component={motion.div}
                                                    whileHover={{
                                                        translateX: 5,
                                                        transition: { duration: 0.3 }
                                                    }}
                                                    sx={{
                                                        backgroundColor: "#201F23",
                                                        fontSize: "12px",
                                                        overflow: "hidden",
                                                        cursor: 'pointer',
                                                        border: '1px solid gray',
                                                        color: '#BFBFBF',
                                                        borderRadius: '8px',
                                                        width: '100%',
                                                        padding: '8px'
                                                    }}
                                                    display="flex" flexDirection="row" justifyContent="space-between"
                                                    width="100%"
                                                    title={bookmark?.question}
                                                >
                                                    <>{bookmark?.question}</>
                                                    <DeleteOutlineIcon fontSize="small" sx={{ cursor: 'pointer', color: '#808080', '&:hover': { color: '#ffffff' } }} onClick={() => handleDeleteBookmark(bookmark?.chatGptId, bookmark)} />
                                                </Typography>
                                            </Box>)
                                    })}
                                </Grid>
                            </TabPanel>
                        </SwipeableViews>
                        :
                        <>
                            {/** Lifestyle tab section */}
                            <Grid display="flex" flexDirection="column" width="100%">
                                <Typography pr={2} pl={2} pb={2} mt={1.5} sx={{ color: "#8E8EA0", fontSize: "18px" }}>{t("Bookmarks")}</Typography>
                                <Grid display="flex" flexDirection="column" width="100%">
                                    {/** Display bookmarks */}
                                    {bookmarkList?.map((bookmark) => {
                                        return (
                                            <Box
                                                key={bookmark?.chatGptId}
                                                display="flex" flexDirection="row"
                                                sx={{ paddingX: 2, color: '#FFFFFF', cursor: 'pointer', marginBottom: 1 }}
                                                onClick={() => handleBookmark(bookmark)}
                                            >
                                                <Typography pt={0}
                                                    component={motion.div}
                                                    whileHover={{
                                                        translateX: 5,
                                                        transition: { duration: 0.3 }
                                                    }}
                                                    sx={{
                                                        backgroundColor: "#201F23",
                                                        fontSize: "12px",
                                                        overflow: "hidden",
                                                        cursor: 'pointer',
                                                        border: '1px solid gray',
                                                        color: '#BFBFBF',
                                                        borderRadius: '8px',
                                                        width: '100%',
                                                        padding: '8px'
                                                    }}
                                                    display="flex" flexDirection="row" justifyContent="space-between"
                                                    width="100%"
                                                    title={bookmark?.question}
                                                >
                                                    <>{bookmark?.question}</>
                                                    <DeleteOutlineIcon fontSize="small" sx={{ cursor: 'pointer', color: '#808080', '&:hover': { color: '#ffffff' } }} onClick={() => handleDeleteBookmark(bookmark?.chatGptId, bookmark)} />
                                                </Typography>
                                            </Box>)
                                    })}
                                </Grid>
                            </Grid>
                        </>
                    }
                </Box>
            </Grid>
            <Grid container>
                {/** Footer of the sidebar */}
                <Grid item width="100%" borderBottom="1px #4c4c4c solid"></Grid>
                <Grid display="flex" flexDirection="row" flexWrap="wrap" mt={2} mb={2} justifyContent="center" alignItems="center" rowGap={2} columnGap={2} sx={{ marginX: "auto" }}>
                    <Button variant='contained' sx={{
                        width: '140px',
                        background: '#6442C4'
                    }} onClick={handleHistoryClick}>{t("History")}</Button>
                    <Button variant='contained' sx={{
                        width: '140px',
                        color: "white",
                        border: "2px solid #6442C4",
                        background: 'transparent',
                        textTransform: 'none'
                    }} onClick={handleNotificationsClick}>{t("Notifications")}</Button>
                    <HelpOutlineIcon fontSize="medium" sx={{ cursor: "pointer", color: "#8E8EA0", '&:hover': { color: '#ffffff' } }} onClick={() => handleHelp()} />
                    {/** Language Selector */}
                    <select
                        style={{ color: "#bfbfbf", backgroundColor: "#313034", padding: 2, borderRadius: "8px" }}
                        onChange={(e) => handleChangeLanguage(e.target.value)}
                        defaultValue={i18n.language}
                    >
                        {supportedLanguages.map((lang) => {
                            return (
                                <option value={lang} sx={{ cursor: "pointer", color: "#BFBFBF" }} >{lang}</option>
                            )
                        }
                        )}
                    </select>
                </Grid>
            </Grid>

            {/** Component containing all the modals */}
            <Modals queryAPI={queryAPI} />

        </Grid>
    );
}