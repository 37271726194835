import React from 'react'
import withPermissionsHoc from "../../Layout/PermissionsHoc/PermissionsHoc"
import AdminDashboard from './AdminDashboardList/dashboardList'
import LazyLoadingHNI from "./HniDashboard/LazyLoadingHNI"
import LazyLoadingRM from "./RmDashboard/LazyLoadingRM"
import ModalWithTable from "../../common/DashboardModal/ModalWithTable";
import { useSelector} from 'react-redux';
import { jwtDecode } from 'jwt-decode';
function Dashboard({ readPermission, writePermission, approvePermission }) {
    const [loadContent,setLoadContent]=React.useState(true);
    const rmUser = JSON.parse(localStorage.getItem('user'))
    const decodedToken = jwtDecode(rmUser?.idToken);
    const rmvalue = rmUser?.userTypeId?.description
    const isHni = (rmUser?.userTypeId?.description === "Client Head" || rmUser?.userTypeId?.description === "Client Family Member") ? true : false;
    const dashboardContent = useSelector((state) => state.dashboard[btoa(decodedToken?.email)]);

    React.useEffect(() => {
        localStorage.setItem("entityId", btoa(JSON.parse(localStorage.getItem("user"))?.entityId))
        const intervalId = setTimeout(() => {
           // if(dashboardContent!==undefined){
                setLoadContent(false)
           // }
           
        }, 200);
    
        return () => {
            clearInterval(intervalId);
        };
      
    }, [dashboardContent])

   
    return (
        <>
        {!loadContent ? (
            <>
                <ModalWithTable />
                {(rmvalue === 'Entity Admin' || rmvalue === 'Kairos Admin') && <AdminDashboard />}
                {rmvalue === 'Entity RM' && <LazyLoadingRM />}
                {isHni && <LazyLoadingHNI />}
            </>
        ) : (
            // Render an alternative component when loadContent is false
            <div>Loading...</div>
        )}
    </>

    )
}

export default withPermissionsHoc(Dashboard, "Dashboard");